import {ReactElement} from "react";
import {Document, Page, Text, View, Image} from "@react-pdf/renderer";
// import {useAppDispatch, useAppSelector} from "store/storeHooks";
import {general, front, sommarie, tutoriel, resultats, conseils, legal} from "./PDF.styles";
import LogoPDF from "assets/img/LogoPDF";
import store from "store/store";
import CONSEILS from "utils/content.json";
import {getFormattedData} from "utils/graphFn";
import {VerticalLineDescription, VerticalLineCroissance, VerticalLine} from "./Units";
import {capitalize, CONSEIL_IMAGES, allAdviceCategories, indicateursAnalysesVariableToTerm, getVariableHexColor, getHexColor, getResultButtonText, getColor} from "utils/utils";
import ICON_UNIVERSITY from "assets/img/icon_university.png";
import MainIndicator from "assets/img/Indicator.jpg"
import GreenIndicator from "assets/img/GreenIndicator.jpg"
import OrangeIndicator from "assets/img/OrangeIndicator.jpg"
import variablesContent from "utils/variablesContent.json";
// import Colors from "theme/Colors";

// Create Document Component
const CreatePDF = (advices: Record<string, any>, diagnosisScores: Record<string, any>, report: Record<string, any>): ReactElement => {
	const name = store.getState().enterprise.selectedEnterprise?.name;
	const siren = store.getState().enterprise.selectedEnterprise?.siren;
	const sectorial = store.getState().enterprise.diagnosisResponse?.sectorial;
	const DATA = getFormattedData(sectorial);
	const selectedConseils = Object.entries(advices);

	const today = new Date();
	return (
		<Document
			language="fr"
			title="Inbonis Rating - Bpifrance"
			subject="Diagnostic Financier"
			author="Inbonis Rating"
		>
			<Page size={{width: 2400, height: 3200}} style={general.body} orientation="landscape">
				{/* <Text
					style={general.pagination}
					render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
					fixed
				/> */}

				{/* FRONT */}
				<View style={front.background}>
					<View fixed style={front.logo}>
						<LogoPDF/>
					</View>
					<View style={front.mainBody}>
						<View style={front.header}>
							<Text style={front.title}>Diagnostic financier de {name}</Text>
						</View>
						<View style={front.images}>
							<Image src={GreenIndicator} style={front.greenIndicator} />
							<Image src={MainIndicator} style={front.mainIndicator} />
							<Image src={OrangeIndicator} style={front.orangeIndicator} />
						</View>
						<View>
							<Text style={front.subTitle}>SIREN: {siren}</Text>
							<Text style={{...front.subTitle, marginTop: 20}}>
								{capitalize(
									today.toLocaleDateString("fr-FR", {year: "numeric", month: "long"})
								)}
							</Text>
						</View>
					</View>
					<Text
						style={general.pagination}
						render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
						fixed
					/>
				</View>

				{/* SOMMAIRE */}
				<View break />
				<View style={general.title}>
					<Text>Sommaire</Text>
				</View>
				<View style={sommarie.mainBody}>
					<View style={{...sommarie.header, marginTop: 20}}>
						<Text>1   Comment lire ce diagnostic ?</Text>
					</View>
					<View style={sommarie.header}>
						<Text>2   Résultats du diagnostic</Text>
					</View>
					<View style={sommarie.conseils}>				
						{selectedConseils.map((el, idx) => {
							if (allAdviceCategories.includes(el[0])) {
								const score = diagnosisScores[el[0]]
								const evaluationColor = getHexColor(score)

								return (
									<View key={`conseils-${idx}`} style={sommarie.conseil}>
										<View style={sommarie.conseilContent}>
											<View style={{
												...sommarie.dot,
												backgroundColor: evaluationColor,
											}} />
											<View style={sommarie.spaceBetween} >
												<Text style={sommarie.indicateurText}>{indicateursAnalysesVariableToTerm(el[0])}</Text>
												<View style={sommarie.conseilNr}>
												{el[1].length !== 0 &&
													<Text>{el[1].length} {el[1].length === 1 ? `conseil` : `conseils`}</Text>
												}
												</View>
											</View>
										</View>
									</View>
									)
							} else {
								return null
							}
						})}
					</View>
					<View style={sommarie.header}>
						<Text>3   Mentions légales</Text>
					</View>
				</View>
				<Text
						style={general.pagination}
						render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
						fixed
				/>

				{/* Tutoriel */}
				<View break />
				<View style={tutoriel.content}>
					<View style={tutoriel.graphBlock}>
						<View style={general.title}>
							<Text>Comment lire ce graphique ?</Text>
						</View>
						<View style={tutoriel.graphTitle}>
							<View>
								<Text style={tutoriel.pageSubTitle}>Répartition des entreprises du secteur</Text>
							</View>
							<View style={tutoriel.graphContent}>
								{DATA ? (
									<>
										<VerticalLineDescription />
										<VerticalLineCroissance data={DATA.activity} />
										<VerticalLine data={DATA.margin} />
										<VerticalLine data={DATA.rentability} />
										<VerticalLine data={DATA.liquidity} />
										<VerticalLine data={DATA.solvency} />
										<VerticalLine data={DATA.debt} />
										<VerticalLineCroissance data={DATA.cost_effectiveness} />
									</>
								) : (
									<></>
								)}
							</View>
						</View>
					</View>
					<View style={tutoriel.rightBlock}>
						<View style={tutoriel.commentBlock}>
							<Text style={tutoriel.commentBlockText}>
								7 indicateurs vous sont proposés pour évaluer la performance de votre entreprise et vous comparer avec votre secteur d’activité.
							</Text>
							<Text style={tutoriel.commentBlockText}>
								Les entreprises de votre secteur sont réparties, selon leur performance sur chacun des indicateur, à effectif égal en 4 groupes (uniquement 2 pour la croissance).
							</Text>
							<Text style={tutoriel.commentBlockText}>
								La vignette colorée permet de vous situer par rapport aux autres entreprises de votre secteur.
							</Text>
						</View>
					</View>
				</View>
				<Text
					style={general.pagination}
					render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
					fixed
				/>

				{/* Resultats && Variables Analysées && CONSEILS */}
				{selectedConseils && selectedConseils.map(([cat, consArr], idx_0) => {
					if (allAdviceCategories.includes(cat)) {
						const score = diagnosisScores[cat]
						const evaluationColor = getHexColor(score)
						const variableContent = Object.values(variablesContent)[Object.keys(variablesContent).indexOf(cat)]
						const varEntries = Object.entries(variableContent)

						return (
							<View key={`idx_0-${idx_0}`} break>

								<View style={resultats.introduction}>
									<View style={resultats.introductionBox}>
										<Text style={resultats.introductionTitle}>{indicateursAnalysesVariableToTerm(cat)}</Text>
										<View style={resultats.globalEvaluation}>
											<Text style={{
												backgroundColor: evaluationColor,
												padding: "20px 45px",
												borderRadius: "50%"
												}}>{getResultButtonText(getColor(score))}</Text>
										</View>
										<View style={resultats.evaluationButtons}>
											{consArr.length > 0 ?
												<Text style={resultats.conseilsButton}>{consArr.length} {consArr.length === 1 ? "conseil" : "conseils"}</Text>
												: null
											}
											
											<Text style={resultats.variablesAnalyseesButton}>{varEntries.length} variables analysées</Text>
										</View>
									</View>
								</View>
								<Text
									style={general.pagination}
									render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
									fixed
								/>
								
								<View style={resultats.background}>
									<View style={resultats.container}>
										<View style={resultats.titles}>
											<Text style={resultats.title}>Variables analysées</Text>
										</View>
										{varEntries.map(([cat2, varDetailed], i) => {
											const score = report[cat][cat2]
											const resultColor = getVariableHexColor(score)

											return (
												<View style={resultats.variable} key={i}>
													<View style={{
														...resultats.variableDot,
														backgroundColor: resultColor,
													}} />
													<View>
														<Text style={resultats.variableTitle}>{varDetailed.term}</Text>
														<Text style={resultats.variableText}>
															{varDetailed.description}
														</Text>
													</View>
												</View>
											)
										})}		
									</View>	

									{consArr.length > 0 &&
										<View style={resultats.container}>
										{consArr.map((cons: string, idx_1: number) => {
											const c = Object.values(CONSEILS)[Object.keys(CONSEILS).indexOf(cons)]
											return (
												<View key={`idx_1-${idx_1}`} style={conseils.conseilSection}>
													<View>
														<Text style={conseils.title}>Conseil №{idx_1 + 1}</Text>
														<Text style={conseils.description}>{c.conseil[0]}</Text>
													</View>
													
													{c?.plan_action ? (
														<View>
															<Text style={conseils.planTitle}>Plan d’action</Text>
															{c.plan_action?.map((plan, IDX) => (
																<Text style={conseils.planText} key={`IDX-${IDX}`}>
																	{plan}
																</Text>
															))}
														</View>
													) : (
														<></>
													)} 

													<Text style={conseils.planTitle}>Pour aller plus loin</Text>

													{c.formations_bpifrance_universite.map((formation, idx_2) => (
														<View key={`idx_2-${idx_2}`} style={conseils.recommendation}>
															<Image
																// @ts-ignore
																src={`${CONSEIL_IMAGES[c.image[idx_2]]}`}
																style={conseils.recImage}
															/>
															<View style={conseils.recContent}>
																
																	<Text style={conseils.recTitle}>
																		<a 
																			href={c.pour_aller_plus_loin_link[idx_2]}
																			target="_blank"
																			rel="noreferrer"
																		>
																			{formation}
																		</a>
																	</Text>
																	<Text style={conseils.recDesc}>
																		<a 
																			href={c.pour_aller_plus_loin_link[idx_2]}
																			target="_blank"
																			rel="noreferrer"
																		>
																			{c.pour_aller_plus_loin_text[idx_2]}
																		</a>
																	</Text>
															
																<View style={conseils.recFooter}>
																	<Image
																		src={ICON_UNIVERSITY}
																		style={conseils.recUniversity}
																	/>
																	<Text style={conseils.recLink}>
																		<a 
																			href={c.pour_aller_plus_loin_link[idx_2]}
																			target="_blank"
																			rel="noreferrer"
																		>
																		↗
																		</a>
																	</Text>
																</View>
															</View>
														</View>
													))}
												</View>
											)
										
										})}

										</View>	
									 }
								</View>
								<Text
									style={general.pagination}
									render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
									fixed
								/>

							</View>
						)
					} else {
						return null
					}
					
				})}

				{/* Legal */}
				<View break>
					<Text style={general.title}>Mentions légales</Text>
					<View style={legal.content}>
						<Text style={legal.title}>
							Attributs et limitations du rapport de diagnostic coachPME®
						</Text>
						<Text style={legal.paragraph}>
							Un service commercialisé par INBONIS RATING France
						</Text>
						<Text style={legal.paragraph}>
							INBONIS RATING France est une société à responsabilité limitée au
							capital de 10.000 euros et immatriculée au RCS de Paris sous le numéro
							851 889 014. Son siège social est situé à Hôtel Bourienne, 58 Rue d{"'"}
							Hauteville, 75010, Paris. Contact : info@inbonis.es et/ou +34912772431.
						</Text>
						<Text style={legal.paragraph}>
							Le rapport de diagnostic est généré exclusivement à partir de coachPME®,
							une application en ligne développée par INBONIS. L’application coachPME®
							est disponible pour les entreprises françaises dont les comptes annuels
							sont publiés au Registre du Commerce ou fournis par l’utilisateur, et
							distribués par les fournisseurs de données d’INBONIS. Les informations
							contenues dans le rapport de diagnostic proviennent exclusivement des
							fournisseurs de données d’INBONIS et des informations fournies
							volontairement par l’utilisateur de l’application coachPME®. Les données
							utilisées par l’application proviennent de sources publiques fiables et
							mises-à-jour régulièrement. La véracité et l’actualité de ces données
							n’est pas garantie et ne peut engager la responsabilité de INBONIS.
							INBONIS effectue ses propres due diligences quant aux sources de données
							utilisées. Cependant, INBONIS n’est pas responsable de la véracité des
							informations publiquement collectées. De même manière, l’utilisateur de
							coachPME® s’engage à fournir des données précises, exactes et complètes.
							INBONIS n’est pas responsable du caractère erroné des données fournies
							pas un utilisateur. INBONIS conservera les données à caractère personnel
							conformément aux durées de prescription légales et réglementaires
							françaises et européennes.
						</Text>
						<Text style={legal.paragraph}>
							La qualification, les commentaires et les conseils personnalisés générés
							automatiquement à partir des informations collectées par l’application
							coachPME® sont de nature strictement informative. En aucun cas les
							contenus du rapport de diagnostic ne pourront servir de justificatif
							auprès des tiers. Tout partenaire s’engage à tenir INBONIS exempt de
							toute contribution aux dommages et intérêts, indemnités et frais de
							toute nature qui pourraient être prononcés à son encontre à la suite
							d’un préjudice résultant d’un manquement à ses obligations. Les parties
							qui utilisent le rapport de diagnostic sont tenues d’évaluer de manière
							indépendante le caractère approprié de tout investissement ou de toute
							transaction, ou de toute autre décision commerciale ou non-commerciale
							concernant l{"'"}entité évaluée dans le rapport de diagnostic.
						</Text>
						<Text style={legal.paragraph}>
							Le nom et le contenu de coachPME® et d’INBONIS (dont le texte, les
							logos, les images, les graphismes et tout autre signe distinctif et
							éléments composant l’application) est la propriété même d’INBONIS. Tout
							tiers ayant accès au rapport de diagnostic s’engage à respecter les
							règles de propriété intellectuelle françaises et internationales et
							s’engagent notamment à ne pas copier, reproduire, diffuser, modifier,
							altérer, communiquer ou transmettre les éléments de propriété de
							coachPME® sans avoir au préalable obtenu l’autorisation écrite d’INBONIS
							par les personnes dûment habilitées à sa représentation. L’Utilisateur
							et le Distributeur intermédiaire s’engagent par ailleurs à ne pas
							supprimer les clauses et mentions obligatoires prévues par INBONIS.
						</Text>
						<Text style={legal.paragraph}>
							Pour plus d’information, consultez les conditions générales
							d’utilisation de l’application coachPME®.
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};
export default CreatePDF;
