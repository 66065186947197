import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Styles
import {ButtonStyled} from "./Button.styles";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

type ButtonProps = {
	text: string;
	loadingText?: string;
	isLoading?: boolean;
	buttonStyles?: Record<string, unknown>;
	icon?: IconDefinition | undefined;
	iconPosition?: "left" | "right" | undefined;
	iconStyles?: Record<string, unknown>;
	onClick?: () => void;
	className?: string;
	disabled?: boolean;
	type: "button" | "submit" | "reset";
	title?: string;
	textStyles?: Record<string, unknown>;
};

function Button({
	text,
	loadingText,
	isLoading,
	buttonStyles,
	icon,
	iconPosition,
	iconStyles,
	onClick,
	className,
	disabled,
	type,
	title,
	textStyles,
}: ButtonProps) {
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (disabled) {
			return;
		}

		const button = e.currentTarget;
		const circle = document.createElement("span");
		const diameter = Math.max(button.clientWidth, button.clientHeight);
		const radius = diameter / 2;

		circle.style.width = circle.style.height = `${diameter}px`;
		circle.style.left = `${e.clientX - button.offsetLeft - radius}px`;
		circle.style.top = `${e.clientY - button.offsetTop - radius}px`;
		circle.classList.add("ripple");

		const ripple = button.getElementsByClassName("ripple")[0];

		if (ripple) {
			ripple.remove();
		}

		button.appendChild(circle);

		if (onClick) {
			onClick();
		}
	};

	return (
		<ButtonStyled
			style={{...buttonStyles}}
			onClick={(e) => handleClick(e)}
			className={className}
			disabled={disabled}
			type={type}
			title={title}
			iconPosition={iconPosition}
		>
			{iconPosition && !isLoading && icon ? (
				<FontAwesomeIcon icon={icon} style={{...iconStyles}} />
			) : null}
			{isLoading && <div className="spinner" role="status" aria-hidden="true" />}
			<span className="text" style={{...textStyles}}>
				{isLoading ? loadingText : text}
			</span>
		</ButtonStyled>
	);
}

export default React.memo(Button);
