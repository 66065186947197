import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const IntroduceNAFStyled = styled.div`
	.top-button {
		position: absolute;
		top: 35px;
		right: 5%;
		border: 1px solid ${Colors.mainColor};
		font-size: 14px;
		padding: 0.25rem 1rem;
		border-radius: 1rem;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: ${Colors.textColor};

		&:hover {
			border-color: ${Colors.darkBlue};
			background-color: ${Colors.darkBlue};
			color: white;
		}

		span {
			margin-left: 0.5rem;
		}

		${device.laptop} {
			display: none;
		}
	}

	.content {
		width: 100%;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		margin: 0 auto;
		position: relative;

		.padding {
			padding: 1rem;

			${device.laptop} {
				padding: 3rem;
			}

			h1 {
				${device.laptop} {
					margin-bottom: 3rem;
				}
			}

			p {
				color: ${Colors.mainColor};

				${device.laptop} {
					font-size: 20px;
					padding-right: 120px;
					margin-top: 0;
				}
			}
		}

		.bottom-content {
			${device.laptop} {
				display: flex;
				align-items: flex-start;
				justify-content: space-space-between;
			}

			.left {
				display: none;
				${device.laptop} {
					display: flex;
					flex-direction: column;
					width: 35%;
					margin-right: 5%;
				}

				.onboarding-desktop {
					${device.laptop} {
						padding: 1.5rem;
						margin-top: 2rem;
						background-color: #f5f7fb;
					}
				}
			}

			.right {
				${device.laptop} {
					display: flex;
					flex-direction: column;
					width: 60%;
					position: relative;
				}

				.loading {
					.loading-animation {
						animation: shimmerOn 1.2s ease-in-out infinite;

						@keyframes shimmerOn {
							0% {
								background-color: #f2f2f2;
							}
							50% {
								background-color: #f9f9f9;
							}
							100% {
								background-color: #f2f2f2;
							}
						}
					}
				}
			}
		}

		.introduce-naf-form {
			display: flex;
			align-items: center;
			width: 100%;
			margin-top: 2rem;

			input {
				font-size: 14px;
				padding: 0.35rem;
				border-top-left-radius: 0.25rem;
				border-bottom-left-radius: 0.25rem;
				width: 70%;
				border: 1px solid ${Colors.mainColor};
				border-right: none;
				height: 36px;

				${device.laptop} {
					font-size: 16px;
					width: 80%;
					padding-left: 1rem;
				}

				&::placeholder {
					color: #708dbb;
				}
			}
		}

		.results {
			font-size: 14px;
			color: ${Colors.mainColor};
			height: 10rem;
			overflow: hidden;
			overflow-y: auto;

			${device.laptop} {
				font-size: 16px;
				margin-top: 0.5rem;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			}

			ul {
				margin: 0;
				padding: 0;
				list-style: none;
			}

			li {
				display: block;
				line-height: 1.2rem;
				padding-top: 0.4rem;
				padding-bottom: 0.4rem;
				padding-left: 1rem;

				${device.laptop} {
					line-height: 1.2rem;
					padding-top: 0.65rem;
					padding-bottom: 0.65rem;
				}

				&:hover {
					cursor: pointer;
					background-color: ${Colors.yellow};
					line-height: 1.2rem;
					padding-top: 0.65rem;
					padding-bottom: 0.65rem;
				}
			}
		}

		.buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 2rem;

			${device.laptop} {
				margin-left: 40%;
			}
		}
	}
`;
