import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

// Styles
import {OnboardingMenuStyled} from "./OnboardingMenu.styles";
import Colors from "theme/Colors";

// Redux
import {useSelector} from "react-redux";
import {RootState} from "store/store";

interface OnboardingMenuI {
	className?: string;
}

const menuOptions = [
	{
		id: 1,
		name: "1. Choix de l’entreprise",
	},
	{
		id: 2,
		name: "2. Ajout du code NAF",
	},
	{
		id: 3,
		name: "3. Mise à jour des données financières",
		children: [
			{id: 3.1, name: "Effectif"},
			{id: 3.2, name: "Bilan"},
			{id: 3.3, name: "Compte de résultat"},
		],
	},
	{
		id: 4,
		name: "4. Diagnostic",
	},
];

function OnboardingMenu({className}: OnboardingMenuI): JSX.Element {
	const curMenu = useSelector((state: RootState) => state.enterprise.currentOnboardingStep);

	return (
		<OnboardingMenuStyled className={className} hasChildren={curMenu >= 3 ? true : false}>
			<div className="bullets">
				{menuOptions.map((el) => (
					<div
						key={`bullet-${el.id}`}
						className={`bullet${
							el.id === curMenu ? " current" : el.id < curMenu ? " completed" : ""
						}`}
					>
						{el.id < curMenu ? (
							<FontAwesomeIcon icon={faCheck} color={Colors.mainColor} />
						) : null}
					</div>
				))}
			</div>
			<ul>
				{menuOptions.map((el) => (
					<li
						key={`name-${el.id}`}
						className={`bullet${
							el.id === curMenu ? " current" : el.id < curMenu ? " completed" : ""
						}`}
					>
						{el.name}
						{el.children && curMenu >= 3 ? (
							<ul className="children">
								{el.children.map((el) => (
									<li
										key={`name-${el.id}`}
										className={`bullet${
											el.id === curMenu
												? " current"
												: el.id < curMenu
												? " completed"
												: ""
										}`}
									>
										{el.name}
									</li>
								))}
							</ul>
						) : null}
					</li>
				))}
			</ul>
		</OnboardingMenuStyled>
	);
}

export default React.memo(OnboardingMenu);
