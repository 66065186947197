import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type notificationTyped = {
	autoClose: boolean;
	title: string;
	type: number;
	text: string;
	id: number;
};

const initialState = {
	notifications: new Array<notificationTyped>(),
};

let id = 0;
const createNotification = (options: notificationTyped) => {
	return {
		...options,
		id: id++,
	};
};

const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		addNotification(state, action: PayloadAction<notificationTyped>) {
			state.notifications.push(createNotification(action.payload));
		},
		removeNotification(state, action: PayloadAction<number>) {
			state.notifications = state.notifications.filter(
				(notification: notificationTyped) => notification.id !== action.payload
			);
		},
	},
});

export const {addNotification, removeNotification} = notificationsSlice.actions;
export default notificationsSlice.reducer;
