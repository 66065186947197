import Body from "components/layout/Body/Body";
import Button from "components/units/Button/Button";
import React from "react";
import {useHistory} from "react-router";

// Styles
import {Container} from "theme/Theme";
import {QuestionnaireEndWrapper} from "./QuestionnaireEnd.styles";

// Content
import EndImg from "assets/img/felicitations-form.svg";

function QuestionnaireEnd(): JSX.Element {
	const history = useHistory();

	const handleClick = () => {
		history.push("/dashboard");
	};

	return (
		<Body title="Félicitations – Bpifrance en ligne" url="questionnare-end">
			<QuestionnaireEndWrapper>
				<Container>
					<img src={EndImg} alt="Félicitations" className="top-img" />
					<div className="content">
						<div className="left">
							<h1>Félicitations</h1>
							<p>
								Vous avez complété le questionnaire.
								<br />
								Vous pouvez dès à présent accéder à vos résultats détaillés.
							</p>
							<Button
								text="J'accède à mes résultats"
								className="primary"
								type="button"
								buttonStyles={{
									padding: "0.5rem 2rem",
								}}
								onClick={() => setTimeout(() => handleClick(), 500)}
							/>
						</div>
						<div className="right">
							<img src={EndImg} alt="Félicitations" />
						</div>
					</div>
				</Container>
			</QuestionnaireEndWrapper>
		</Body>
	);
}

export default QuestionnaireEnd;
