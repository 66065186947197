import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

interface QuickViewStyledI {
	height: number;
	top: number;
	showComment: boolean;
}

export const QuickViewStyled = styled.div<QuickViewStyledI>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;

	${device.laptop} {
		padding-top: 0.5rem;
	}

	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		background-color: white;
		border-radius: 4px;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		width: 100%;
		padding-bottom: ${(props) => (props.showComment ? "6rem" : 0)};
		> div {
			width: 100%;
		}

		.top {
			max-width: 90%;
			margin: 0 auto;

			${device.laptop} {
				flex-direction: row;
			}

			.top-title {
				width: 100%; 

				${device.tablet} {
					display: flex;
					justify-content: space-between; 
				}

				& button {
					height: 100%;
					margin-bottom: 1rem;
				}
			}

			h1 {
				margin: 0;
				margin-bottom: 1rem;
				font-size: 22px;

				${device.laptop} {
					margin-bottom: 1.7rem;
				}
			}

			p {
				margin: 0;
			}

			margin-top: 1rem;
		}

		.result-graph {
			max-width: 100%;
			margin-top: 3rem;

			${device.laptop} {
				max-width: 80%;
				overflow: initial;
			}
		}

		.comment-modal {
			position: absolute;
			background-color: white;
			border: 3.5px solid ${Colors.yellow};
			border-radius: 4px;
			top: 6rem;
			max-width: 80%;
			min-height: 520px;
			right: 11%;
			padding: 1rem;
			z-index: 9999 !important;

			${device.tablet} {
				top: 12rem;
				height: 500px;
				right: 12%;
				padding: 2rem;
			}

			${device.laptop} {
				max-width: 55%;
				right: 20%;
			}

			h2 {
				margin: 0;
				margin-bottom: 2rem;
				${device.tablet} {
					margin-bottom: 5rem;
				}
			}

			p {
				margin-top: 0;
				margin-bottom: 1.5rem;
				font-size: 0.875rem;
				${device.tablet} {
					font-size: 1rem;
				}
			}

			.button {
				position: absolute;
				bottom: 2rem;
				left: 2rem;
				right: 2rem;

				${device.mobileL} {
					left: auto;
					right: 2rem;
				}
			}
		
			
		}

		.footer {
			display: flex;
			justify-content: center;
			margin-top: 1rem;
			margin-bottom: 3rem;
			flex-direction: column;

			${device.laptop} {
				flex-direction: row;
			}

			button {
				width: 90%;
				margin: 0 auto;
				${device.laptop} {
					width: auto;
					margin: 0;
				}

				&:first-child {
					margin-bottom: 1rem;
					${device.laptop} {
						margin-right: 1.5rem;
						margin-bottom: 0;
					}
				}
			}
		}
	}
`;
