import styled from "styled-components";
import Colors from "theme/Colors";

export const CheckboxStyled = styled.label`
	cursor: pointer;
	position: relative;
	line-height: 18px;
	color: ${Colors.mainColor};
	margin-left: 30px;
	margin-bottom: 20px;
	margin-top: 20px;

	&:hover {
		cursor: pointer;
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked ~ .checkmark {
			border: none;
			background-color: ${Colors.yellow};
		}

		&:checked ~ .checkmark:after {
			display: block;
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: -30px;
		height: 16px;
		width: 16px;
		background-color: transparent;
		border-radius: 2px;
		border: 1px solid ${Colors.darkGrey};

		&:checked {
		}

		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 5px;
			top: 1px;
			width: 5px;
			height: 10px;
			border: solid ${Colors.mainColor};
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}
`;
