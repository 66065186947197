import {Font, StyleSheet} from "@react-pdf/renderer";
import IBMregular from "assets/fonts/IBMPlexSans-Regular.ttf";
import IBMsemibold from "assets/fonts/IBMPlexSans-SemiBold.ttf";
import Colors from "theme/Colors";

Font.register({
	family: "IBM Plex Sans",
	fonts: [
		{src: IBMregular, fontStyle: "normal", fontWeight: 400},
		{src: IBMsemibold, fontWeight: 500},
	],
});

// Create styles
const general = StyleSheet.create({
	body: {
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		fontFamily: "IBM Plex Sans"
	},
	title: {
		fontSize: 96,
		fontWeight: 500,
		margin: 60,
		marginLeft: "5%"
	},
	pagination: {
		position: "absolute",
		bottom: 60,
		right: 60,
		fontSize: 72,
		color: "#666",
	},
});

const front = StyleSheet.create({
	background: {
		width: "100%", 
		height: "99.9%",
		padding: 0,
		margin: 0,
		backgroundColor: Colors.greyBlue,
	},
	logo: {
		margin: 60,
		marginLeft: "5%",
	},
	mainBody: {
		width: "90%",
		backgroundColor: "white",
		margin: "auto",
		marginTop: "5%",
		borderRadius: 20,
	},
	header: {
		width: "100%",
		fontWeight: 500,
		marginTop: 100,
	},
	title: {
		width: "100%",
		textAlign: "center",
		fontSize: 90,
	},
	images: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 100,
		marginBottom: 100, 
	},
	greenIndicator: {
		width: "22%",
		objectFit: "contain",
	},
	mainIndicator: {
		width: "12%",
		objectFit: "contain",
	},
	orangeIndicator: {
		width: "25%",
		objectFit: "contain",
	},
	subTitle: {
		width: "100%",
		marginBottom: 60,
		textAlign: "center",
		fontSize: 70,
		color: "#666",
	}
});

const sommarie = StyleSheet.create({
	mainBody: {
		width: "80%",
		margin: "auto",
		marginTop: 60,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-start",
		width: "100%",
		fontWeight: 400,
		fontSize: 70,
		marginBottom: 80,
	},
	conseils: {
		flexDirection: "column",
		width: "100%",
		marginTop: -30,
		marginBottom: 80,
	},
	conseil: {
		width: "100%",
		marginTop: 45,
		flexDirection: "row",
		alignItems: "center",
		fontSize: 60,
		backgroundColor: Colors.greyBlue,
		borderRadius: 20,
	},
	dot: {
		width: 50,
		height: 50,
		margin: "auto 50",
		borderRadius: "50%",
	},
	conseilContent: {
		position: "relative",
		paddingLeft: 30,
		paddingRight: 60,
		flexDirection: "row",
		alignItems: "center",
		width: "100%",
	},
	spaceBetween: {
		display: "flex",
		flexDirection: "row",
		width: "100%",
		justifyContent: "space-between"
	},
	indicateurText: {
		paddingLeft: 60,
		fontWeight: 500,
	},
	conseilNr: {
		color: Colors.textColor,
	},
});

const tutoriel = StyleSheet.create({
	content: {
		width: "100%",
		height: "99.9%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		padding: 0,
		margin: 0
	},
	graphBlock: {
		width: "68%",
		height: "100%",
		paddingLeft: 60,
		borderRadius: 20,
	},
	graphTitle: {
		fontSize: 72,
		// fontWeight: 500,
	},
	pageSubTitle: {
		fontSize: 36,
		color: Colors.textColor,
		marginTop: 300,
		marginLeft: 40,
	},
	graphContent: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		marginTop: 150,
	},
	rightBlock: {
		width: "30.5%",
		height: "100%",
		backgroundColor: Colors.greyBlue,
	},
	commentBlock: {
		width: "90%",
		display: "flex",
		flexDirection: "column",
		height: 960,
		padding: 45,
		margin: "auto",
		border: 2,
		borderColor: "#DDD",
		borderRadius: 20,
		backgroundColor: "#fff",
	},
	commentBlockText: {
		fontSize: 48,
		// fontWeight: 400,
	},
	footer: {
		padding: 60,
		position: "absolute",
		bottom: 0,
		right: 0,
		fontSize: 72,
		color: "#666",
	},
});

const verticalLines = StyleSheet.create({
	companyResult: {
		paddingVertical: 10,
		paddingHorizontal: 25,
		marginBottom: 25,
		borderRadius: 50,
		color: "white",
		textAlign: "center",
		fontSize: 32,
	},
	verticalLine: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: 280,
		marginRight: 30,
	},
	verticalLineFirst: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: 250,
		marginLeft: 30,
	},
	verticalLineBetweenLeft: {
		height: 3,
		backgroundColor: Colors.lightGrey,
		width: 50,
		position: "relative",
	},
	verticalLineBetweenLeftText: {
		position: "absolute",
		right: 80,
		top: -20,
		fontSize: 26,
		color: Colors.textColor,
	},
	verticalLineBlock: {
		height: 162,
		width: 50,
		marginBottom: 15,
		borderRadius: 20,
		backgroundColor: Colors.lightGrey,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		fontSize: 28,
		color: "#FFF",
	},
	verticalLineLine: {
		height: 175,
		width: 50,
		left: 25,
		borderLeft: `2px solid ${Colors.lightGrey}`,
		display: "flex",
	},
	verticalLineBlockBetween: {
		height: 2,
		backgroundColor: Colors.lightGrey,
		width: 50,
		marginBottom: 15,
		position: "relative",
	},
	verticalLineBlockBetweenText: {
		position: "absolute",
		left: 70,
		top: -20,
		fontSize: 28,
		color: Colors.textColor,
	},
	verticalLineTitle: {
		textAlign: "center",
		fontSize: 28,
		paddingTop: 50,
	},
});

const resultats = StyleSheet.create({
	introduction: {
		width: "100vw",
		height: "100vh",
		padding: 0,
		margin: 0,
		backgroundColor: Colors.greyBlue,
	},
	introductionBox: {
		width: "90%",
		margin: "auto",
		padding: 100,
		borderRadius: 30,
		backgroundColor: "#fff",
	},
	introductionTitle: {
		marginBottom: 45,
		textAlign: "center",
		fontSize: 70,
		fontWeight: 500
	},
	globalEvaluation: {
		margin: "auto",
		marginBottom: 45,
		padding: 45,
		borderRadius: "50%",
		fontSize: 60,
	},
	evaluationButtons: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		fontSize: 60,
	},
	conseilsButton: {
		padding: 20,
		paddingLeft: 60,
		paddingRight: 60,
		margin: 20,
		borderRadius: "50%",
		backgroundColor: Colors.yellow
	},
	variablesAnalyseesButton: {
		padding: 20,
		paddingLeft: 60,
		paddingRight: 60,
		margin: 20,
		borderRadius: "50%",
		backgroundColor: Colors.lightGrey
	},
	background: {
		width: "100vw",
		minHeight: "99.9vh",
		backgroundColor: Colors.greyBlue
	},
	container: {
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "space-between",
		flexDirection: "column",
		width: "90%",
		margin: "auto",
		marginTop: 80,
		marginBottom: 50,
		padding: 60,
		borderRadius: 30,
		backgroundColor: "#fff"
	},
	titles: {
		display: "flex",
		flexDirection: "column",
	},
	title: {
		fontSize: 72,
		fontWeight: 500,
	},
	subTitle: {
		fontSize: 60,
		marginTop: 40,
	},
	variablesTitle: {
		fontSize: 60,
		fontWeight: 500,
		textDecoration: "underline",
		marginTop: 80,
	},
	variable: {
		marginTop: 100,
		border: 2,
		borderColor: Colors.secondaryColor,
		borderRadius: 20,
		padding: 70,
		display: "flex",
		width: "100%",
		flexDirection: "row",
	},
	variableDot: {
		width: 70,
		height: 70,
		borderRadius: 35,
		marginRight: 70,
	},
	variableTitle: {
		fontSize: 60,
		fontWeight: 500,
		marginBottom: 30,
	},
	variableText: {
		fontSize: 60,
		maxWidth: "95%",
	},
});

const conseils = StyleSheet.create({
	conseilSection: {
		marginBottom: 150,
	},
	title: {
		marginTop: 50,
		fontWeight: 500,
		fontSize: 60,
	},
	description: {
		fontSize: 60,
		fontWeight: 500,
		marginTop: 50,
	},
	planTitle: {
		fontSize: 50,
		color: "#666",
		textTransform: "uppercase",
		marginTop: 150,
	},
	planText: {
		fontSize: 60,
		marginTop: 50,
	},
	recommendation: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 75,
		width: "100%",
	},
	recContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: 70,
		border: 2,
		borderStyle: "solid",
		borderColor: "#DDD",
		borderTopRightRadius: 20,
		borderBottomRightRadius: 20,
		width: 2250,
	},
	recImage: {
		width: 500,
		height: 500,
	},
	recTitle: {
		fontSize: 60,
		fontWeight: 500,
	},
	recDesc: {
		fontSize: 52,
	},
	recFooter: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flexDirection: "row",
	},
	recUniversity: {width: 200, height: "auto"},
	recLink: {fontSize: 80, color: "black"},
});

const legal = StyleSheet.create({
	content: {
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		width: "100%",
		paddingLeft: 180,
		paddingRight: 180,
	},
	title: {
		fontSize: 52,
		fontWeight: 500,
		marginTop: 50,
	},
	paragraph: {
		fontSize: 45,
		marginTop: 50,
	},
});

export {general, front, sommarie, tutoriel, verticalLines, resultats, conseils, legal};
