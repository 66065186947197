import {ReactElement} from "react";
import styled from "styled-components";
import Colors from "theme/Colors";

const VerticalDescriptionBarStyled = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 130px;
	margin-top: 35px;
`;

const VertivalBarUnitStyled = styled.div`
	position: relative;
	display: flex;
	right: -3.5rem;
	height: calc(82px + 1rem);
	width: 15px;
	align-items: center;
	justify-content: left;
	text-align: center;
	border-left: 1px solid ${Colors.lightGrey};
	background-color: transparent;
	font-size: 12px;

	&:after {
		display: block;
	}

	.left {
		position: absolute;
		top: -0.75rem;
		left: -3rem;
		font-size: 12px;
		color: ${Colors.textColor};
	}

	.horizontal-line {
		position: absolute;
		top: -0.5rem;
		width: 29px;
		right: 0;
		border-bottom: 1px solid ${Colors.lightGrey};
	}

	.filler {
		position: absolute;
		right: 0.5rem;
		height: 1rem;
		width: 20px;
		margin-top: 80px;
		background-color: white;
	}
`;

function VerticalBar(): ReactElement {
	return (
		<VerticalDescriptionBarStyled className="vertical-horizontal-bar">
			<VertivalBarUnitStyled>
			</VertivalBarUnitStyled>

			<VertivalBarUnitStyled>
				<div className="left">75 %</div>
				<div className="horizontal-line" />
			</VertivalBarUnitStyled>

			<VertivalBarUnitStyled>
				<div className="left">50 %</div>
				<div className="horizontal-line" />
			</VertivalBarUnitStyled>

			<VertivalBarUnitStyled>
				<div className="left">25 %</div>
				<div className="horizontal-line" />
				<div className="filler" />
			</VertivalBarUnitStyled>
		</VerticalDescriptionBarStyled>
	);
}

export default styled(VerticalBar)``;
