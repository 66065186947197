import styled from "styled-components";
import Colors from "theme/Colors";

export const StartDiagnosticWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100vh;
	width: 100%;
	flex-direction: column;
	background-color: ${Colors.background};
	padding-top: 2rem;
	padding-bottom: 2rem;

	.content-wrapper {
		width: 100%;
		max-width: 80%;

		.top-button {
			margin-bottom: 1rem;
			margin-left: auto;
		}
	}

	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: white;
		border-radius: 30px;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		transition: all 0.2s ease;

		&:hover {
			cursor: pointer;
			box-shadow: 0 7px 50px rgba(0, 0, 0, 0.2);
		}

		.content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			padding-top: 4rem;
			padding-bottom: 4rem;

			.left {
				padding-left: 5rem;

				h1 {
					font-size: 40px;
					font-weight: 500;
				}
			}

			.right {
				padding-right: 5rem;
				img {
					width: 290px;
					height: auto;
				}
			}
		}
	}

	.footer-logo {
		align-items: center;
		display: flex;

		svg {
			margin-left: 0.65rem;
		}
	}
`;
