/* eslint-disable jsx-a11y/no-onchange */
import {useState, useEffect} from "react";
import Body from "components/layout/Body/Body";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import OnboardingMenu from "components/composed/OnboardingMenu/OnboardingMenu";
import {useHistory} from "react-router-dom";

// Styles
import {IntroduceNAFStyled} from "./IntroduceNAF.styles";
import {Container} from "theme/Theme";

// Redux
import {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import Button from "components/units/Button/Button";
import Colors from "theme/Colors";
import SelectedEnterprise from "components/composed/SelectedEnterprise/SelectedEnterprise";
import {showRetourModal} from "store/user.slice";
import {setOnboardingStep, setSelectedActivity} from "store/enterprise.slice";
import {getActivities} from "store/enterprise.actions";
// import Spinner from "components/units/Spinner/Spinner";

const ActivitiesDropdown = ({
	activities,
	// selActivity,
	inputVal,
	setInputVal,
}: {
	activities: any;
	// selActivity: string;
	inputVal: string;
	setInputVal: (act: string, code: string, desc: string) => void;
}): JSX.Element => {
	const lis: any = [];
	for (let i = 0; i < activities.length; i++) {
		const act = activities[i];
		const composedAct = `${act.description} - ${act.code}`;
		if (!composedAct.toLowerCase().includes(inputVal.toLowerCase())) {
			continue;
		}

		// if (selActivity !== "parcourir" && !act.associated_cnaes.includes(selActivity)) {
		// 	continue;
		// }

		lis.push(
			<li
				key={i}
				onKeyDown={() => setInputVal(composedAct, act.code, act.description)}
				tabIndex={0}
				onMouseDown={() => setInputVal(composedAct, act.code, act.description)}
				role="menuitem"
			>
				{act.description} - {act.code}
			</li>
		);
	}
	return lis;
};

function IntroduceNAF(): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();
	const activities = useSelector((state: RootState) => state.enterprise.activities);
	// const activitiesCnaes = useSelector((state: RootState) => state.enterprise.activitiesCnaes);

	// Fetch data
	useEffect(() => {
		dispatch(setOnboardingStep(2));

		if (!activities.length) {
			dispatch(getActivities());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Selected activity
	// const [selActivity, setSelActivity] = useState("parcourir");

	// Etape menu
	const [showMenu, setShowMenu] = useState(false);
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	// Handle next
	const handleNext = () => {
		if (inputVal !== "" && selectedEl !== "") {
			setTimeout(() => history.push("/vos-donnees"), 300);
		}
	};

	// Handle result click
	const [showResultsDropdown, setShowResultsDropdown] = useState(false);
	const handleResultClick = (val: string, code: string, desc: string) => {
		setInputVal(val);
		setSelectedEl(val);
		setShowResultsDropdown(false);
		dispatch(setSelectedActivity({code, desc}));
	};

	// Search value
	const [inputVal, setInputVal] = useState("");
	const [selectedEl, setSelectedEl] = useState("");
	const handleChange = (val: string) => {
		setInputVal(val);
		if (val !== "") {
			setShowResultsDropdown(true);
		}
	};

	return (
		<Body url="/secteur-activite" title="Code NAF – Bpifrance en ligne">
			<IntroduceNAFStyled>
				<Container>
					<button type="button" className="top-button" onClick={toggleMenu}>
						<FontAwesomeIcon
							className="icon"
							icon={showMenu ? faAngleUp : faAngleDown}
						/>
						<span>Étape 1</span>
					</button>
					<div className="content">
						<SelectedEnterprise />
						{showMenu ? <OnboardingMenu /> : null}
						<div className="padding">
							<div className="top-content">
								<h1>
									Pour quelle entreprise souhaitez-vous réaliser ce diagnostic ?
								</h1>
							</div>
							<div className="bottom-content">
								<div className="left">
									<SelectedEnterprise className="desktop" />
									<OnboardingMenu className="onboarding-desktop" />
								</div>
								<div className="right">
									<div className="form-content">
										<p>
											Pour continuer, veuillez indiquer le secteur d’activité
											ou le code NAF de cette entreprise
										</p>
										{!activities.length /* || !activitiesCnaes.length */ ? (
											<div
												className="loading"
												style={{
													height: 100,
													width: "100%",
													display: "flex",
													alignItems: "center",
												}}
											>
												{/* <Spinner color={Colors.mainColor} fontSize={24} /> */}
												<div className="loading-animation"/>
											</div>
										) : (
											<form className="introduce-naf-form">
												<input
													type="text"
													value={inputVal}
													placeholder="Secteur d’activité ou code NAF"
													onChange={(e) => handleChange(e.target.value)}
													onBlur={() => setShowResultsDropdown(false)}
												/>
												<Button
													text="Parcourir"
													textStyles={{color: "white", lineHeight: "1em"}}
													type="button"
													onClick={() =>
														setShowResultsDropdown(!showResultsDropdown)
													}
													icon={faAngleDown}
													iconPosition="right"
													iconStyles={{
														color: "white",
														marginLeft: 10,
													}}
													buttonStyles={{
														width: 120,
														borderRadius: 0,
														borderTopRightRadius: 4,
														borderBottomRightRadius: 4,
														borderColor: Colors.mainColor,
													}}
												/>
												{/* <select
													name="enterprise-type"
													id="enterprise-type"
													value={selActivity}
													onChange={(e) => setSelActivity(e.target.value)}
												>
													<option value="parcourir">Parcourir</option>
													{activitiesCnaes.map((op) => (
														<option key={op} value={op}>
															{op}
														</option>
													))}
												</select> */}
											</form>
										)}
									</div>
									{showResultsDropdown === false ? null : (
										<div className="results">
											<ul>
												<ActivitiesDropdown
													activities={activities}
													inputVal={inputVal}
													setInputVal={handleResultClick}
												/>
											</ul>
										</div>
									)}
								</div>
							</div>
							<div className="buttons">
								<Button
									text="Retour"
									icon={faArrowLeft}
									iconPosition="left"
									type="button"
									className="secondary"
									onClick={() => dispatch(showRetourModal())}
									iconStyles={{color: Colors.darkGrey, marginRight: "1rem"}}
									buttonStyles={{
										border: 'none',
									}}
								/>
								<Button
									text="Suivant"
									icon={faArrowRight}
									iconPosition="right"
									type="button"
									className={`secondary ${inputVal !== '' && selectedEl !== '' ? '' : 'disabled'}`}
									onClick={handleNext}
									iconStyles={{color: Colors.mainColor, marginLeft: "1rem"}}
									disabled={inputVal !== "" && selectedEl !== "" ? false : true}
								/>
							</div>
						</div>
					</div>
				</Container>
			</IntroduceNAFStyled>
		</Body>
	);
}

export default IntroduceNAF;
