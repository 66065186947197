import {ReactElement} from "react";
import {Page, Text, View} from "@react-pdf/renderer";
import store from "store/store";
import LogoPDF from "assets/img/LogoPDF";
import {TutorielStyles, VerticalLineStyles} from "./Resultat.styles";
import {getFormattedData} from "utils/graphFn";
import Colors from "theme/Colors";

const getClassName = (row: number, quartile: number, name: string): string => {
	if (row === 4 && quartile === 4 ) return Colors.success;
	if (row === 3 && quartile === 3 ) return Colors.lightYellow;
	if (row === 2 && quartile === 2 ) {
		if (name === 'Croissance' || name === 'Chiffre d’affaires par salarié') return Colors.success;
		return Colors.orange;
	}
	if (row === 1 && quartile === 1 ) {
		if (name === 'Croissance' || name === 'Chiffre d’affaires par salarié') return Colors.orange;
		return Colors.error;
	}
	return Colors.lightGrey;
};

const getResultClassName = (quartile: number, name: string): string => {
	switch(quartile) {
		case 1:
			return name === 'Croissance' || name === 'Chiffre d’affaires par salarié'? Colors.orange : Colors.error;
		case 2:
			return name === 'Croissance' || name === 'Chiffre d’affaires par salarié'? Colors.success : Colors.orange;
		case 3:
			return Colors.lightYellow;
		case 4:
			return Colors.success;
	}
	return "";
}

const showMaxOneDigit = (num: number | string | undefined) => {
	if (typeof num === 'undefined') return
	if (typeof num === 'number') {
		return Number.isInteger(num) ? num : num.toFixed(1)
	}
	const number = parseFloat(num)
	return Number.isInteger(number) ? number : number.toFixed(1)
}

const {
	page,
	logo,
	pageTitle,
	pageSubTitle,
	graphDesciption,
	content,
	graphBlock,
	graphContent,

	footer,
} = TutorielStyles;

const {
	companyResult,
	verticalLine,
	verticalLineFirst,
	verticalLineBlock,
	verticalLineLine,
	verticalLineBetweenLeft,
	verticalLineBetweenLeftText,
	verticalLineBlockBetween,
	verticalLineBlockBetweenText,
	verticalLineTitle,
} = VerticalLineStyles;

const VerticalLineDescription = (): ReactElement => {
	return (
		<View style={verticalLineFirst}>
			<View
				style={{
					height: 85,
				}}
			>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
			<View style={verticalLineBetweenLeft}>
				<Text style={verticalLineBetweenLeftText}>
					75%
				</Text>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
			<View style={verticalLineBetweenLeft}>
				<Text style={verticalLineBetweenLeftText}>
					50%
				</Text>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
			<View style={verticalLineBetweenLeft}>
				<Text style={verticalLineBetweenLeftText}>
					25%
				</Text>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
		</View>
	)
}

const VerticalLineCroissance = ({data}: {data: any}): ReactElement => {
	return (
		<View style={verticalLine}>
			<View
				style={{
					...companyResult,
					backgroundColor: getResultClassName(data.quartile, data.name),
				}}
			>
				<Text>{showMaxOneDigit(data.value)}{' '}{data.sign}</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getClassName(2, data.quartile, data.name),
					height: 452,
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{data.q1}{' '}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getClassName(1, data.quartile, data.name),
					height: 452,
				}}
			>
			</View>
			<View style={verticalLineTitle}>
				<Text>{data.name}</Text>
			</View>
		</View>
	);
};

const VerticalLine = ({data}: {data: any}): ReactElement => {
	const endettement = data?.name === 'Endettement' ? true : false

	return (
		<View style={verticalLine}>
			<View
				style={{
					...companyResult,
					backgroundColor: getResultClassName(data.quartile, data.name),
				}}
			>
				<Text>{showMaxOneDigit(data.value)}{' '}{data.sign}</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getClassName(4, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{endettement? data.q1 : data.q3}{' '}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getClassName(3, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{data.q2}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getClassName(2, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{endettement? data.q3 : data.q1}{' '}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getClassName(1, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineTitle}>
				<Text>{data.name}</Text>
			</View>
		</View>
	);
};

// Create Document Component
const Resultat = (): ReactElement => {
	const sectorial = store.getState().enterprise.diagnosisResponse?.sectorial;
	const DATA = getFormattedData(sectorial);
	if (DATA) {
		return (
			<Page size={{width: 2400, height: 3200}} style={page} orientation="landscape">
				<View style={logo}>
					<LogoPDF />
					<Text style={pageTitle}>Resultat</Text>
				</View>
				<View>
					<Text style={pageSubTitle}>La vignette colorée représente la performance de votre entreprise sur chacun des ratios
et permet de vous situer et de vous comparer avec le secteur.</Text>
					<Text style={graphDesciption}>Répartition des entreprises du secteur</Text>
				</View>
				<View style={content}>
					<View style={graphBlock}>
						<View style={graphContent}>
							<VerticalLineDescription />
							<VerticalLineCroissance data={DATA.activity} />
							<VerticalLine data={DATA.margin} />
							<VerticalLine data={DATA.rentability} />
							<VerticalLine data={DATA.liquidity} />
							<VerticalLine data={DATA.solvency} />
							<VerticalLine data={DATA.debt} />
							<VerticalLineCroissance data={DATA.cost_effectiveness} />
						</View>
					</View>
				</View>
				<View style={footer}>
					<Text>3 / 4</Text>
				</View>
			</Page>
		);
	}
	return <></>;
};
export default Resultat;
