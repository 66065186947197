type questionnaireTyped = Record<
	string,
	{
		prevTitle?: string;
		title: string;
		minutesLeft: number;
		options: {
			value: number | boolean;
			title: string;
			subtitle?: string;
		}[];
	}
>;

const questionnaireContent: questionnaireTyped = {
	who_are_your_clients: {
		title: "Qui sont vos clients ?",
		minutesLeft: 8,
		options: [
			{
				value: 101,
				title: "Individus",
			},
			{
				value: 102,
				title: "Sociétés",
			},
		],
	},
	three_represents_50_percent: {
		title:
			"Trois clients ou moins représentent-ils plus de la moitié de votre chiffre d’affaires ?",
		minutesLeft: 7,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
	differentiating_product: {
		title:
			"Considérez-vous que votre produit/service est différencié par rapport à vos concurrents ?",
		minutesLeft: 7,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
	where_are_competitors_from: {
		title: "La majorité de vos fournisseurs de rang 1 sont situés?",
		minutesLeft: 7,
		options: [
			{
				value: 201,
				title: "Dans ma région",
			},
			{
				value: 202,
				title: "En France",
			},
			{
				value: 203,
				title: "Dans l’espace Schengen",
			},
			{
				value: 204,
				title: "Hors espace Schengen",
			},
		],
	},
	highly_seasonal: {
		title:
			"Votre activité est-elle fortement saisonnière (soit >50% du chiffre d’affaires réalisé en moins de 3 mois)?",
		minutesLeft: 7,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
	exports_regularly: {
		title: "Réalisez-vous du chiffre d’affaires régulier à l’exportation?",
		minutesLeft: 6,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
	why_did_sales_decreased: {
		title:
			"Votre chiffre d’affaires apparait en déclin versus l’année précédente; pour quelle raison?",
		minutesLeft: 6,
		options: [
			{
				value: 501,
				title: "Marché en baisse",
			},
			{
				value: 502,
				title: "Part de clients conséquente",
			},
			{
				value: 503,
				title: "Manque de ressources",
			},
			{
				value: 504,
				title: "Nous sommes déjà en croissance",
			},
		],
	},
	why_is_gross_margin_low: {
		title:
			"Votre taux de marge EBITDA semble inférieure à la moyenne du secteur. Quelle pourrait être l’explication ?",
		minutesLeft: 5,
		options: [
			{
				value: 601,
				title: "Pouvoir de mes clients",
			},
			{
				value: 602,
				title: "Politique de prix agressive",
			},
			{
				value: 603,
				title: "Pouvoir de mes fournisseurs",
			},
		],
	},
	investing_in_company: {
		title: "Est-ce que votre entreprise est en phase d’investissement ?",
		minutesLeft: 5,
		options: [
			{
				value: 701,
				title: "Non, pas actuellement",
			},
			{
				value: 702,
				title: "Oui, en actifs immobilisés",
				subtitle: "(ex fonds de commerce, brevets, matériel industriel…)",
			},
			{
				value: 703,
				title: "Oui, pour renforcer l’effectif",
			},
			{
				value: 704,
				title: "Oui, en marketing",
			},
		],
	},
	time_before_getting_paid: {
		title:
			"Quel est le délai réel entre la livraison d’un produit/service et la réception du paiement ?",
		minutesLeft: 4,
		options: [
			{
				value: 801,
				title: "Encaissement avant livraison - au comptant",
			},
			{
				value: 802,
				title: "Entre 30 et 60 jours",
			},
			{
				value: 803,
				title: "Plus de 90 jours",
			},
		],
	},
	balance_life: {
		title: "Votre solde actuel vous permet de traiter vos paiements pendant:",
		minutesLeft: 4,
		options: [
			{
				value: 901,
				title: "Moins de 1 mois",
			},
			{
				value: 902,
				title: "1 à 3 mois",
			},
			{
				value: 903,
				title: "Plus de 3 mois",
			},
		],
	},
	will_you_use_financiation: {
		title: "Pensez-vous avoir recours au financement bancaire prochainement ?",
		minutesLeft: 3,
		options: [
			{
				value: 1001,
				title: "Non, je n’en ai pas besoin",
			},
			{
				value: 1002,
				title: "Oui, j’y aurai recours",
			},
			{
				value: 1003,
				title: "La banque ne m’en donnera pas",
			},
		],
	},
	kind_of_finance: {
		title: "Quel type de financement ?",
		minutesLeft: 3,
		options: [
			{
				value: 1006,
				title: "Financement export",
			},
			{
				value: 1007,
				title: "Affacturage",
			},
			{
				value: 1008,
				title: "Ligne de crédit",
			},
			{
				value: 1009,
				title: "Prêt à long terme",
			},
		],
	},
	directors_guaranteed_company: {
		title:
			"Les membres de l’équipe de direction ont-ils apportés des cautions personnelles à l’entreprise ?",
		minutesLeft: 2,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
	experience_in_sector: {
		title: "Combien d’années d’expérience avez-vous dans le secteur ?",
		minutesLeft: 2,
		options: [
			{
				value: 1301,
				title: "Moins de 3 ans",
			},
			{
				value: 1302,
				title: "Entre 3 et 10 ans",
			},
			{
				value: 1303,
				title: "Plus de 10 ans",
			},
		],
	},
	future_changes_in_management: {
		title:
			"Des changements au niveau de l’équipe de direction ou de l’actionnariat sont-ils prévus l’année prochaine ?",
		minutesLeft: 1,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
	corona_sales_lost: {
		title:
			"Du fait de la crise du coronavirus, votre entreprise anticipe une perte de chiffre d’affaires équivalente à:",
		minutesLeft: 1,
		options: [
			{
				value: 901,
				title: "Moins de 1 mois",
			},
			{
				value: 902,
				title: "1 à 3 mois",
			},
			{
				value: 904,
				title: "3 à 6 mois",
			},
			{
				value: 905,
				title: "Plus de 6 mois",
			},
		],
	},
	estimates_and_projects: {
		title:
			"Avez-vous l'habitude d’établir des budgets ou des projections de flux de trésorerie ?",
		minutesLeft: 1,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
	social_environmental_programs: {
		title:
			"Votre entreprise participe-t-elle à des actions environnementales (programmes de recyclage des déchets, d’efficacité énergétique, etc.) ?",
		minutesLeft: 0,
		options: [
			{
				value: true,
				title: "Oui",
			},
			{
				value: false,
				title: "Non",
			},
		],
	},
};
export default questionnaireContent;
