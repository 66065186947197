import {useState, useEffect} from "react";
import {Helmet} from "react-helmet";
import {Link, useHistory} from "react-router-dom";
import Button from "components/units/Button/Button";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ConseilsStyled} from "./Conseils.styles";
import {Container} from "theme/Theme";
import BpiFrance from "assets/img/BpiFrance";
import {useAppSelector} from "store/storeHooks";
import CONSEILS from "utils/content.json";
import VARIABLES from "utils/variablesContent.json";
import ConseilsView from "./Views/ConseilsView";
import SingleConseilView from "./Views/SingleConseilView";
import { indicateursAnalysesVariableToTerm, getResultButtonText } from "utils/utils";
import {ConseilI} from "store/enterprise.slice";

function Conseils(): JSX.Element {
	const history = useHistory();
	const curConseils = useAppSelector((state) => state.enterprise.currentConseils);
	const curConseilColor = useAppSelector((state) => state.enterprise.currentConseilColor);
	const curConseilCategorie = useAppSelector((state) => state.enterprise.currentConseilCategorie);
	const report = useAppSelector((state) => state.enterprise.report);
	const conseilContent = curConseils?.map(e => CONSEILS[e]);
	const variablesAnalysees = curConseilCategorie !== undefined ? VARIABLES[curConseilCategorie] : undefined;
	const [viewType, setViewType] = useState<"conseils" | "conseil">("conseils");
	const [curIdx, setCurIdx] = useState<undefined | number>(undefined);
	const [singleContent, setSingleContent] =  useState<undefined | ConseilI>(undefined);
	const [sliderPosition, setSliderPosition] = useState<number>(0);
	const [slideAmount, setSlideAmount] = useState<number>(0);
	const [calculatedSlideAmount, setCalculatedSlideAmount] = useState(false);
	const [slideAction, setSlideAction] = useState('slide-out');
	
	useEffect(() => {
		if (variablesAnalysees && !calculatedSlideAmount) {
			setSlideAmount(100 / Object.keys(variablesAnalysees).length);
			setCalculatedSlideAmount(true)
		}
		
	}, [variablesAnalysees, calculatedSlideAmount])

	const resultButtonText = () => {
		if (curConseilColor === "success") return getResultButtonText("success")
		if (curConseilColor === "yellow") return getResultButtonText("yellow")
		if (curConseilColor === "orange") return getResultButtonText("orange")
		if (curConseilColor === "error") return getResultButtonText("error")
		return ""
	}

	const handleSetSlider = (value: number ) => {
		setSliderPosition(sliderPosition + value)
	}

	const handleSlideIn = () => {
		setSlideAction('slide-in')
	}

	const handleSlideOut = () => {
		setSlideAction('slide-out')
		setTimeout(() => setViewType('conseils'), 250)
	}

	const getColor = (evaluation: Record<string, any>) => {
		if (evaluation.max_score === 2) {
			if (evaluation.score === 1) return "orange"
			return "success"
		} else if (evaluation.max_score === 3) {
			if (evaluation.score === 1) return "error"
			if (evaluation.score === 2) return "orange"
			return "success"
		} else if (evaluation.max_score === 4) {
			if (evaluation.score === 1) return "error"
			if (evaluation.score === 2) return "orange"
			if (evaluation.score === 3) return "yellow"
			return "success"
		} else {
			return "grey"
		}
	}

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Coaching - Mon coaching financier</title>
				<link rel="canonical" href="conseils" />
			</Helmet>
			<ConseilsStyled>
				<Container
					style={{
						zIndex: 9,
						position: "relative",
						paddingTop: 20,
						paddingBottom: 20,
						width: "90%",
						maxWidth: "90%",
					}}
				>
					{viewType === "conseil" && (
						<div 
							className={`semi-transparent-background ${slideAction}`} 
							role="button"
							onClick={() => handleSlideOut()}
							onKeyPress={(e) => e.keyCode === 13 && handleSlideOut()}
							tabIndex={0}
						/>
					)}
					<div className="logo-container">
						<Link to="/" className="logo">
							<BpiFrance />
						</Link>
					</div>
					<div className="body">
						<div>
							<Button
								text="Tous les indicateurs"
								icon={faArrowLeft}
								iconPosition="left"
								iconStyles={{marginRight: "1rem"}}
								buttonStyles={{padding: "0.4rem 1.5rem"}}
								type="button"
								className="go-back"
								onClick={() => history.push("/dashboard")}
							/>
							<div>
								<h1 className="title">
								{curConseilCategorie && indicateursAnalysesVariableToTerm(curConseilCategorie)}
								{curConseils && <button className={curConseilColor}>{resultButtonText()}</button>}
								</h1>
							</div>
						</div>

						<div>
							{viewType === "conseils" ? (<ConseilsView
								conseilContent={conseilContent}
								setViewType={(view) => setViewType(view)}
								setCurIdx={(idx) => setCurIdx(idx)}
								setSingleContent={(selectedContent) => setSingleContent(selectedContent)}
								setSlideAction={() => handleSlideIn()}
							/>) : (
								<SingleConseilView
									conseilContent={singleContent}
									setViewType={(view) => setViewType(view)}
									curIdx={curIdx}
									slideAction={slideAction}
								/>
							)}
						</div>
						<div>
							<div className="card-row">
							<div className="header">
								<h2>Variables analysées</h2>
							</div>
							<div className="card-container">
								<div 
									className="slider-arrow-left"
									role="button"
									onClick={() => handleSetSlider(slideAmount)}
									onKeyPress={(e) => e.keyCode === 13 && handleSetSlider(slideAmount)}
									tabIndex={0}
									style={{
										display: sliderPosition >= 0 || window.innerWidth < 768 ? "none" : "block"
									}}
								>
									<div className="arrow-left" />
								</div>
								<div 
									className="card-slider"
									style={{
										transform: `translate(${sliderPosition}%)`
									}}
								>
									{variablesAnalysees && Object.entries(variablesAnalysees).map((pair, idx) => {
										const evaluation = curConseilCategorie && JSON.parse(JSON.stringify(report))[curConseilCategorie][pair[0]]
										const classColor = getColor(evaluation)

										return (
											<div key={idx} className="card">
												<div>
													<div className={`dot ${classColor}`}></div>
												</div>
												<div>
													<h3>{pair[1].term}</h3>
													<p>{pair[1].description}</p>
												</div>
											</div>
										)
									})}
								</div>
								
								<div 
									className="slider-arrow-right"
									role="button"
									onClick={() => handleSetSlider(-slideAmount)}
									onKeyPress={(e) => e.keyCode === 13 && handleSetSlider(-slideAmount)}
									tabIndex={0}
									style={{
										display: sliderPosition <= (-100 + slideAmount) || window.innerWidth < 768 ? "none" : "block"
									}}
								>
									<div className="arrow-right" />
								</div>
							</div>
							
						</div>

						</div>
						
					</div>
					
				</Container>
			</ConseilsStyled>
		</>
	);
}

export default Conseils;
