/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {ReactElement} from "react";

import {CheckboxStyled} from "./Checkbox.styles";

interface CheckboxI {
	id: string;
	name: string;
	checked: boolean;
	label: ReactElement;
	onChange: () => void;
}

function Checkbox({id, name, checked, label, onChange}: CheckboxI): ReactElement {
	return (
		<CheckboxStyled htmlFor={id}>
			<input id={id} name={name} checked={checked} type="checkbox" onChange={onChange} />
			<span className="checkmark" />
			{label}
		</CheckboxStyled>
	);
}

export default Checkbox;
