import {createSlice} from "@reduxjs/toolkit";
import {postQuestionnaire} from "./questionnaire.actions";

export const USER_STATUS = {
	INITIAL: "INITIAL",
	LOADING: "LOADING",
	LOADED: "LOADED",
};

type questionnaireTyped = {
	status: string;
	answers: {
		who_are_your_clients: 101 | 102 | undefined;
		three_represents_50_percent: boolean | undefined;
		differentiating_product: boolean | undefined;
		where_are_competitors_from: 201 | 202 | 203 | 204 | undefined;
		highly_seasonal: boolean | undefined;
		exports_regularly: boolean | undefined;
		why_did_sales_decreased: 501 | 502 | 503 | 504 | undefined;
		why_is_gross_margin_low: 601 | 602 | 603 | undefined;
		investing_in_company: 701 | 702 | 703 | 704 | undefined;
		time_before_getting_paid: 801 | 802 | 803 | undefined;
		balance_life: 901 | 902 | 903 | undefined;
		will_you_use_financiation: 1001 | 1002 | 1003 | undefined;
		kind_of_finance: 1006 | 1007 | 1008 | 1009 | undefined;
		directors_guaranteed_company: boolean | undefined;
		experience_in_sector: 1301 | 1302 | 1303 | undefined;
		future_changes_in_management: boolean | undefined;
		corona_sales_lost: 901 | 902 | 904 | 905 | undefined;
		estimates_and_projects: boolean | undefined;
		social_environmental_programs: boolean | undefined;
	};
	currentQuestion: string;
};

const initialState: questionnaireTyped = {
	status: USER_STATUS.LOADING,
	answers: {
		who_are_your_clients: undefined,
		three_represents_50_percent: undefined,
		differentiating_product: undefined,
		where_are_competitors_from: undefined,
		highly_seasonal: undefined,
		exports_regularly: undefined,
		why_did_sales_decreased: undefined,
		why_is_gross_margin_low: undefined,
		investing_in_company: undefined,
		time_before_getting_paid: undefined,
		balance_life: undefined,
		will_you_use_financiation: undefined,
		kind_of_finance: undefined,
		directors_guaranteed_company: undefined,
		experience_in_sector: undefined,
		future_changes_in_management: undefined,
		corona_sales_lost: undefined,
		estimates_and_projects: undefined,
		social_environmental_programs: undefined,
	},
	currentQuestion: "who_are_your_clients",
};

export const questionnaireSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		nextQuestion: (state, action) => {
			state.currentQuestion = action.payload;
		},
		prevQuestion: (state, action) => {
			state.currentQuestion = action.payload;
		},
		setAnswer: (state, action) => {
			state.answers = {...state.answers, ...action.payload};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(postQuestionnaire.pending, (state, action) => {
			return;
		});
	},
});

export const {nextQuestion, prevQuestion, setAnswer} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
