import React from "react";
import Notification from "./Notification";
// Styles
import {NotificationsWrapper} from "./Notifications.styles";

// Redux
import {RootState} from "store/store";
import {useDispatch, useSelector} from "react-redux";
import {removeNotification} from "store/notifications.slice";

function Notifications() {
	const dispatch = useDispatch();
	const notifications = useSelector((state: RootState) => state.notifications.notifications);

	return (
		<NotificationsWrapper>
			{notifications
				? notifications.map((notification) => (
						<Notification
							{...notification}
							key={notification.id}
							id={notification.id}
							title={notification.title}
							text={notification.text}
							type={notification.type}
							autoClose={notification.autoClose}
							onDismissClick={() => dispatch(removeNotification(notification.id))}
						/>
				  ))
				: null}
		</NotificationsWrapper>
	);
}

export default React.memo(Notifications);
