import styled from "styled-components";
import {device} from "theme/GlobalStyles";
import Colors from "theme/Colors";

interface DashboardI {
	boxHeight: number;
	childHeight: undefined | number;
}

export const DashboardStyled = styled.div<DashboardI>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;

	${device.laptop} {
		padding-top: 2rem;
	}

	.repartition-entreprises-title {
		display: block;
		font-size: 12px;
		color: ${Colors.textColor};
		margin-bottom: 2.5rem;
	}

	.top-row {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;

		${device.laptop} {
			flex-direction: row;
		}

		.left {
			padding: 1.25rem;
			padding-right: 5rem;
			width: 100%;
			background-color: #fff;
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);

			${device.laptop} {
				padding-right: 5rem;
				width: 43%;
				padding: 2rem;
				height: ${(props) => props.boxHeight}px;
			}

			h2 {
				font-size: 20px;
				margin-bottom: 1.5rem;
			}

			p {
				margin-bottom: 3rem;
				color: #999;
			}

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					margin-bottom: 1.5rem;

					svg {
						margin-right: 1rem;
					}

					&.done {
						text-decoration: line-through;
					}
				}
			}
		}

		.right {
			padding: 1.25rem;
			width: 100%;
			background-color: #fff;
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
			overflow: hidden;

			${device.laptop} {
				width: 53%;
				padding: 2rem;
				height: ${(props) => props.boxHeight}px;
			}

			h2 {
				font-size: 20px;
				margin-bottom: 1.5rem;
			}

			.conseils {
				display: block;
				overflow: hidden;
				overflow-y: auto;
				height: ${(props) => (props.childHeight ? props.childHeight : 360)}px;

				.indicateurs-analyses {
					height: 69px;
					width: 5px;
					margin-right: 1rem;
					
					&.success {
						background-color: ${Colors.success};
					}
	
					&.yellow {
						background-color: ${Colors.lightYellow};
					}
	
					&.orange {
						background-color: ${Colors.orange};
					}
	
					&.error {
						background-color: ${Colors.error};
					}
				}
			
			}
		}

		.indicateurs-analyses-container {
			padding: 1.5rem 2rem;
			width: 100%;
			background-color: #fff;
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);	

			.indicateurs-analyses-titles {
				h2 {
					font-size: 20px;
					margin-bottom: 1.5rem;
				}

				${device.laptop} {
					display: flex;
					justify-content: space-between;
				}
			}

			.conseils {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}

			.indicateurs-analyses-new {
				height: 69px;
				width: 5px;
				margin-right: 1rem;
			}
		}
	}

	.bottom-row {
		width: 100%;
		background-color: #fff;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
		padding: 2rem;
		margin-top: 4rem;
		overflow-x: scroll !important;

		.header {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin-bottom: 1.2rem;

			${device.laptop} {
				flex-direction: row;
				align-items: center;
			}

			h2 {
				font-size: 16px;

				${device.laptop} {
					font-size: 20px;
				}
			}
			span {
				font-size: 14px;
				${device.laptop} {
					font-size: 16px;
				}
			}
		}

		.description-line {
			margin-bottom: 2rem;
		}

		.content {
			width: 100%;
			position: relative;
			overflow: scroll;
		}
	}
`;
