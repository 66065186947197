import styled from "styled-components";
import Colors from "theme/Colors";

interface OnboardingMenuStyledI {
	hasChildren?: boolean;
}

export const OnboardingMenuStyled = styled.div<OnboardingMenuStyledI>`
	padding-left: 1rem;
	padding-right: 1rem;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	.bullets {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;

		.bullet {
			width: 16px;
			height: 16px;
			background-color: #bcbcbc;
			border-radius: 8px;
			align-self: center;
			position: relative;
			margin-bottom: 16px;
			font-size: 8px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:first-child {
				margin-top: 8px;
			}

			&:after {
				content: " ";
				position: absolute;
				top: 16px;
				width: 2px;
				height: 16px;
				left: 7px;
				background-color: #bcbcbc;
			}

			&:nth-child(3) {
				margin-bottom: ${(props) => (props.hasChildren ? "88px" : "16px")};
				&:after {
					height: ${(props) => (props.hasChildren ? "88px" : "16px")};
				}
			}

			&:last-child {
				margin-bottom: 0;

				&:after {
					content: none;
				}
			}

			&.completed {
				background-color: ${Colors.yellow};

				&:after {
					background-color: ${Colors.yellow};
				}
			}
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		padding-left: 1rem;

		li {
			line-height: 2rem;
			font-size: 14px;
			color: #737373;

			&.completed {
				color: ${Colors.mainColor};
			}

			&.current {
				color: ${Colors.mainColor};
				font-weight: bold;
			}

			.children {
				font-weight: normal;
				font-size: 13px;
				padding-left: 2rem;

				li {
					line-height: 1.5rem;
				}
			}
		}
	}
`;
