import styled from "styled-components";

export const LoadingPageAnimation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: white;
  
  .content {
    width: 90%;
    height: 80%;
    max-width: 1100px;
    background-color: #f9f9f9;
    animation: shimmerOn 1.2s ease-in-out infinite;
  }

	@keyframes shimmerOn {
		0% {
			background-color: #f2f2f2;
		}
		50% {
			background-color: #f9f9f9;
		}
		100% {
			background-color: #f2f2f2;
		}
	}

`
