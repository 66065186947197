import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const DoneesFinancieresStyled = styled.div`
	.top-button {
		position: absolute;
		top: 35px;
		right: 5%;
		border: 1px solid ${Colors.mainColor};
		font-size: 14px;
		padding: 0.25rem 1rem;
		border-radius: 1rem;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: ${Colors.textColor};

		&:hover {
			border-color: ${Colors.darkBlue};
			background-color: ${Colors.darkBlue};
			color: white;
		}

		span {
			margin-left: 0.5rem;
		}

		${device.tablet} {
			display: none;
		}
	}

	.content {
		width: 100%;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		margin: 0 auto;

		.padding {
			padding: 1rem;
			p,
			span,
			button {
				font-size: 14px;
			}

			${device.laptop} {
				padding: 3rem;
			}

			.found {
				background-color: #f5f5f5;
				padding: 1.5rem 2rem;
				border-radius: 8px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 2rem 0;

				${device.tablet} {
					height: 180px;
				}

				${device.laptop} {
					margin-top: 0;
				}

				span {
					font-weight: 500;
					${device.laptop} {
						padding: 2rem 1rem;
						font-size: 16px;
						padding-left: 0;
						padding-right: 5rem;
					}
				}

				button {
					color: white;
					background-color: ${Colors.mainColor};
					border: none;
					border-radius: 6px;
					font-size: 13px;
					margin-left: 0.5rem;
					flex-shrink: 0;
					padding: 0.5rem 0.75rem;

					${device.laptop} {
						font-size: 16px;
						padding: 1rem;
					}
				}
			}

			.choice {
				font-weight: 500;
				padding: 2rem;
				border-radius: 8px;
				box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);

				p {
					${device.laptop} {
						font-size: 16px;
						padding-right: 10rem;
						margin-bottom: 2rem;
						line-height: 1.5em;
					}
				}

				.questions {
					display: flex;
					align-items: flex-end;
					width: 100%;
					justify-content: space-between;
					margin-top: 1rem;

					${device.laptop} {
						width: 40%;
						margin-left: auto;
					}

					button {
						width: 45%;
						border: 1px solid ${Colors.mainColor};
						background-color: #fff;
						color: ${Colors.mainColor};
						padding: 0.75rem 1rem;
						border-radius: 7px;
						position: relative;

						&:hover {
							cursor: pointer;
						}

						&.selected {
							border: 2px solid;
							border-color: #1160cc;
						}

						.circle {
							position: absolute;
							top: 50%;
							transform: translate(0, -50%);
							left: -10px;
							width: 20px;
							height: 20px;
							border-radius: 10px;
							background-color: ${Colors.mainColor};
							display: flex;
							justify-content: center;
							align-items: center;

							svg {
								color: white;
								font-size: 10px;
							}
						}
					}
				}
			}

			.sitting-laptop {
				position: relative;
				background-color: #ffe166;
				padding: 1.5rem;
				border-radius: 4px;
				margin: 0rem 0 2rem;
				// margin: 2.5rem 0 2rem;

				img {
					position: absolute;
					top: -25px;
					left: 3px;
					width: 28px;
					height: auto;

					${device.laptop} {
						width: 40px;
						top: -40px;
						left: 10px;
					}
				}

				p {
					margin-top: 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.effectif {
				display: flex;
				flex-direction: column;
				margin-bottom: 2rem;

				label {
					color: ${Colors.mainColor};
					font-size: 14px;
					margin-bottom: 0.75rem;
				}

				input {
					padding: 0.5rem;
					font-size: 14px;
					border-radius: 4px;
					border: 1px solid ${Colors.mainColor};
				}
			}

			.results {
				font-size: 14px;
				color: ${Colors.mainColor};
				height: 10rem;
				overflow: hidden;
				overflow-y: auto;
				border-radius: 4px;

				${device.laptop} {
					font-size: 16px;
					margin-top: 0.5rem;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				}

				ul {
					margin: 0;
					padding: 0;
					list-style: none;
				}

				li {
					display: block;
					line-height: 2rem;
					padding-left: 1rem;

					${device.laptop} {
						line-height: 2.5rem;
					}

					&:hover {
						background-color: ${Colors.yellow};
						cursor: pointer;
					}
				}
			}

			.tabs {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #dde8fb;

				${device.laptop} {
					display: none;
				}

				> div {
					width: 50%;
					font-size: 14px;
					text-align: center;
					padding-bottom: 0.5rem;
					position: relative;

					&:hover {
						cursor: pointer;
					}

					&.selected {
						font-weight: bold;

						&:after {
							position: absolute;
							bottom: 0;
							left: 5%;
							margin-bottom: -2px;
							content: " ";
							width: 90%;
							height: 4px;
							border-radius: 2px;
							background-color: ${Colors.yellow};
						}
					}
				}
			}

			.tabs-content {
				margin-bottom: 3rem;

				${device.laptop} {
					display: none;
				}

				.inline {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 1rem;
					position: relative;

					.label {
						position: relative;
						width: 30%;

						&:hover,
						&:active,
						&:focus {
							.popup-content {
								display: block;
							}
						}

						.popup-content {
							display: none;
							position: absolute;
							top: 1rem;
							left: 1rem;
							width: 250px;
							font-size: 16px;
							padding: 0.8rem;
							background-color: #fff;
							border-radius: 10px;
							border: 1px solid ${Colors.secondaryColor};
							z-index: 99;
						}

						label {
							font-size: 16px;

							&:hover {
								cursor: pointer;
							}
						}
					}

					input {
						font-size: 13px;
						padding: 8px 16px;
						width: 182px;
						flex-shrink: 0;
						border: 1px solid ${Colors.darkBlueGrey};
						border-radius: 4px;
						color: ${Colors.darkBlueGrey};

						&::placeholder {
							color: ${Colors.placeholder};
						}

						&:focus,
						&:hover,
						&:active {
							background-color: ${Colors.greyBlue};
						}

						&::-webkit-outer-spin-button,
						&::-webkit-inner-spin-button {
							-webkit-appearance: none;
							margin: 0;
						}
						-moz-appearance: textfield;
					}

					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					span.euro {
						position: absolute;
						right: 20px;
						color: ${Colors.placeholder};
					}

					span.active {
						color: ${Colors.darkBlueGrey};
					}
				}
			}

			.suivant {
				display: flex;
				align-items: flex-end;
				width: 100%;
				justify-content: flex-end;
				margin-top: 1rem;

				button {
					border: 1px solid #89847e;
					background-color: #fff;
					color: #89847e;
					padding: 0.35rem 1rem;
					border-radius: 1rem;

					${device.laptop} {
						font-size: 16px;
						padding: 0.5rem 1.5rem;
						border-radius: 1.5rem;
					}

					span {
						color: #89847e;
					}

					&.ready {
						border: 1px solid ${Colors.mainColor};
						color: ${Colors.mainColor};

						span {
							color: ${Colors.mainColor};
						}
					}

					svg {
						margin-left: 0.5rem;
					}
				}
			}
		}

		.top-content {
			display: flex;
			flex-direction: column;

			${device.laptop} {
				flex-direction: row;
				align-items: flex-start;
				justify-content: space-between;
				margin-bottom: 3rem;
			}

			h1 {
				${device.laptop} {
					width: 35%;
					margin-right: 5%;
					margin-top: 0;
				}
			}

			p {
				${device.laptop} {
					padding: 0;
					width: 60%;
					font-size: 16px;
					margin-top: 0;
				}

				&.no-sentence {
					display: none;
					${device.laptop} {
						display: none;
					}
				}

				&.only-mobile {
					${device.laptop} {
						display: none;
					}
				}
			}

			.consultez {
				border-radius: 4px;

				${device.laptop} {
					border-radius: 1.25rem;
				}
			}
		}

		.bottom-content {
			${device.laptop} {
				display: flex;
				align-items: flex-start;
				justify-content: space-space-between;
			}

			.left {
				display: none;
				${device.laptop} {
					display: flex;
					flex-direction: column;
					width: 35%;
					margin-right: 5%;
				}

				.onboarding-desktop {
					${device.laptop} {
						padding: 1.5rem;
						margin-top: 2rem;
						background-color: #f5f7fb;
					}
				}
			}

			.right {
				${device.laptop} {
					display: flex;
					flex-direction: column;
					width: 60%;
					position: relative;
				}

				.desktop-form {
					display: none;

					${device.laptop} {
						display: block;
					}
				}
			}
		}

		.buttons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 2rem;

			${device.laptop} {
				margin-left: 40%;
			}
		}

		.form-desktop {
			display: none;

			${device.laptop} {
				display: flex;
				flex-direction: column;
			}

			.titles {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				h3 {
					width: 35%;
					text-align: center;
					font-size: 16px;
				}
			}

			.group {
				display: flex;
				justify-content: space-between;
				width: 100%;
				align-items: center;
				margin-top: 1.5rem;

				.label {
					position: relative;
					width: 30%;

					&:hover {
						.popup-content {
							display: block;
						}
					}

					.popup-content {
						display: none;
						position: absolute;
						top: 1rem;
						left: 2rem;
						width: 300px;
						font-size: 13px;
						padding: 0.8rem;
						background-color: #fff;
						border-radius: 10px;
						border: 1px solid ${Colors.secondaryColor};
						z-index: 99;
					}

					label {
						font-size: 13px;

						&:hover {
							cursor: pointer;
						}
					}
				}

				.inline {
					display: flex;
					align-items: center;
					justify-content: space-between;
					position: relative;

					label {
					}

					input {
						font-size: 13px;
						width: 182px;
						margin-top: 0;
						padding: 8px 16px;
						border: 1px solid ${Colors.darkBlueGrey};
						border-radius: 4px;
						color: ${Colors.darkBlueGrey};

						&::placeholder {
							color: ${Colors.placeholder};
						}
						&:focus,
						&:hover,
						&:active {
							background-color: ${Colors.greyBlue};
						}

						&::-webkit-outer-spin-button,
						&::-webkit-inner-spin-button {
							-webkit-appearance: none;
						}
						-moz-appearance: textfield;
					}

					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					span.euro {
						position: absolute;
						right: 20px;
						color: ${Colors.placeholder};
					}

					span.active {
						color: ${Colors.darkBlueGrey};
					}
				}
			}
		}
	}
`;

export const FakeFound = styled.div`
	width: 100%;
	height: 148px;
	margin-bottom: 2rem;
	background-color: #f2f2f2;
	animation: shimmerOn 1.2s ease-in-out infinite;
	border-radius: 4px;

	@keyframes shimmerOn {
		0% {
			background-color: #f2f2f2;
		}
		50% {
			background-color: #f9f9f9;
		}
		100% {
			background-color: #f2f2f2;
		}
	}
`;

export const FakeChoice = styled.div`
	width: 100%;
	height: 202px;
	margin-bottom: 2rem;
	background-color: white;
	animation: shimmerOn 1.2s ease-in-out infinite;
	border-radius: 4px;
	box-shadow: 0 0 50px rgb(0 0 0 / 5%);
	@keyframes shimmerOn {
		0% {
			background-color: #f5f6f5;
		}
		50% {
			background-color: white;
		}
		100% {
			background-color: #f5f6f5;
		}
	}
`;
