import styled from "styled-components";
import Colors from "theme/Colors";

// Styles
import {device} from "theme/GlobalStyles";

interface GlossaireStyledI {
	contentHeight: number | undefined;
}

export const GlossaireStyled = styled.div<GlossaireStyledI>`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	bottom: 0;
	left: 0;
	overflow: hidden;
	padding: 0rem;
	position: fixed;
	right: 0;
	top: 0;
	opacity: 1;
	z-index: 400;

	.overlay {
		background: ${Colors.backgroundGrey};
		bottom: 0;
		cursor: default;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;

		&.overlay-opening {
			animation: fadeIn 0.3s ease-in-out forwards;
		}

		&.overlay-closing {
			animation: fadeOut 0.3s ease-in-out forwards;
		}

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 0.8;
			}
		}

		@keyframes fadeOut {
			0% {
				opacity: 0.8;
			}
			100% {
				opacity: 0;
			}
		}
	}

	.modal-container {
		display: flex;
		flex-direction: column;
		height: 80vh;
		max-width: 95%;
		min-width: 95%;
		padding: 1rem;
		padding-top: 1.5rem;
		z-index: 1;
		background: #fff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		opacity: 1;
		overflow: hidden;
		margin-bottom: -80vh;

		${device.laptop} {
			max-width: 1100px;
			min-width: 1100px;
			height: 75vh;
			padding: 2rem;
		}

		&.container-opening {
			animation: slideIn 0.3s ease-in-out forwards;
		}

		&.container-closing {
			animation: slideOut 0.3s ease-in-out forwards;
		}

		@keyframes slideIn {
			100% {
				margin-bottom: 0;
			}
		}

		@keyframes slideOut {
			0% {
				margin-bottom: 0;
			}
			100% {
				margin-bottom: -80vh;
			}
		}

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			${device.laptop} {
				display: none;
			}

			h3 {
				font-size: 20px;
				font-weight: 500;
				margin: 0;
			}

			.close {
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					font-size: 14px;
					text-decoration: underline;
				}

				svg {
					margin-right: 0.75rem;
					color: ${Colors.mainColor};
				}
			}
		}

		.header-desktop {
			display: none;

			.left {
				display: flex;
				align-items: center;

				h3 {
					font-size: 33px;
					font-weight: 500;
					margin: 0;
				}
			}

			${device.laptop} {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.search-wrapper {
				position: relative;
				margin-left: 1.75rem;
				width: 400px;

				svg {
					color: ${Colors.mainColor};
					position: absolute;
					top: 8px;
					right: 2px;
				}
			}

			#search-glossaire {
				border: none;
				border-bottom: 1px solid ${Colors.mainColor};
				width: 100%;
				padding: 0.5rem;
				padding-left: 0;

				&:placeholder {
					color: #89847e;
				}

				&:focus {
					outline: none;
				}
			}

			.close-desktop {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: ${Colors.yellow};
				padding: 0.5rem 1.25rem;
				border-radius: 1rem;

				span {
					font-size: 13px;
					text-decoration: none;
				}

				svg {
					margin-right: 0.75rem;
					color: ${Colors.mainColor};
				}

				&:hover {
					cursor: pointer;
				}
			}
		}

		.body {
			.search-wrapper {
				position: relative;
				margin-top: 1.75rem;

				${device.laptop} {
					display: none;
				}

				svg {
					color: ${Colors.mainColor};
					position: absolute;
					top: 8px;
					right: 2px;
				}
			}

			#search-glossaire {
				border: none;
				border-bottom: 1px solid ${Colors.mainColor};
				width: 100%;
				padding: 0.5rem;
				padding-left: 0;

				&:placeholder {
					color: #89847e;
				}

				&:focus {
					outline: none;
				}
			}

			.content {
				display: flex;
				flex-direction: column;
				height: ${(props) => (props.contentHeight ? `${props.contentHeight}px` : "auto")};
				overflow: hidden;
				overflow-y: auto;
				margin-top: 2rem;

				.def {
					margin-bottom: 1.5rem;
					padding-bottom: 1.5rem;
					border-bottom: 1px solid #979797;

					&:last-child {
						border-bottom: none;
					}

					h3 {
						font-weight: 500;
						font-size: 18px;
					}

					p {
						font-size: 14px;
					}
				}
			}
		}
	}
`;
