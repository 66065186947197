import {View, Text} from "@react-pdf/renderer";
import {ReactElement} from "react";
import {/* addWhiteSpace,  */getBlockColor} from "utils/utils";
import {verticalLines} from "./PDF.styles";
import Colors from "theme/Colors";

const getResultClassName = (quartile: number, name: string): string => {
	switch(quartile) {
		case 1:
			return name === 'Croissance' || name === 'Chiffre d’affaires par salarié' ? Colors.orange : Colors.error;
		case 2:
			return name === 'Croissance' || name === 'Chiffre d’affaires par salarié' ? Colors.success : Colors.orange;
		case 3:
			return Colors.lightYellow;
		case 4:
			return Colors.success;
	}
	return "";
}

const showMaxOneDigit = (num: number | string | undefined) => {
	if (typeof num === 'undefined') return
	if (typeof num === 'number') {
		return Number.isInteger(num) ? num : num.toFixed(1)
	}
	const number = parseFloat(num)
	return Number.isInteger(number) ? number : number.toFixed(1)
}

const {
	companyResult,
	verticalLine,
	verticalLineFirst,
	verticalLineBlock,
	verticalLineLine,
	verticalLineBetweenLeft,
	verticalLineBetweenLeftText,
	verticalLineBlockBetween,
	verticalLineBlockBetweenText,
	verticalLineTitle,
} = verticalLines;

export const VerticalLineDescription = (): ReactElement => {
	return (
		<View style={verticalLineFirst}>
			<View
				style={{
					height: 100,
				}}
			>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
			<View style={verticalLineBetweenLeft}>
				<Text style={verticalLineBetweenLeftText}>
					75%
				</Text>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
			<View style={verticalLineBetweenLeft}>
				<Text style={verticalLineBetweenLeftText}>
					50%
				</Text>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
			<View style={verticalLineBetweenLeft}>
				<Text style={verticalLineBetweenLeftText}>
					25%
				</Text>
			</View>
			<View
				style={{
					...verticalLineLine
				}}
			>
			</View>
		</View>
	)
}

export const VerticalLineCroissance = ({data}: {data: any}): ReactElement => {
	return (
		<View style={verticalLine}>
			<View
				style={{
					...companyResult,
					backgroundColor: getResultClassName(data.quartile, data.name),
				}}
			>
				<Text>{showMaxOneDigit(data.value)}{data.sign}</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getBlockColor(2, data.quartile, data.name),
					height: 352,
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{data.q1}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getBlockColor(1, data.quartile, data.name),
					height: 352,
				}}
			>
			</View>
			<View style={verticalLineTitle}>
				<Text>{data.name}</Text>
			</View>
		</View>
	);
};

export const VerticalLine = ({data}: {data: any}): ReactElement => {
	const endettement = data?.name === 'Endettement' ? true : false

	return (
		<View style={verticalLine}>
			<View
				style={{
					...companyResult,
					backgroundColor: getResultClassName(data.quartile, data.name),
				}}
			>
				<Text>{showMaxOneDigit(data.value)}{data.sign}</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getBlockColor(4, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{endettement? data.q1 : data.q3}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getBlockColor(3, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{data.q2}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getBlockColor(2, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineBlockBetween}>
				<Text style={verticalLineBlockBetweenText}>
					{endettement? data.q3 : data.q1}
					{data.sign}
				</Text>
			</View>
			<View
				style={{
					...verticalLineBlock,
					backgroundColor: getBlockColor(1, data.quartile, data.name),
				}}
			>
			</View>
			<View style={verticalLineTitle}>
				<Text>{data.name}</Text>
			</View>
		</View>
	);
};
