import styled from "styled-components";
import Colors from "theme/Colors";
import {hexToRGBA} from "utils/utils";

interface ButtonStyledI {
	iconPosition: "left" | "right" | undefined;
}

export const ButtonStyled = styled.button<ButtonStyledI>`
	position: relative;
	display: flex;
	flex-direction: ${(props) => (props.iconPosition === "right" ? "row-reverse" : "row")};
	justify-content: center;
	align-items: center;
	padding: 0.625rem 1.5rem;
	background-color: ${Colors.mainColor};
	color: ${Colors.mainColor};
	border: none;
	border-radius: 1.25rem;
	outline: none;
	overflow: hidden;
	transition: all 0.8s ease-in-out;
	
	.text {
		margin-left: ${(props) => (props.iconPosition === "left" ? "8px" : "0")};
		margin-right: ${(props) => (props.iconPosition === "right" ? "8px" : "0")};
	}

	span.ripple {
		position: absolute;
		border-radius: 50%;
		transform: scale(0);
		animation: ripple 800ms linear;
		background-color: rgba(255, 255, 255, 0.7);
	}

	@keyframes ripple {
		to {
			transform: scale(3);
			opacity: 0;
		}
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		opacity: 0.65;

		&:hover {
			cursor: not-allowed;
		}
	}

	&:hover {
		cursor: pointer;
	}

	&.primary {
		border: 1px solid ${Colors.yellow};
		background-color: ${Colors.yellow};

		&:hover,
		&:focus {
			border-color: ${Colors.lightYellow};
			background-color: ${Colors.lightYellow};
		}

		&.disabled {
			border-color: ${Colors.lightGrey};
			background-color: ${Colors.lightGrey};
			color: ${Colors.darkGrey};
		}
	}

	&.primary-clear {
		border: 1px solid ${Colors.buttonClear};
		background-color: ${Colors.buttonClear};
	}

	&.secondary {
		border: 1px solid ${Colors.mainColor};
		background-color: white;
		color: ${Colors.textColor};

		svg {
			path {
				transition: all 0.8s ease-in-out;
				color: ${Colors.textColor};
			}
		}

		&:hover,
		&:focus {
			border-color: ${Colors.darkBlue};
			background-color: ${Colors.darkBlue};
			color: white;

			svg {
				path {
					transition: all 0.8s ease-in-out;
					color: white;
				}
			}
		}

		&.disabled {
			border-color: ${Colors.lightGrey};
			background-color: ${Colors.lightGrey};
			color: ${Colors.darkGrey};

			svg {
				path {
					transition: all 0.8s ease-in-out;
					color: ${Colors.textColor};
				}
			}
		}
	}

	.spinner {
		width: 20px;
		height: 20px;
		margin-right: 10px;
		border: 2px solid ${hexToRGBA("#FFF", 0.25)};
		border-top-color: ${hexToRGBA("#000", 0.25)};
		border-radius: 50%;
		animation: rotation 0.8s ease infinite;
	}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;
