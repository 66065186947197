import React from "react";
import Inbonislogo from "assets/img/LogoInbonis";
import {Link} from "react-router-dom";

// Styles
import {FooterWrapper} from "./Footer.styles";
import {Container} from "theme/Theme";

const Footer = () => {
	return (
		<FooterWrapper>
			<Container className="container">
				<div className="legal">
					<ul>
						<li>
							<Link to="/cgu">Mentions légales</Link>
						</li>
						<li>
							<Link to="/cgu">CGU</Link>
						</li>
						<li>
							<a href="mailto:coach@inbonis.com">Contact</a>
						</li>
					</ul>
					<span>Copyright © {new Date().getFullYear()} Bpifrance</span>
				</div>
				<div className="footer-logo">
					<span>Propulsé par</span>
					<Inbonislogo />
				</div>
			</Container>
		</FooterWrapper>
	);
};

export default React.memo(Footer);
