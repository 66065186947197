import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReactElement} from "react";
import {ConseilI} from "store/enterprise.slice";
import ICON_UNIVERSITY from "assets/img/icon_university.png";

interface ConseilsViewI {
	conseilContent: undefined | ConseilI;
	setViewType: (conseilView: "conseils") => void;
	curIdx: number | undefined;
	slideAction: string;
}

function SingleConseilView({conseilContent, setViewType, curIdx, slideAction}: ConseilsViewI): ReactElement {
	const selectedIdx = curIdx !== undefined ? curIdx : 0;
	const changeView = () => {
		setTimeout(() => setViewType("conseils"), 250)
	}
	return (
		<div className={`conseil-detailed ${slideAction}`}>
			<div className="single-conseil-header">
				<div className="conseil-nr">
					Conseil №{selectedIdx + 1}
				</div>
				<div
					className="go-back"
					onClick={() => changeView()}
					tabIndex={0}
					onKeyPress={(e) => e.keyCode === 13 && changeView()}
					role="button"
				>
					<FontAwesomeIcon icon={faTimes} style={{marginRight: 8}} />
				</div>
			</div>
			{conseilContent?.conseil[0] && 
				<div className="desc">{conseilContent?.conseil[0]}</div>
			}
			{conseilContent?.plan_action ? (
				<div className="plan-action">
					<div className="title">Plan d’action </div>
					{conseilContent?.plan_action.map((plan, idx) => (
						<div className="desc" key={idx} dangerouslySetInnerHTML={{__html: plan}} />
					))}
				</div>
			) : (
				<></>
			)}
			<div className="plus">
				<div className="title" style={{marginBottom: 10}}>
					Pour aller plus loin
				</div>
				<div className="recommendation-card-container">
					{conseilContent?.formations_bpifrance_universite.map((formation, idx) => (
						<div key={idx} className="recommendation">
							<a
								href={conseilContent?.lien_lms[idx]}
								style={{textDecoration: 'none'}}
								className="external"
								target="_blank"
								rel="noreferrer"
							>
								<div className="recommendation-container">
									<img
										className="visible-on-large-screen"
										src={`${process.env.PUBLIC_URL}/${conseilContent?.image[idx]}.png`}
										alt=""
									/>
									<div className="content">
										<div className="visible-on-small-screen">
											<img
												src={`${process.env.PUBLIC_URL}/${conseilContent?.image[idx]}.png`}
												alt=""
											/>
										</div>
										<h3>{formation}</h3>
										<div className="desc">
											{conseilContent?.pour_aller_plus_loin_text[idx]}
										</div>
										<div className="footer">
											<img
												src={ICON_UNIVERSITY}
												alt="BPI France University"
												className="icons"
											/>
											
												↗
										
										</div>
									</div>
								</div>
							</a>
						</div>
					))}

				</div>
			</div>
		</div>
	);
}

export default SingleConseilView;
