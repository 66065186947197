import React, {useState, useEffect} from "react";
import OnboardingMenu from "components/composed/OnboardingMenu/OnboardingMenu";
import SelectedEnterprise from "components/composed/SelectedEnterprise/SelectedEnterprise";

// Content
// eslint-disable-next-line
import GirlLaptop from "assets/img/girl-laptop.png";

// Redux
import {useDispatch, useSelector} from "react-redux";
import {setOnboardingStep, setSalaries, ENTERPRISE_STATUS} from "store/enterprise.slice";
import {RootState} from "store/store";
import ConsultezNotreGlossaire from "./ConsultezNotreGlossaire";

const options = [
	{
		value: 0,
		name: "0 salarié",
	},
	{
		value: 1,
		name: "1 ou 2 salariés",
	},
	{
		value: 4,
		name: "3 à 5 salariés",
	},
	{
		value: 7,
		name: "6 à 9 salariés",
	},
	{
		value: 15,
		name: "10 à 19 salariés",
	},
	{
		value: 30,
		name: "20 à 49 salariés",
	},
	{
		value: 75,
		name: "50 à 99 salariés",
	},
	{
		value: 150,
		name: "100 à 199 salariés",
	},
	// {
	// 	value: 325,
	// 	name: "250 à 499 salariés",
	// },
	{
		value: 225,
		name: "200 à 249 salariés",
	},
	// {
	// 	value: 750,
	// 	name: "500 à 999 salariés",
	// },
	// {
	// 	value: 1500,
	// 	name: "1000 à 1999 salariés",
	// },
	// {
	// 	value: 3500,
	// 	name: "2000 à 4999 salariés",
	// },
	// {
	// 	value: 7500,
	// 	name: "5000 à 9999 salariés",
	// },
	// {
	// 	value: 1000,
	// 	name: "10000 salariés et plus",
	// },
	{
		value: 250,
		name: "Plus de 250 salariés",
	},
];

const getSalariesName = (val: number): string => {
	if (val === -1) {
		return "";
	}
	const op = options.find((el) => el.value === val);
	if (op?.name) {
		return op.name;
	} else {
		return "";
	}
};

function Step2(): JSX.Element {
	const dispatch = useDispatch();
	const diagnosisAnonStatus = useSelector(
		(state: RootState) => state.enterprise.diagnosisAnonStatus
	);
	const salaries = useSelector((state: RootState) => state.enterprise.salaries);

	useEffect(() => {
		dispatch(setOnboardingStep(3.1));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [showResult, setShowResult] = useState(false);

	const handleSetSalaries = (op: {value: number; name: string}) => {
		dispatch(setSalaries(op.value));
		setShowResult(false);
	};

	return (
		<>
			<div className="top-content">
				<h1>Données financières</h1>
				<p className="no-sentence">
					Ce diagnostic se nourrit des données publiques agrégées auprès des sources
					telles que la Banque de France, l’INSEE et l’INPI.
				</p>
				<p className="only-mobile">
					Nous avons besoin de plus d’informations pour continuer. Veuillez renseigner les
					informations du bilan de l’entreprise.
				</p>
				<ConsultezNotreGlossaire />
			</div>
			<div className="bottom-content">
				<div className="left">
					<SelectedEnterprise className="desktop" />
					<OnboardingMenu className="onboarding-desktop" />
				</div>
				<div className="right">
					{diagnosisAnonStatus !== ENTERPRISE_STATUS.SUCCESS ? (
						<div className="sitting-laptop">
							{/* <img src={GirlLaptop} alt="Laptop" /> */}
							<p style={{fontWeight: 500}}>
								Les données financières de l’entreprise ne sont pas disponibles
							</p>
							<p>Pour continuer, veuillez indiquer l’effectif de votre entreprise</p>
						</div>
					) : null}
					<form className="effectif">
						<label htmlFor="effectif">Effectif de votre entreprise</label>
						<input
							id="effectif"
							name="effectif"
							type="text"
							placeholder="Nombre d'employés"
							value={getSalariesName(salaries)}
							onFocus={() => setShowResult(true)}
						/>

						{showResult ? (
							<div className="results">
								<ul>
									{options.map((op) => (
										<li
											key={op.value}
											onKeyPress={(e) =>
												e.keyCode === 13 && handleSetSalaries(op)
											}
											tabIndex={0}
											onClick={() => handleSetSalaries(op)}
											role="menuitem"
										>
											{op.name}
										</li>
									))}
								</ul>
							</div>
						) : null}
					</form>
				</div>
			</div>
		</>
	);
}

export default Step2;
