import {ReactElement} from "react";
import {Document} from "@react-pdf/renderer";
import Resultat from "./Pages/Resultat";
import Front from "utils/PDF/Front";
import Tutoriel from "utils/PDF/Tutoriel";
import MentionsLegales from "utils/PDF/MentionsLegales";

// Create Document Component
const CreatePDF = (): ReactElement => {
	return (
		<Document
			language="fr"
			title="Inbonis Rating - Bpifrance"
			subject="Diagnostic Financier"
			author="Inbonis Rating"
		>
			<Front />
			<Tutoriel />
			<Resultat />
			<MentionsLegales />
		</Document>
	);
};
export default CreatePDF;
