import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const RetourModalStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0;
	left: 0;
	overflow: hidden;
	padding: 0rem;
	position: fixed;
	right: 0;
	top: 0;
	opacity: 1;
	z-index: 400;

	.overlay {
		background: #f5f7fb;
		bottom: 0;
		cursor: default;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		animation: fadeIn 0.3s ease-in-out forwards;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 0.62;
			}
		}
	}

	.modal-container {
		display: flex;
		flex-direction: column;
		height: auto;
		max-width: 90%;
		padding: 1rem;
		padding-top: 1.5rem;
		z-index: 1;
		background: #fff 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
		opacity: 0;
		animation: fadeInModal 0.3s ease-in-out forwards;

		${device.laptop} {
			padding: 3rem;
		}

		@keyframes fadeInModal {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 1;
			}
		}

		.body {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding-top: 2.5rem;

			${device.laptop} {
			}

			img {
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -70%);
				z-index: 999;

				${device.laptop} {
					transform: translate(-50%, -75%);
				}
			}

			h3 {
				font-size: 18px;
				text-align: center;

				${device.laptop} {
					font-size: 25px;
				}
			}

			p {
				font-size: 14px;
				text-align: center;
				color: ${Colors.mainColor};
			}

			.menu {
				margin: 0 auto;
			}

			.buttons {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				margin-top: 2rem;

				${device.laptop} {
					flex-direction: row;
				}

				button:last-child {
					margin-top: 1rem;

					${device.laptop} {
						margin-top: 0rem;
						margin-left: 3rem;
					}
				}
			}
		}
	}
`;
