import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const HomeWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;

	${device.laptop} {
		padding-top: 2rem;
	}

	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: white;
		border-radius: 4px;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		width: 100%;
		padding: 1rem;

		${device.mobileL} {
			padding: 2rem;
			padding-right: 1rem;
		}

		${device.laptop} {
			padding-right: 0;
		}

		.content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			flex-direction: column;

			${device.laptop} {
				flex-direction: row;
			}

			.top-button {
				background-color: ${Colors.buttonClear};
				border: none;
				border-radius: 17px;
				color: ${Colors.mainColor};
				padding: 0.5rem;
				transition: all 0.15s ease-in-out;
				font-weight: 500;
				font-size: 11px;
				align-self: flex-start;
				margin-bottom: 1rem;

				${device.mobileL} {
					font-size: 12px;
				}

				${device.tablet} {
					display: none;
				}

				span {
					position: relative;
					display: inline-block;
					z-index: 1;

					&:before {
						content: " ";
						height: 8px;
						width: 105%;
						background-color: ${Colors.yellow};
						position: absolute;
						top: 10px;
						z-index: -1;
						margin-left: -2px;
					}
				}
			}

			.left {
				display: flex;
				flex-direction: column;
				width: 90%;

				${device.laptop} {
					padding-left: 1rem;
				}

				h1 {
					font-size: 20px;
					font-weight: 500;

					${device.tablet} {
						font-size: 33px;
					}
				}

				.images {
					display: flex;
					justify-content: space-between;
					width: 100%;
					padding: 1rem 0;

					img {
						width: 30%;
					}

					.img-main-indicator {
						width: 20%;
					}

					${device.laptop} {
						display: none;
					}
				}

				p {
					color: #071a39;
					opacity: 0.4;
					margin-bottom: 1rem;

					${device.laptop} {
						padding-right: 4rem;
					}
				}

				.checkbox {
					display: flex;
					align-items: flex-start;
					margin-top: 2rem;
					margin-bottom: 2rem;

					input {
						margin: 0;
						margin-top: 2px;
						margin-right: 1rem;
					}

					label {
						font-size: 14px;

						a {
							color: ${Colors.mainColor};
						}
					}
				}

				button.bottom {
					margin-bottom: 2rem;
					align-self: center;

					${device.laptop} {
						align-self: flex-start;
					}
				}
			}

			.right {
				position: relative;
				display: block;
				min-width: 400px;
				min-height: 500px;
				display: none;

				${device.laptop} {
					display: block;
				}

				.img-main-indicator {
					position: absolute;
					top: 40%;
					left: 1rem;
				}

				.img-green-indicator {
					position: absolute;
					top: 0;
					right: 0;
				}

				.img-orange-indicator {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				#img-graph {
					display: none;
				}

				${device.laptopL} {
					#img-graph {
						display: block;
						position: absolute;
						right: 0;
						top: 45%;
						transform: translate(45%, -50%) scale(0.25);
					}
				}
			}
		}
	}
`;
