const Colors = {
	textColor: "#54514D",
	mainColor: "#071A39",
	mainColorLight: "#395179",
	secondaryColor: "#CCC",
	darkGrey: "#666",
	backgroundGrey: "#00040C",
	error: "#ED122E",
	background: "#F7F6F6",
	yellow: "#FFCD00",
	lightYellow: "#FFE166",
	success: "#1EBA83",
	buttonClear: "#dde8fb",
	darkBlue: "#395179",
	darkBlueGrey: "#001D4A",
	greyBlue: "#F5F7FB",
	lightGrey: "#eaeae9",
	orange: "#EA7600",
	placeholder: "#5273A7"
};

export default Colors;
