// import Spinner from "components/units/Spinner/Spinner";
import React from "react";
// import Colors from "theme/Colors";
import { LoadingPageAnimation } from "./LoadingPage.styles";

function LoadingPage(): JSX.Element {
	// return (
	// 	<div
	// 		style={{
	// 			width: "100wh",
	// 			height: "100vh",
	// 			display: "flex",
	// 			alignItems: "center",
	// 			justifyContent: "center",
	// 			backgroundColor: "#F6F6F6",
	// 		}}
	// 	>
	// 		<Spinner color={Colors.mainColor} />
	// 	</div>
	// );
	return (
		<LoadingPageAnimation>
			<div className="content" />
		</LoadingPageAnimation>
	)
}

export default LoadingPage;
