import styled from "styled-components";

export const LegalStyled = styled.div`
	display: flex;
	flex-direction: column;

	h1 {
		margin-bottom: 1.5rem;
		font-size: 28px;
	}

	h2 {
		font-size: 21px;
		margin-bottom: 0.5rem;
		margin-top: 1.5rem;
	}

	p {
		margin-top: 0;
		margin-bottom: 1rem;
		text-align: justify;
	}
`;
