import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const SelectedEnterpriseStyled = styled.div`
	margin-bottom: 1rem;
	width: 100%;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	transition: all 0.15s ease;
	background-color: ${Colors.yellow};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	position: relative;
	overflow: hidden;
	padding: 0.75rem 0;

	${device.laptop} {
		display: none;
		flex-direction: row;
	}

	.top {
		display: flex;
		align-items: flex-start;
		width: 100%;

		${device.laptop} {
			margin-top: 0.5rem;
			display: flex;
		}
	}

	.left {
		width: 80px !important;
	}

	.right {
		display: block;
		width: 100%;
	}

	.circle {
		width: 50px;
		height: 50px;
		background-color: ${Colors.lightYellow};
		border-radius: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: auto !important;
		flex-shrink: 0;

		svg {
			font-size: 1.25rem;
		}
	}

	.decorative-circle {
		position: absolute;
		width: 90px;
		height: 90px;
		background-color: ${Colors.lightYellow};
		border-radius: 39px;
		bottom: -40px;
		right: -40px;

		svg {
			font-size: 1.25rem;
		}
	}

	.data {
		font-size: 16px;
		font-weight: 600;
		display: block;
		z-index: 1;
		margin-left: 0.5rem;
		height: 130px;

		${device.tablet} {
			height: 130px;
		}

		.siren,
		.selected-activity {
			display: block;
			color: ${Colors.mainColor};
			font-weight: 400;
			font-size: 14px;
			margin-top: 0.25rem;
		}

		.siren {
			margin-bottom: 0.5rem;
		}
	}

	.change-enterprise {
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		font-size: 14px;
		text-decoration: underline;
		font-weight: 400;
		z-index: 1;		
	}

	&.desktop {
		margin-bottom: 0;
		border-radius: 8px;
		display: none;

		${device.laptop} {
			display: flex;
		}

		.left {
			width: 80px !important;
			padding: auto;
		}

		.right {
			width: 100% !important;
		}

		.circle {
			margin-left: 1rem !important;

			svg {
				font-size: 18px;
			}
		}

		.change-enterprise {
			width: 100%;
			text-align: right;
			margin: 0.5rem 1rem 0 0;
		}

		.data {
			font-size: 26px;
		}
	}

	a {
		color: ${Colors.mainColor};
	}
`;
