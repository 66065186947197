import React, {useState, useEffect} from "react";
import {Route, Switch} from "react-router-dom";

import Home from "screens/Home/Home";
import Error404 from "screens/Error404/Error404";
import Questionnaire from "screens/Questionnaire/Questionnaire";
import Bienvenue from "screens/Bienvenue/Bienvenue";
import IntroduceNAF from "screens/IntroduceNAF/IntroduceNAF";
import StartDiagnostic from "screens/StartDiagnostic/StartDiagnostic";
import NotSubscribed from "screens/NotSubscribed/NotSubscribed";
import CGU from "screens/Legal/CGU";
import DoneesFinancieres from "screens/DonneesFinancieres/DonneesFinancieres";
import ProtectedRoute from "components/composed/ProtectedRoute/ProtectedRoute";
import QuestionnaireEnd from "screens/QuestionnaireEnd/QuestionnaireEnd";
import Dashboard from "screens/Dashboard/Dashboard";
import Conseils from "screens/Conseils/Conseils";
import QuickView from "screens/QuickView/QuickView";
// import Start from "screens/Start/Start";
import PDF from "screens/Dashboard/CreatePDF/RenderPDF";


function App(): JSX.Element {
	const [counter, setCounter] = useState<number>(0)

	// wrapping setTimeout in Promise removes repeated [violation] warnings in console	
	const timeoutHandler = async() => {
		await new Promise(resolve => setTimeout(resolve, 1))
		setCounter(counter+1)
	}
	
	useEffect(() => {
		if (counter >= 59) window.location.replace("https://app.bel.bpifrance.fr/dashboard")
		const timer = setTimeout(timeoutHandler, 60000)

		return () => clearTimeout(timer)
		// eslint-disable-next-line
	}, [counter])

	return (
		<Switch>
			<Route exact path="/not-subscribed" component={NotSubscribed} />
			{/* <Route exact path="/start" component={Start} /> */}

			<Route exact path="/quick-view" component={QuickView} />
			<Route exact path="/questionnaire" component={Questionnaire} />
			<Route exact path="/questionnaire-end" component={QuestionnaireEnd} />
			<Route exact path="/selection-entreprise" component={Bienvenue} />
			<Route exact path="/secteur-activite" component={IntroduceNAF} />
			<Route exact path="/vos-donnees" component={DoneesFinancieres} />

			<Route exact path="/start-diagnostic" component={StartDiagnostic} />
			<Route exact path="/dashboard" component={Dashboard} />
			<Route exact path="/conseils" component={Conseils} />
			<Route exact path="/document" component={PDF} />

			{/* Legal */}
			<Route exact path="/cgu" component={CGU} />

			<ProtectedRoute path="/"component={Home} />
			{/* 404 */}
			<Route component={Error404} />
		</Switch>
	);
}

export default App;
