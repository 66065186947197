import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const QuestionnaireEndWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-top: 3rem;
	margin-bottom: 3rem;

	${device.laptop} {
		margin-top: 7rem;
		margin-bottom: 7rem;
	}

	.top-img {
		max-width: 65%;
		margin: 0 auto;
		width: 100%;
		height: auto;

		${device.laptop} {
			display: none;
		}
	}

	.content {
		width: 100%;
		margin: 0 auto;
		position: relative;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		padding: 1.5rem;
		padding-bottom: 2rem;
		border-top: 4px solid ${Colors.yellow};

		${device.laptop} {
			padding: 4.5rem;
			box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.left {
			h1 {
				${device.laptop} {
					margin-top: 0;
				}
			}

			p {
				font-size: 14px;
				color: ${Colors.mainColor};

				${device.laptop} {
					font-size: 16px;
				}
			}

			button {
				margin: 0 auto;
				margin-top: 2rem;

				${device.laptop} {
					margin-left: 0;
					margin-top: 4rem;
				}
			}
		}

		.right {
			display: none;

			${device.laptop} {
				display: flex;
			}
			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
`;
