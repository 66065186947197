import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar, faTimes} from "@fortawesome/free-solid-svg-icons";

// Styles
import {ServicePremiumStyled} from "./ServicePremium.styles";

// Redux
import {useDispatch} from "react-redux";
import {showServicePremiumModal} from "store/user.slice";
import Button from "components/units/Button/Button";
import Colors from "theme/Colors";

// Assets
import CROISSANCE from "assets/img/error-modal/croissance.svg";
import ENDETTEMENT from "assets/img/error-modal/endettement.svg";
import RENDEMENT from "assets/img/error-modal/rendement.svg";
import VECTOR from "assets/img/error-modal/vector.png";


interface ServicePremiumI {
	isActive: boolean;
}

function ServicePremium({isActive}: ServicePremiumI): JSX.Element {
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(showServicePremiumModal());
	};

	return isActive ? (
		<ServicePremiumStyled contentHeight={undefined}>
			<div
				className="overlay"
				onClick={() => handleClose()}
				role="button"
				onKeyPress={(e) => e.keyCode === 13 && handleClose()}
				tabIndex={0}
			/>
			<div className="modal-container">
				<div className="header">
					<h2 className="title">
						<img src={VECTOR} alt="Vector" className="vector" />
						<span>Service Premium</span>
					</h2>
					<div
						className="close visible-on-large-screen"
						onClick={handleClose}
						role="button"
						tabIndex={0}
						onKeyPress={(e) => e.keyCode === 13 && handleClose()}
					>
						<FontAwesomeIcon icon={faTimes} />
						<span>Retour à mon cockpit</span>
					</div>
				</div>
				<div className="body">
					<h3>
						Ce service est inclus dans les abonnements Bpifrance en ligne.
						<br /> Découvrez ces services additionnels et souscrivez en 1 clic, sans
						engagement.
					</h3>
					<div className="prices">
						<div className="price">
							<h5>Enterprise</h5>
							<h3>
								<span className="cost">10€</span> / mois (HT)
							</h3>
							<span>Sans engagement</span>
							<button>60 jours d{"'"}essai</button>
						</div>
						<div className="price special">
							<h5>Enterprise</h5>
							<h3>
								<span className="cost">10€</span> / mois (HT)
							</h3>
							<span>Sans engagement</span>
							<button>60 jours d{"'"}essai</button>
						</div>
						<div className="price">
							<h5>Groupe</h5>
							<h3>
								<span className="cost">10€</span> / mois (HT)
							</h3>
							<span>Sans engagement</span>
							<button>60 jours d{"'"}essai</button>
						</div>
					</div>
					<Button
						type="button"
						text="Découvrir les abonnements"
						icon={faStar}
						iconPosition="left"
						textStyles={{paddingLeft: "0.5rem"}}
						className="discover-subscription-btn"
						buttonStyles={{
							backgroundColor: Colors.yellow,
							borderRadius: "1.5rem",
						}}
					/>
					<div
						className="close visible-on-small-screen"
						onClick={handleClose}
						role="button"
						tabIndex={0}
						onKeyPress={(e) => e.keyCode === 13 && handleClose()}
					>
						<FontAwesomeIcon icon={faTimes} />
						<span>Retour à mon cockpit</span>
					</div>
				</div>
				<img src={CROISSANCE} alt="Croissance" className="croissance" />
				<img src={ENDETTEMENT} alt="Endettement" className="endettement" />
				<img src={RENDEMENT} alt="Rendement" className="rendement" />
			</div>
		</ServicePremiumStyled>
	) : (
		<></>
	);
}

export default ServicePremium;
