import {useState, useEffect} from "react";

const hasWindow = typeof window !== "undefined";

function getWindowDimensions() {
	const width: number | null = hasWindow ? window.innerWidth : null;
	const height: number | null = hasWindow ? window.innerHeight : null;
	return {
		width,
		height,
	};
}

function useWindowSize(): {width: number; height: number} {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	function handleResize() {
		setWindowDimensions(getWindowDimensions());
	}

	useEffect(() => {
		if (hasWindow) {
			handleResize();
			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}
	}, []);
	// @ts-ignore
	return windowDimensions;
}

export default useWindowSize;
