import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "store/store";
import App from "./App";
import axios from "axios";
import {setAuthorizationToken} from "utils/utils";
import "normalize.css";
import GlobalStyle from "theme/GlobalStyles";

if (localStorage.getItem("access_token")) {
	setAuthorizationToken(localStorage.getItem("access_token"));
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle />
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);
