import React from "react";

function LogoInbonis(): JSX.Element {
	return (
		<svg
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 934.7 158.9"
		>
			<g id="artwork">
				<g>
					<g>
						<path
							className="st0"
							fill="#005480"
							d="M491.4,14.1h-48.7V0h62.7l0,0C505.5,7.8,499.2,14.1,491.4,14.1z M440.4,151.2L440.4,151.2h62.7v-14.1h-48.7
				C446.7,137.1,440.4,143.4,440.4,151.2z M20.4,120.5V30.8H5.1v89.7H20.4z M99.7,95.2h-0.3L55.6,30.8H40.5v89.7h15.3V56.9
				l43.6,63.5h15.5V30.8H99.7C99.7,30.8,99.7,95.2,99.7,95.2z M181.5,73c8.7-3.5,13.3-11.4,13.3-20.3c0-14.5-10.5-21.9-29.2-21.9
				h-31.2v89.7h35.5c19.2,0,29.4-9.9,29.4-24.6c0-12-7.1-20.1-17.8-22.7L181.5,73L181.5,73z M162.6,44.4c11,0,16.5,3.8,16.5,11.6
				c0,8-6.6,11.9-15,11.9h-14.5V44.4H162.6z M149.6,106.8V81h15.9c11.5,0,17.8,5.1,17.8,13.1c0,8.2-6.9,12.6-18.1,12.6H149.6z
				 M255,121.5c13,0,23.9-4.3,32.3-12.8c8.6-8.5,12.9-19.6,12.9-33.1s-4.3-24.6-12.9-33.1C278.9,34,268,29.8,255,29.8
				c-13.4,0-24.3,4.3-32.7,13c-8.5,8.6-12.6,19.6-12.6,32.8c0,13.3,4.2,24.3,12.6,33C230.7,117.2,241.6,121.5,255,121.5z M255,107.2
				c-8.7,0-15.8-3-21.4-9.1c-5.4-6.1-8.2-13.5-8.2-22.5c0-9,2.8-16.4,8.2-22.5c5.6-6.1,12.6-9.1,21.4-9.1c8.3,0,15.3,3,20.8,9
				c5.7,5.9,8.5,13.4,8.5,22.6c0,9.1-2.8,16.7-8.5,22.6C270.3,104.2,263.3,107.2,255,107.2z M374.9,95.2h-0.3l-43.8-64.4h-15.2v89.7
				h15.3V56.9l43.6,63.5h15.5V30.8h-15.2V95.2z M425.3,120.5V30.8H410v89.7H425.3z M493.7,75.5c-2.9-2-9.1-4.8-18.3-8.5l-7.5-2.9
				c-1.4-0.5-3-1.5-4.9-2.7c-3.5-2.1-4.4-4.3-4.4-7.5c0.1-6.6,5.4-10.5,12.6-10.5c6.1,0,12.8,2.9,26,13.1V38.3
				c-10.4-6.5-18-8.7-26-8.6c-7.8,0-14.5,2.4-20.1,7.3c-5.6,4.8-8.3,10.7-8.3,17.7c0,8,2.9,13.9,8.6,18.1c5.9,4.2,10.4,6.3,18.6,9.2
				c6.4,2.3,11,4.3,13.5,6.2s3.8,4.5,3.8,8.2c0,6.7-5.3,10.9-14.9,10.9c-7.5,0-15.8-3.7-26.7-14.8v19.7c9.4,6.8,17.6,9.3,26.6,9.3
				c8.1,0,15.3-2.3,21.5-6.9c6.3-4.7,9.5-11.4,9.5-20C503.1,86.3,500,79.9,493.7,75.5z"
						/>
					</g>
					<g>
						<path
							className="st0"
							d="M647.7,96.7h-43.3l-9,23.8h-7.6l34.4-89.7h7.5l34.7,89.7h-7.7L647.7,96.7z M645.2,89.9l-19.1-50.5h-0.3
				l-18.9,50.5H645.2z"
						/>
						<path
							className="st0"
							d="M553.9,83.8c14-1.9,23.6-10.2,23.6-26.5c0-15.2-8.6-26.5-28.8-26.5h-31.6v6.7h31.3
				c13.4,0,21.5,6.6,21.5,19.8c0,11.4-6.4,20.1-23.6,20.1h-29.2v6.8h28.3l24.9,36.3h9L553.9,83.8z"
						/>
						<path
							className="st0"
							d="M689.3,37.5h-28v-6.7h63.7v6.7h-28.4v83h-7.2V37.5z"
						/>
						<path className="st0" d="M738.7,30.8h7.1v89.7h-7.1V30.8z" />
						<path
							className="st0"
							d="M838.2,30.8v89.7H831l-56.5-77.2h-0.3v77.2h-7.1V30.8h6.9l56.7,77.6h0.3V30.8H838.2z"
						/>
						<path
							className="st0"
							d="M898.3,121.3c-24.7,0-43.3-19.7-43.3-45.8c0-25.6,19.6-45.7,44.6-45.7c18.1,0,28.8,8.1,31.1,9.9l-4,5.6
				c-2.4-1.8-12.4-8.7-26.7-8.7c-21.5,0-37.1,16.3-37.1,38.7c0,22.3,15.6,39.1,36.3,39.1c17.6,0,28.6-13.3,28.7-13.4l0.1-0.2V84.8
				h-15.3v-6.6h22.5v41.6h-6.9v-6.1c0-1.9,0.1-3.8,0.1-3.8l0-0.7h-1.3l-0.2,0.2C927,109.5,916.7,121.3,898.3,121.3z"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}

export default LogoInbonis;
