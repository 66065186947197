import {StyleSheet/* , Font */} from "@react-pdf/renderer";
import Colors from "theme/Colors";
// import IBMregular from "assets/fonts/IBMPlexSans-Regular.ttf";
// import IBMsemibold from "assets/fonts/IBMPlexSans-SemiBold.ttf";

// Font.register({
// 	family: "IBM Plex Sans",
// 	fonts: [
// 		{src: IBMregular, fontStyle: "normal", fontWeight: 400},
// 		{src: IBMsemibold, fontWeight: 500},
// 	],
// });

export const TutorielStyles = StyleSheet.create({
	page: {
		flexDirection: "column",
		alignItems: "flex-start",
		fontFamily: "IBM Plex Sans",
	},
	logo: {
		flexDirection: "row",
		alignItems: "center",
	},
	pageTitle: {
		fontSize: 96,
		// fontWeight: 500,
		marginLeft: 160,
	},
	pageSubTitle: {
		fontSize: 50,
		color: Colors.textColor,
		marginTop: 50,
	},
	graphDescription: {
		fontSize: 36,
		color: Colors.textColor,
		marginTop: 100,
		marginLeft: 60,
	},
	leftBlock: {
		width: "65%",
		height: "100%",
		padding: 60,
		margin: 0,
	},
	graphBlock: {
		width: "100%",
		height: 1600,
		padding: 60,
		marginTop: 150,
	},
	graphTitle: {
		fontSize: 72,
		// fontWeight: 500,
	},
	graphContent: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		marginTop: 100,
	},
	rightBlock: {
		display: "flex",
		alignItems: "center",
		width: "35%",
		height: "100%",
		backgroundColor: Colors.lightGrey,
	},
	commentBlock: {
		display: "flex",
		flexDirection: "column",
		padding: 45,
		margin: 60,
		marginTop: "58%",
		backgroundColor: "white",
		borderRadius: 20,
	},
	commentBlockTitle: {
		fontSize: 60,
		// fontWeight: 500,
	},
	commentBlockText: {
		fontSize: 48,
		// fontWeight: 400,
		marginTop: 55,
	},
	footer: {
		padding: 60,
		position: "absolute",
		bottom: 0,
		right: 0,
		fontSize: 72,
		color: "#666",
	},
});

export const VerticalLineStyles = StyleSheet.create({
	companyResult: {
		paddingVertical: 10,
		paddingHorizontal: 25,
		marginBottom: 25,
		borderRadius: 50,
		color: "white",
		textAlign: "center",
		fontSize: 32,
	},
	verticalLine: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: 280,
		marginRight: 30,
	},
	verticalLineFirst: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: 250,
		marginLeft: 30,
	},
	verticalLineBetweenLeft: {
		height: 3,
		backgroundColor: Colors.lightGrey,
		width: 50,
		position: "relative",
	},
	verticalLineBetweenLeftText: {
		position: "absolute",
		right: 80,
		top: -20,
		fontSize: 26,
		color: Colors.textColor,
	},
	verticalLineBlock: {
		height: 160,
		width: 50,
		marginBottom: 15,
		borderRadius: 20,
		backgroundColor: Colors.lightGrey,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		fontSize: 28,
		color: "#FFF",
	},
	verticalLineLine: {
		height: 180,
		width: 50,
		left: 25,
		borderLeft: `2px solid ${Colors.lightGrey}`,
		display: "flex",
	},
	verticalLineBlockBetween: {
		height: 2,
		backgroundColor: Colors.lightGrey,
		width: 50,
		marginBottom: 15,
		position: "relative",
	},
	verticalLineBlockBetweenText: {
		position: "absolute",
		left: 70,
		top: -20,
		fontSize: 28,
		color: Colors.textColor,
	},
	verticalLineTitle: {
		textAlign: "center",
		fontSize: 28,
		paddingTop: 50,
	},
});
