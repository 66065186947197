import { ReactElement } from 'react';
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import CreatePDF from "./CreatePDF";

const PDF = ():ReactElement<any, any> => {
	return (
		<>
			<PDFViewer showToolbar={false} width={960} height={720}>
				<CreatePDF />
			</PDFViewer>
			<PDFDownloadLink document={<CreatePDF />} fileName="Diagnostic-Bpifrance.pdf">
				{({blob, url, loading, error}) =>
					loading ? "Loading document..." : "Download now!"
				}
			</PDFDownloadLink>
		</>
	);
};

export default PDF;
