import React from "react";
import Body from "components/layout/Body/Body";
import Button from "components/units/Button/Button";

// Styles
import {NotSubscribedWrapper} from "./NotSubscribed.styles";
import Colors from "theme/Colors";

// Content
import HomeImage from "assets/img/home.png";
import {Container} from "theme/Theme";

function NotSubscribed(): JSX.Element {
	return (
		<Body
			url="/not-subscribed"
			title="Not Subscribed - Bpifrance"
			headerRight={
				<button type="button" className="top-button">
					Inclus dans votre <span className="highlighted">abonnement</span> Bpifrance en
					ligne
				</button>
			}
		>
			<NotSubscribedWrapper>
				<Container>
					<div className="card">
						<div className="content">
							<div className="left">
								<button type="button" className="top-button">
									Inclus dans votre{" "}
									<span className="highlighted">abonnement</span> Bpifrance en
									ligne
								</button>
								<h1>Évaluez et faites progresser votre entreprise</h1>
								<img src={HomeImage} alt="Home" />
								<p>
									Cette application de diagnostic financier est proposée par
									Inbonis, partenaire de Bpifrance, afin de vous permettre une
									meilleure compréhension de vos résultats et indicateurs
									financiers.
								</p>
								<p>
									A l’issue de ce diagnostic, des recommandations, plans d’actions
									et des formations Bpifrance Universités vous sont partagés afin
									de vous aider à identifier des leviers.
								</p>
								<p>
									Les résultats obtenus à la suite de la simulation réalisée ne
									sont donnés qu’à titre informatif et n’engagent pas la société
									Bpifrance. Ils ne valent pas un audit personnalisé par un
									expert.
								</p>
								<div className="group">
									<input
										type="checkbox"
										name="accept_legal_conditions"
										id="accept_legal_conditions"
									/>
									<label htmlFor="accept_legal_conditions">
										Je reconnais avoir pris connaissance et accepte les{" "}
										<a href="/">Conditions générales d’utilisation</a>{" "}
										d&apos;Inbonis
									</label>
								</div>
								<Button
									type="button"
									className="bottom"
									text="Je démarre mon diagnostic financier"
									buttonStyles={{backgroundColor: Colors.yellow}}
								/>
							</div>
							<div className="right">
								<img src={HomeImage} alt="Home" />
							</div>
						</div>
					</div>
				</Container>
			</NotSubscribedWrapper>
		</Body>
	);
}

export default NotSubscribed;
