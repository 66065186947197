/* eslint-disable react/no-unescaped-entities */
import {ReactElement} from "react";
import {Page, Text, View, StyleSheet, Font} from "@react-pdf/renderer";
import IBMregular from "assets/fonts/IBMPlexSans-Regular.ttf";
import IBMsemibold from "assets/fonts/IBMPlexSans-SemiBold.ttf";
import LogoPDF from "assets/img/LogoPDF";

Font.register({
	family: "IBM Plex Sans",
	fonts: [
		{src: IBMregular, fontStyle: "normal", fontWeight: 400},
		{src: IBMsemibold, fontWeight: 500},
	],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		alignItems: "flex-start",
		fontFamily: "IBM Plex Sans",
	},
	logo: {
		margin: 60,
		flexDirection: "row",
		alignItems: "center",
	},
	pageTitle: {fontSize: 96, fontWeight: 500, marginLeft: 360},
	content: {
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		width: "100%",
		padding: 60,
		paddingRight: 180,
	},
	title: {
		fontSize: 52,
		fontWeight: 500,
	},
	paragraph: {
		fontSize: 45,
		marginTop: 50,
	},

	footer: {
		padding: 60,
		position: "absolute",
		bottom: 0,
		right: 0,
		fontSize: 72,
		color: "#666",
		// marginLeft: -110,
	},
});

// Create Document Component
const MentionsLegales = (): ReactElement => {
	return (
		<Page size={{width: 2400, height: 3200}} style={styles.page} orientation="landscape">
			<View style={styles.logo}>
				<LogoPDF />
				<Text style={styles.pageTitle}>Mentions légales</Text>
			</View>
			<View style={styles.content}>
				<Text style={styles.title}>
					Attributs et limitations du rapport de diagnostic coachPME®
				</Text>
				<Text style={styles.paragraph}>
					Un service commercialisé par INBONIS RATING France
				</Text>
				<Text style={styles.paragraph}>
					INBONIS RATING France est une société à responsabilité limitée au capital de
					10.000 euros et immatriculée au RCS de Paris sous le numéro 851 889 014. Son
					siège social est situé à Hôtel Bourienne, 58 Rue d'Hauteville, 75010, Paris.
					Contact : info@inbonis.es et/ou +34912772431.
				</Text>
				<Text style={styles.paragraph}>
					Le rapport de diagnostic est généré exclusivement à partir de coachPME®, une
					application en ligne développée par INBONIS. L’application coachPME® est
					disponible pour les entreprises françaises dont les comptes annuels sont publiés
					au Registre du Commerce ou fournis par l’utilisateur, et distribués par les
					fournisseurs de données d’INBONIS. Les informations contenues dans le rapport de
					diagnostic proviennent exclusivement des fournisseurs de données d’INBONIS et
					des informations fournies volontairement par l’utilisateur de l’application
					coachPME®. Les données utilisées par l’application proviennent de sources
					publiques fiables et mises-à-jour régulièrement. La véracité et l’actualité de
					ces données n’est pas garantie et ne peut engager la responsabilité de INBONIS.
					INBONIS effectue ses propres due diligences quant aux sources de données
					utilisées. Cependant, INBONIS n’est pas responsable de la véracité des
					informations publiquement collectées. De même manière, l’utilisateur de
					coachPME® s’engage à fournir des données précises, exactes et complètes. INBONIS
					n’est pas responsable du caractère erroné des données fournies pas un
					utilisateur. INBONIS conservera les données à caractère personnel conformément
					aux durées de prescription légales et réglementaires françaises et européennes.
				</Text>
				<Text style={styles.paragraph}>
					La qualification, les commentaires et les conseils personnalisés générés
					automatiquement à partir des informations collectées par l’application coachPME®
					sont de nature strictement informative. En aucun cas les contenus du rapport de
					diagnostic ne pourront servir de justificatif auprès des tiers. Tout partenaire
					s’engage à tenir INBONIS exempt de toute contribution aux dommages et intérêts,
					indemnités et frais de toute nature qui pourraient être prononcés à son encontre
					à la suite d’un préjudice résultant d’un manquement à ses obligations. Les
					parties qui utilisent le rapport de diagnostic sont tenues d’évaluer de manière
					indépendante le caractère approprié de tout investissement ou de toute
					transaction, ou de toute autre décision commerciale ou non-commerciale
					concernant l'entité évaluée dans le rapport de diagnostic.
				</Text>
				<Text style={styles.paragraph}>
					Le nom et le contenu de coachPME® et d’INBONIS (dont le texte, les logos, les
					images, les graphismes et tout autre signe distinctif et éléments composant
					l’application) est la propriété même d’INBONIS. Tout tiers ayant accès au
					rapport de diagnostic s’engage à respecter les règles de propriété
					intellectuelle françaises et internationales et s’engagent notamment à ne pas
					copier, reproduire, diffuser, modifier, altérer, communiquer ou transmettre les
					éléments de propriété de coachPME® sans avoir au préalable obtenu l’autorisation
					écrite d’INBONIS par les personnes dûment habilitées à sa représentation.
					L’Utilisateur et le Distributeur intermédiaire s’engagent par ailleurs à ne pas
					supprimer les clauses et mentions obligatoires prévues par INBONIS.
				</Text>
				<Text style={styles.paragraph}>
					Pour plus d’information, consultez les conditions générales d’utilisation de
					l’application coachPME®.
				</Text>
			</View>
			<View style={styles.footer}>
				<Text>4 / 4</Text>
			</View>
		</Page>
	);
};
export default MentionsLegales;
