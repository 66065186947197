import React from "react";
// import Img404 from "assets/images/404.svg";
import Button from "components/units/Button/Button";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

// Styles
import {Wrapper} from "./Error404.styles";

function Error404(): JSX.Element {
	const history = useHistory();
	const handleClick = () => {
		history.push("/");
	};

	return (
		<Wrapper>
			{/* <img src={Img404} alt="" /> */}
			<h1>Whoops… Page not found</h1>
			<p>It seems like this page doesn&apos;t exist.</p>
			<Button
				type="submit"
				text="Go home"
				loadingText="Loading..."
				className="orange-gradient"
				icon={faAngleLeft}
				iconPosition="left"
				onClick={handleClick}
			/>
		</Wrapper>
	);
}

export default Error404;
