import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faTimes,
	faCheckCircle,
	faExclamationTriangle,
	faInfoCircle,
	faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

// Styles
import {NotificationWrapper} from "./Notifications.styles";

const objectToString = (content: any) => {
	const keys = Object.keys(content);
	return (
		<ul>
			{keys.map((key, index) => {
				return (
					<li key={`${key + index}`}>
						{key !== "non_field_errors" && <strong>{key}: </strong>} {content[key]}
					</li>
				);
			})}
		</ul>
	);
};

const getType = (toCheck: number | string) => {
	if (typeof toCheck === "string") {
		return toCheck;
	} else {
		if (toCheck >= 400 && toCheck < 500) {
			return "warning";
		} else if (toCheck >= 500) {
			return "error";
		} else if (toCheck >= 200 && toCheck < 300) {
			return "success";
		} else {
			return "info";
		}
	}
};

const renderIcon = (type: number | string) => {
	switch (getType(type)) {
		case "info":
			return <FontAwesomeIcon className="icon" icon={faInfoCircle} color="teal" />;
		case "success":
			return <FontAwesomeIcon className="icon" icon={faCheckCircle} color="green" />;
		case "warning":
			return <FontAwesomeIcon className="icon" icon={faExclamationTriangle} color="yellow" />;
		case "error":
			return <FontAwesomeIcon className="icon" icon={faTimesCircle} color="red" />;
		default:
			break;
	}
};

type NotificationType = {
    id: number;
	autoClose: boolean;
	type: number | string;
	onDismissClick: () => void;
	title: string;
	text: string;
};
const Notification = ({autoClose, type, onDismissClick, title, text}: NotificationType) => {
	useEffect(() => {
		if (autoClose) {
			const STO = window.setTimeout(() => {
				onDismissClick();
				window.clearTimeout(STO);
			}, 8000);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<NotificationWrapper>
			<div className="content">{renderIcon(type)}</div>
			<div className="content">
				<p className="notification-title">{title}</p>
				<div className="notification-text">
					{typeof text === "object" ? objectToString(text) : text}
				</div>
			</div>
			<FontAwesomeIcon
				icon={faTimes}
				onClick={onDismissClick}
				className="notification-dismiss"
			/>
		</NotificationWrapper>
	);
};

export default React.memo(Notification);
