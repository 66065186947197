import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Button from "components/units/Button/Button";
import {ReactElement} from "react";
import {useAppDispatch} from "store/storeHooks";
import {showGlossaire} from "store/user.slice";
import Colors from "theme/Colors";

function ConsultezNotreGlossaire(): ReactElement {
	const dispatch = useAppDispatch();
	return (
		<Button
			icon={faQuestionCircle}
			iconPosition="left"
			type="button"
			text="Consultez notre glossaire"
			className="consultez"
			buttonStyles={{
				border: "none",
				backgroundColor: Colors.buttonClear,
				padding: "0.5rem 1rem",
				margin: " 2rem 0",
			}}
			textStyles={{color: Colors.mainColor}}
			onClick={() => dispatch(showGlossaire())}
		/>
	);
}

export default ConsultezNotreGlossaire;
