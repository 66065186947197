import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ReactElement} from "react";
import {ConseilI} from "store/enterprise.slice";
import styled from "styled-components";

const ConseilsViewStyled = styled.div``;

interface ConseilsViewI {
	conseilContent: Array<ConseilI> | undefined;
	setViewType: (conseilView: "conseil") => void;
	setSingleContent: (selectedContent: ConseilI) => void;
	setCurIdx: (curIdx: number) => void;
	setSlideAction: () => void;
}

function ConseilsView({conseilContent, setViewType, setCurIdx, setSingleContent, setSlideAction}: ConseilsViewI): ReactElement {
	const handleClick = (idx: number, el: ConseilI) => {
		setCurIdx(idx);
		setSingleContent(el);
		setViewType("conseil");
		setSlideAction();
	};
	return (
		<ConseilsViewStyled className="conseils-container">
			<div className="conseils">

				{conseilContent?.map((el, idx) => (
					<div
						key={idx}
						className="conseil"
						onClick={() => handleClick(idx, el)}
						tabIndex={0}
						onKeyPress={(e) => e.keyCode === 13 && handleClick(idx, el)}
						role="button"
					>
						<div className="content">
							<div className="title">Conseil №{idx + 1}</div>
							<div className="desc">{el.conseil[0]}</div>
							<div className="footer">
								{el.plan_action ? (
									<div className="plan">
										Plan d’action
										<div className="number">
											{el.plan_action.length}
										</div>
									</div>
								) : (
									<></>
								)}
								<div className="formations">
									Formations
									<div className="number">
										{el.formations_bpifrance_universite.length}
									</div>
								</div>
							</div>
						</div>
						<div className="arrow">
							<FontAwesomeIcon icon={faAngleRight} />
						</div>
					</div>
				))}
			</div>

		</ConseilsViewStyled>
	);
}

export default ConseilsView;
