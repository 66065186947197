import {configureStore} from "@reduxjs/toolkit";
import {createLogger} from "redux-logger";
import notificationsReducer from "./notifications.slice";
import userReducer from "./user.slice";
import enterpriseReducer from "./enterprise.slice";
import questionnaireSlice from "./questionnaire.slice";

const logger = createLogger({
	collapsed: true,
	diff: true,
});

const store = configureStore({
	reducer: {
		notifications: notificationsReducer,
		user: userReducer,
		questionnaire: questionnaireSlice,
		enterprise: enterpriseReducer,
	},
	middleware: (getDefaultMiddleware) => {
		if (process.env.NODE_ENV === "development") {
			return getDefaultMiddleware({serializableCheck: false}).concat(logger);
		}
		return getDefaultMiddleware({serializableCheck: false});
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
