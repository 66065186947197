import styled from "styled-components";
import {device} from "theme/GlobalStyles";

export const FooterWrapper = styled.div`
	display: flex;
	width: 100%;
	padding-top: 2rem;
	margin-top: 3rem;
	margin-bottom: 3rem;
	align-items: flex-start;
	justify-content: space-between;
	font-size: 14px;

	${device.laptop} {
		font-size: 16px;
	}

	.container {
		justify-content: space-between;
		flex-direction: column-reverse;
		align-items: center;

		${device.laptop} {
			flex-direction: row;
		}
	}

	.legal {
		align-items: center;
		display: flex;
		flex-direction: column;

		${device.laptop} {
			flex-direction: row;
		}

		span {
			color: #071a39;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			margin-bottom: 1.5rem;

			${device.laptop} {
				margin-bottom: 0;
			}

			li {
				display: inline-block;
				margin-right: 1.5rem;

				&:last-child {
					margin-right: 0;
				}

				${device.laptop} {
					&:last-child {
						margin-right: 1.5rem;
					}
				}

				a {
					color: #071a39;
					text-decoration: none;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	.footer-logo {
		align-items: center;
		display: flex;
		margin-bottom: 2.5rem;

		${device.laptop} {
			margin-bottom: 0;
		}

		svg {
			margin-left: 1rem;
			width: 150px;

			${device.laptop} {
				margin-bottom: 0;
			}
		}
	}
`;
