const content = [
	{
		title: "Agence de notation de cr&eacute;dit",
		description:
			"Organisme ind&eacute;pendant agr&eacute;&eacute; par l&rsquo;Autorit&eacute; Europ&eacute;enne des March&eacute;s Financiers (ESMA) charg&eacute; d&rsquo;&eacute;valuer la capacit&eacute; d&rsquo;une entreprise &agrave; faire face &agrave; ses engagements financiers.",
	},
	{
		title: "Augmentation de capital",
		description:
			"Op&eacute;ration par laquelle une soci&eacute;t&eacute; augmente son capital social (c&rsquo;est-&agrave;-dire renforce ses fonds propres), soit par &eacute;mission d&rsquo;actions nouvelles en num&eacute;raire soit en incorporant dans le capital social la partie des&nbsp;profits&nbsp;qui avait &eacute;t&eacute;&nbsp;mise en r&eacute;serve&nbsp;car n'ayant pas &eacute;t&eacute; distribu&eacute;e.&nbsp;",
	},
	{
		title: "BFR",
		description:
			"Le Besoin en Fonds de Roulement (BFR) repr&eacute;sente les besoins de financement &agrave; court terme d&rsquo;une entreprise r&eacute;sultant des d&eacute;calages des flux de tr&eacute;sorerie correspondant aux d&eacute;caissements et aux encaissements li&eacute;s &agrave; l&rsquo;activit&eacute;. Il est calcul&eacute; de la mani&egrave;re suivante&nbsp;: BFR = Stocks + Cr&eacute;ances (cr&eacute;ances clients et autres cr&eacute;ances) - Dettes (toutes les dettes non financi&egrave;res).",
	},
	{
		title: "Bussines Angel",
		description:
			"Un Business Angel est une personne physique qui d&eacute;cide de soutenir financi&egrave;rement une entreprise dont le projet lui para&icirc;t &ecirc;tre innovant. En plus d'un capital financier, cet investisseur met &agrave; la disposition du chef d'entreprise son exp&eacute;rience et l'ensemble de ses comp&eacute;tences dans le domaine entrepreneurial. Il intervient &agrave; un stade pr&eacute;coce de cr&eacute;ation ou en d&eacute;but d&rsquo;activit&eacute; (&agrave; &laquo;&nbsp;l'amor&ccedil;age&nbsp;&raquo;), soit la p&eacute;riode la plus risqu&eacute;e de l'investissement.",
	},
	{
		title: "Charges financi&egrave;res",
		description:
			"Elles correspondent au co&ucirc;t des ressources d'emprunt obtenus aupr&egrave;s des diff&eacute;rents cr&eacute;anciers bancaires et financiers. Le montant de ces charges financi&egrave;res varie selon le niveau d'endettement d'une entreprise et des taux d'int&eacute;r&ecirc;t.",
	},
	{
		title: "Co&ucirc;ts fixes",
		description:
			"Il s'agit de l'ensemble des co&ucirc;ts qui &eacute;voluent ind&eacute;pendamment du niveau d'activit&eacute; de l'entreprise mais que l'entreprise doit payer pour son bon fonctionnement (ex : loyer, co&ucirc;ts administratifs, &eacute;lectricit&eacute;, etc.).",
	},
	{
		title: "Crowfunding",
		description:
			"Le crowdfunding est un terme anglais pour d&eacute;signer un &laquo; financement participatif &raquo;. Cela signifie qu&rsquo;un grand nombre de personnes sont amen&eacute;es &agrave; participer &agrave; l&rsquo;&eacute;laboration, en termes &eacute;conomiques et financiers, d&rsquo;un projet. C&rsquo;est un &eacute;change de fonds entre individus en dehors des circuits financiers institutionnels, afin de financer un projet via une plateforme en ligne.",
	},
	{
		title: "Dividendes",
		description:
			"Un&nbsp;dividende&nbsp;est un versement d'une&nbsp;entreprise&nbsp;&agrave; ses&nbsp;actionnaires. Il correspond &agrave; la part des&nbsp;b&eacute;n&eacute;fices&nbsp;engendr&eacute;s par une entreprise et se r&eacute;parti entre les actionnaires &agrave; hauteur du nombre d'actions&nbsp;poss&eacute;d&eacute;es par chacun d'entre eux. Ce sont les actionnaires, r&eacute;unis en&nbsp;assembl&eacute;e g&eacute;n&eacute;rale, qui d&eacute;cident du versement si l'entreprise d&eacute;tient une tr&eacute;sorerie suffisante. Ils sont habituellement pay&eacute;s en num&eacute;raire mais ils peuvent &eacute;galement &ecirc;tre &eacute;mis en parts ou en actions.",
	},
	{
		title: "EBE",
		description:
			"L&rsquo;Exc&eacute;dent Brut d&rsquo;Exploitation (EBE) est un solde interm&eacute;diaire de gestion, qui permet de visualiser la rentabilit&eacute; d&eacute;gag&eacute;e par l&rsquo;activit&eacute; principale de l&rsquo;entreprise. En effet, il ne prend pas en compte les produits et charges exceptionnels, ni les&nbsp;amortissements&nbsp;ou le r&eacute;sultat financier. EBE&nbsp;= Chiffre d&rsquo;affaires &ndash; achat de marchandises et de mati&egrave;res premi&egrave;res &ndash; charges externes &ndash; imp&ocirc;ts et autres taxes &ndash; salaires et charges sociales. Si ce ratio financier ressort n&eacute;gatif, cela signifie que le CA de l&rsquo;entreprise ne couvre pas ses charges et ainsi que l&rsquo;activit&eacute; &eacute;conomique de l&rsquo;entreprise n&rsquo;est pas rentable.",
	},
	{
		title: "Factoring (Affacturage)",
		description:
			"C'est une solution qui consiste &agrave; c&eacute;der une cr&eacute;ance &agrave; un &eacute;tablissement financier appel&eacute; le factor. Cela permet de financer la tr&eacute;sorerie des entreprises ou &eacute;ventuellement de se prot&eacute;ger contre les retards de paiement.",
	},
	{
		title: "Flux de tr&eacute;sorerie",
		description:
			"&Eacute;galement appel&eacute; &laquo; Cash-Flow &raquo;, ils d&eacute;signent les mouvements de liquidit&eacute;s entrants ou sortants, que conna&icirc;t une entreprise au cours d'une p&eacute;riode sp&eacute;cifique.",
	},
	{
		title: "Fonds d&rsquo;investissement",
		description:
			"Il s&rsquo;agit d&rsquo;une&nbsp;soci&eacute;t&eacute;&nbsp;financi&egrave;re dont l'objectif consiste &agrave; investir dans des soci&eacute;t&eacute;s s&eacute;lectionn&eacute;es pour leurs opportunit&eacute;s d'&eacute;volution. Les fonds d'investissement ou private equity se divisent en plusieurs cat&eacute;gories&nbsp;: fonds de capital-risque, fonds de capital d&eacute;veloppement, fonds&nbsp;LBO&nbsp;(leveraged buy-out) ... Toutes ces sp&eacute;cificit&eacute;s correspondent &agrave; des strat&eacute;gies d'investissements diff&eacute;rentes bas&eacute;es sur la p&eacute;riode de maturit&eacute; des entreprises cibles.",
	},
	{
		title: "Fonds propres",
		description:
			"Les fonds propres, sont les capitaux dont dispose l'entreprise. Ils ont &eacute;t&eacute; soit apport&eacute;s par les actionnaires, soit acquis par les profits g&eacute;n&eacute;r&eacute;s par l&rsquo;entreprise. Le r&ocirc;le des fonds propres est notamment de&nbsp;financer une partie des investissements. Plus une entreprise poss&egrave;de de fonds propres, plus elle est r&eacute;sistante en p&eacute;riode de crise.",
	},
	{
		title: "FR",
		description:
			"Le Fonds de Roulement (FR) repr&eacute;sente les ressources de moyen et long terme dont l'entreprise dispose pour payer ses charges d'exploitation, avant de recevoir les paiements de ses clients. Il se calcule comme suit&nbsp;: Fonds de roulement = (capitaux propres + capitaux emprunt&eacute;s &agrave; moyen et long terme) &ndash; actif immobilis&eacute;. Un FR n&eacute;gatif de fa&ccedil;on chronique signale une situation critique car cela veut dire qu'elle n'a pas suffisamment de ressources financi&egrave;res pour supporter la totalit&eacute; de ses&nbsp;investissements.",
	},
	{
		title: "Fournisseurs de rang 1",
		description:
			"Fournisseur qui livre directement l&rsquo;entreprise qui produit. C&rsquo;est un fournisseur direct, nomm&eacute; de rang 1.",
	},
	{
		title: "Ligne&nbsp;de&nbsp;cr&eacute;dit",
		description:
			"il s'agit d&rsquo;une autorisation (droit de tirages) donn&eacute;e par une banque &agrave; un emprunteur de tirer des fonds jusqu'&agrave; un plafond fix&eacute; et pendant une p&eacute;riode donn&eacute;e.",
	},
	{
		title: "Marge brute",
		description:
			"La marge brute est un indicateur qui permet de mesurer si l'activit&eacute; d'une entreprise est susceptible de d&eacute;gager un&nbsp;b&eacute;n&eacute;fice&nbsp;ou non.&nbsp; Marge brute = Prix de vente HT &ndash; Co&ucirc;t d&rsquo;achat HT.",
	},
	{
		title: "Plan de tr&eacute;sorerie",
		description:
			"Il s&rsquo;agit d&rsquo;un tableau&nbsp;r&eacute;capitulant les encaissements et les d&eacute;caissements pr&eacute;visionnels de&nbsp;l'entreprise.&nbsp;Le plan de tr&eacute;sorerie est d&eacute;taill&eacute; mois par mois&nbsp;pour offrir une visibilit&eacute; aux d&eacute;cideurs sur les flux de tr&eacute;sorerie pr&eacute;visibles &agrave; court terme. Ceci permet d&rsquo;estimer les d&eacute;penses et les recettes futures et de d&eacute;gager la situation finale en veillant &agrave; l&rsquo;&eacute;quilibrer.",
	},
	{
		title: "Remise en payant comptant ou paiement comptant",
		description:
			"Il s'agit de s&rsquo;acquitter d&rsquo;une facture, d&rsquo;un achat, sans avoir recours &agrave; un&nbsp;cr&eacute;dit&nbsp;et en une seule fois&nbsp;: c&rsquo;est le paiement imm&eacute;diat. Comme le vendeur n&rsquo;a pas besoin de proposer un cr&eacute;dit et qu&rsquo;il est certain d&rsquo;obtenir le r&egrave;glement, il peut consentir &agrave; l&rsquo;acheteur un escompte commercial (&agrave; savoir une r&eacute;duction sur le prix de vente).",
	},
	{
		title: "Rentabilit&eacute;",
		description:
			"La rentabilit&eacute; repr&eacute;sente le rapport entre les revenus d'une soci&eacute;t&eacute; et les sommes qu'elle a mobilis&eacute;es pour les obtenir. Il s&rsquo;agit d&rsquo;un &eacute;l&eacute;ment important pour &eacute;valuer la performance de l&rsquo;entreprise.",
	},
	{
		title: "Taux de marge brute d&rsquo;exploitation",
		description:
			"Le taux de marge se calcule en pourcentage en divisant la&nbsp;marge commerciale&nbsp;par le prix de revient.&nbsp;Taux de marge (%)&nbsp;= Marge brute HT / Co&ucirc;t d&rsquo;achat HT x 100",
	},
	{
		title: "Tr&eacute;sorerie",
		description:
			"la somme d&rsquo;argent disponible en banque &agrave; un moment donn&eacute;.",
	},
];

export default content;
