import {useState, useEffect} from "react";
import Body from "components/layout/Body/Body";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp, faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import OnboardingMenu from "components/composed/OnboardingMenu/OnboardingMenu";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import SelectedEnterprise from "components/composed/SelectedEnterprise/SelectedEnterprise";
import Button from "components/units/Button/Button";

// Styles
import {DoneesFinancieresStyled} from "./DonneesFinancieres.styles";
import {Container} from "theme/Theme";

// Redux
import {showRetourModal} from "store/user.slice";
import {useHistory} from "react-router";
import {ENTERPRISE_STATUS} from "store/enterprise.slice";
import {postDiagnosisAnon} from "store/enterprise.actions";
import {useAppDispatch, useAppSelector} from "store/storeHooks";

// STEPS
const STEPS = {
	FIRST: "FIRST",
	SECOND: "SECOND",
	THIRD: "THIRD",
	FOURTH: "FOURTH",
};

function DonneesFinancieres(): JSX.Element {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const salaries = useAppSelector((state) => state.enterprise.salaries);
	const bilan = useAppSelector((state) => state.enterprise.bilan);
	const resultat = useAppSelector((state) => state.enterprise.resultat);
	const diagnosisAnonStatus = useAppSelector((state) => state.enterprise.diagnosisAnonStatus);
	const postDiagnosisAnonStatus = useAppSelector(
		(state) => state.enterprise.postDiagnosisAnonStatus
	);

	useEffect(() => {
		if (diagnosisAnonStatus === ENTERPRISE_STATUS.FAILURE && currentStep === STEPS.FIRST) {
			setCurrentStep(STEPS.SECOND);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [diagnosisAnonStatus]);

	useEffect(() => {
		if (currentStep === STEPS.FOURTH && postDiagnosisAnonStatus === ENTERPRISE_STATUS.SUCCESS) {
			history.push("/quick-view");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [postDiagnosisAnonStatus]);

	// Etape menu
	const [showMenu, setShowMenu] = useState(false);
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	// Selected option
	const [selectedOption, setSelectedOption] = useState("");

	// Current step
	const [currentStep, setCurrentStep] = useState(STEPS.FIRST);
	const handleStep = () => {
		if (currentStep === STEPS.FIRST && selectedOption !== "") {
			if (selectedOption.toLowerCase() === "non") {
				history.push("/quick-view");
			} else {
				setCurrentStep(STEPS.SECOND);
			}
		}

		if (currentStep === STEPS.SECOND) {
			setCurrentStep(STEPS.THIRD);
		}

		if (currentStep === STEPS.THIRD) {
			setCurrentStep(STEPS.FOURTH);
		}

		if (currentStep === STEPS.FOURTH) {
			dispatch(postDiagnosisAnon());
		}
	};

	const handleDisabled = () => {
		if (currentStep === STEPS.FIRST && selectedOption !== "") {
			return false;
		}

		if (currentStep === STEPS.SECOND && salaries >= 0) {
			return false;
		}

		if (
			currentStep === STEPS.THIRD &&
			bilan.total_assets !== "" &&
			bilan.total_assets_old !== "" &&
			bilan.own_fund !== "" &&
			bilan.own_fund_old !== "" &&
			bilan.cash_and_other_liquid !== "" &&
			bilan.total_debt !== ""
		) {
			return false;
		}

		if (
			currentStep === STEPS.FOURTH &&
			resultat.revenues !== "" &&
			resultat.revenues_old !== "" &&
			resultat.fixed_assets_amortization !== "" &&
			resultat.fixed_assets_amortization_old !== "" &&
			resultat.tax_year_result !== "" &&
			resultat.tax_year_result_old !== "" &&
			resultat.personnel_expenses !== "" &&
			resultat.financial_expenses !== "" &&
			resultat.ebitda !== ""
		) {
			return false;
		}

		return true;
	};

	const handleReturn = () => {
		switch (currentStep) {
			case STEPS.FIRST:
				dispatch(showRetourModal());
				return;
			case STEPS.SECOND:
				setCurrentStep(STEPS.FIRST);
				break;
			case STEPS.THIRD:
				setCurrentStep(STEPS.SECOND);
				break;
			case STEPS.FOURTH:
				setCurrentStep(STEPS.THIRD);
				break;

			default:
				break;
		}
	};

	return (
		<Body url="/donnees-financieres" title="Données financières – Bpifrance en ligne">
			<DoneesFinancieresStyled>
				<Container>
					<button type="button" className="top-button" onClick={toggleMenu}>
						<FontAwesomeIcon
							className="icon"
							icon={showMenu ? faAngleUp : faAngleDown}
						/>
						<span>Étape 2</span>
					</button>
					<div className="content">
						<SelectedEnterprise />
						{showMenu ? <OnboardingMenu /> : null}
						<div className="padding">
							{currentStep === STEPS.FIRST ? (
								<Step1
									selectedOption={selectedOption}
									setSelectedOption={setSelectedOption}
								/>
							) : currentStep === STEPS.SECOND ? (
								<Step2 />
							) : currentStep === STEPS.THIRD ? (
								<Step3 />
							) : currentStep === STEPS.FOURTH ? (
								<Step4 />
							) : null}

							<div className="buttons">
								<Button
									text="Retour"
									icon={faArrowLeft}
									iconPosition="left"
									type="button"
									className="secondary"
									onClick={() => setTimeout(() => handleReturn(), 300)}
									iconStyles={{marginRight: "1rem"}}
									buttonStyles={{
										border: "none",
									}}
								/>
								<Button
									text="Suivant"
									icon={faArrowRight}
									iconPosition="right"
									type="button"
									className={`secondary ${handleDisabled() ? 'disabled' : ''}`}
									onClick={() => setTimeout(() => handleStep(), 300)}
									iconStyles={{marginLeft: "1rem"}}
									disabled={handleDisabled()}
								/>
							</div>
						</div>
					</div>
				</Container>
			</DoneesFinancieresStyled>
		</Body>
	);
}

export default DonneesFinancieres;
