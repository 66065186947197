import {ReactElement} from "react";
import {useHistory} from "react-router-dom";
import {ConseilCodes, indicateurAnalysesCategories, setCurrentConseils, setCurrentConseilColor, setCurrentConseilCategorie} from "store/enterprise.slice";
import {useAppDispatch} from "store/storeHooks";
import {setHasSeenConseils} from "store/user.slice";
import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const ConseilListItemStyled = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 325px;
	margin: 0.5rem;
	padding-right: 1rem;
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid ${Colors.lightGrey};

	&:hover {
		cursor: pointer;
		border: none;
		box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);

		.icon {
			color: ${Colors.darkGrey};
		}
	
	}

	h3 {
		font-size: 14px;
		margin: 0;
		margin-top: 0.9rem;

		${device.desktop} {
			font-size: 16px;
		}
	}

	p {
		margin-top: 0.2rem;
		font-size: 12px;
	}

	.dot-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 100%;
		margin-right: 5px;

		.dot {
			width: 15px;
			height: 15px;
			border-radius: 50%;
			background-color: transparent;

			&.success {
				margin: auto;
				background-color: ${Colors.success};
			}

			&.yellow {
				background-color: ${Colors.yellow};
			}

			&.orange {
				background-color: ${Colors.orange};
			}

			&.error {
				background-color: ${Colors.error};
			}
		}
	}

	.icon {
		position: absolute;
		right: 15px;
		width: 10px;
		color: ${Colors.secondaryColor};
	}


	.success,
	.fail {
		display: block;
		height: 100%;
		width: 5px;
		background-color: ${Colors.error};
		margin-right: 1rem;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		${device.desktop} {
			width: 10px;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}

	.success {
		background-color: ${Colors.success};
	}
`;

interface ConseilListItemI {
	name: string;
	className: string;
	amount: string;
	code: (keyof typeof ConseilCodes)[];
	color: string;
	categorie: keyof typeof indicateurAnalysesCategories;
}

function ConseilListItem({name, className, amount, code, color, categorie}: ConseilListItemI): ReactElement {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const gotToConseil = () => {
		history.push("/conseils");
		dispatch(setCurrentConseils(code));
		dispatch(setCurrentConseilColor(color));
		dispatch(setCurrentConseilCategorie(categorie));
		dispatch(setHasSeenConseils());
	};

	return (
		<ConseilListItemStyled onClick={gotToConseil}>
			<div className="dot-container">
				<div className={`dot ${color}`} />
			</div>
			<div>
				<h3>{name}</h3>
				<p>
					{parseInt(amount) > 0 && `${amount} ${parseInt(amount) === 1 ? 'conseil' : 'conseils'}`}
				</p>
			</div>
			<div className="icon">
				<FontAwesomeIcon icon={faSearch} />
			</div>
		</ConseilListItemStyled>
	);
}

export default ConseilListItem;
