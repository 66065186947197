import React, {ReactElement} from "react";
import {useAppSelector} from "store/storeHooks";
import {ResultGraphStyled} from "./ResultGraph.styles";
import VerticalBar from "./VerticalBar";
import VerticalDescriptionBar from "./VerticalDescriptionBar";
import {getFormattedData} from "utils/graphFn";

interface ResultGraphI {
	className?: string;
}

const ResultGraph = React.forwardRef(
	({className}: ResultGraphI, ref: React.ForwardedRef<HTMLDivElement>): ReactElement => {
		const sectorial = useAppSelector((state) => state.enterprise.diagnosisResponse?.sectorial);
		const formattedData = getFormattedData(sectorial);
		return (
			<>
				<ResultGraphStyled className={className} ref={ref}>
					{formattedData ? (
						<>
							<VerticalDescriptionBar/>
							<VerticalBar
								data={formattedData?.activity}
								croissance
								className="vertical-bar"
							/>
							<VerticalBar data={formattedData?.margin} className="vertical-bar" />
							<VerticalBar data={formattedData?.rentability} className="vertical-bar" />
							<VerticalBar data={formattedData?.liquidity} className="vertical-bar" />
							<VerticalBar data={formattedData?.solvency} className="vertical-bar" />
							<VerticalBar data={formattedData?.debt} className="vertical-bar" />
							<VerticalBar
								data={formattedData?.cost_effectiveness}
								croissance
								className="vertical-bar"
							/>
						</>
					) : null}
				</ResultGraphStyled>
			</>
		);
	}
);

ResultGraph.displayName = "ResultGraph";
export default ResultGraph;
