import {StyleSheet, Font} from "@react-pdf/renderer";
import Colors from "theme/Colors";
import IBMregular from "assets/fonts/IBMPlexSans-Regular.ttf";
import IBMsemibold from "assets/fonts/IBMPlexSans-SemiBold.ttf";

Font.register({
	family: "IBM Plex Sans",
	fonts: [
		{src: IBMregular, fontStyle: "normal", fontWeight: 400},
		{src: IBMsemibold, fontWeight: 500},
	],
});

export const TutorielStyles = StyleSheet.create({
	page: {
		flexDirection: "column",
		alignItems: "flex-start",
		fontFamily: "IBM Plex Sans",
	},
	logo: {
		margin: 60,
		flexDirection: "row",
		alignItems: "center",
	},
	pageTitle: {
		fontSize: 96,
		fontWeight: 500,
		marginLeft: 360,
	},
	pageSubTitle: {
		fontSize: 56,
		color: Colors.textColor,
		marginTop: 50,
		marginLeft: 70,
	},
	graphDesciption: {
		fontSize: 36,
		color: Colors.textColor,
		marginTop: 110,
		marginLeft: 70,
	},
	content: {
		padding: 50,
		paddingRight: 120,
		height: 1200,
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	graphBlock: {
		width: "100%",
		height: 1800,
	},

	graphContent: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "space-between",
		marginTop: 150,
	},
	footer: {
		padding: 60,
		position: "absolute",
		bottom: 0,
		right: 0,
		fontSize: 72,
		color: "#666",
	},
});

export const VerticalLineStyles = StyleSheet.create({
	companyResult: {
		paddingVertical: 10,
		paddingHorizontal: 25,
		marginBottom: 25,
		borderRadius: 50,
		color: "white",
		textAlign: "center",
		fontSize: 32,
	},
	verticalLine: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: 320,
		marginRight: 50,
	},
	verticalLineFirst: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: 250,
		marginLeft: 30,
	},
	verticalLineBetweenLeft: {
		height: 3,
		backgroundColor: Colors.lightGrey,
		width: 60,
		position: "relative",
	},
	verticalLineBetweenLeftText: {
		position: "absolute",
		right: 85,
		top: -20,
		fontSize: 32,
		color: Colors.textColor,
	},
	verticalLineBlock: {
		height: 200,
		width: 60,
		marginBottom: 25,
		borderRadius: 25,
		backgroundColor: Colors.lightGrey,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		fontSize: 28,
		color: "white",
		fontWeight: 500,
	},
	verticalLineLine: {
		height: 240,
		width: 60,
		left: 30,
		borderLeft: `2px solid ${Colors.lightGrey}`,
		display: "flex",
	},
	verticalLineBlockText: {},
	verticalLineBlockBetween: {
		height: 3,
		backgroundColor: Colors.lightGrey,
		width: 60,
		marginBottom: 25,
		position: "relative",
	},
	verticalLineBlockBetweenText: {
		position: "absolute",
		left: 80,
		top: -20,
		fontSize: 32,
		color: Colors.textColor,
	},
	verticalLineTitle: {
		textAlign: "center",
		fontSize: 32,
		paddingTop: 60,
	},
});
