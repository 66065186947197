import styled from "styled-components";
import {device} from "theme/GlobalStyles";

export const ResultGraphStyled = styled.div`
	display: flex;
	position: relative;
	align-items: flex-start;
	justify-content: space-between;
	margin: 1rem auto 0 !important;
	padding-bottom: 2rem;
	width: 100%;
	overflow-x: auto !important;

	> div {
		width: 100%;
	}

	.vertical-bar {
		min-width: 120px;
		align-items: center;

		${device.laptop} {
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 90%;
		}
	}

	${device.laptop} {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 90%;
	}
`;
