import React from "react";
import Body from "components/layout/Body/Body";

// Styles
import {Container} from "theme/Theme";
import {LegalStyled} from "./Legal.styles";

function CGU(): JSX.Element {
	return (
		<Body url="/cgu" title="Mentions légales – Bpifrance">
			<Container>
				<LegalStyled>
					<h1>
						Conditions Générales d’Utilisation de coachPME® et politique de
						confidentialité
					</h1>
					<p>
						Les présentes Conditions Générales d{"'"}Utilisation ont pour objet de
						définir les modalités de mise à disposition de l’application coachPME®,
						ci-après nommée « l’Application», un service commercialisé par INBONIS
						RATING France, ci-après nommé {"« "}INBONIS{" »"} et les conditions d{"'"}
						utilisation de l’Application par l{"'"}Utilisateur.
					</p>
					<h2>MENTIONS L&Eacute;GALES</h2>
					<p>
						INBONIS RATING France est une soci&eacute;t&eacute; &agrave;
						responsabilit&eacute; limit&eacute;e au capital de 10.000 euros et
						immatricul&eacute;e au RCS&nbsp;de Paris sous le num&eacute;ro 851 889 014.
						Son si&egrave;ge social est situ&eacute; &agrave; H&ocirc;tel Bourienne, 58
						Rue d{"'"}Hauteville, 75010, Paris. Contact&nbsp;: coach@inbonis.com et/ou
						06 59 27 85 94.
					</p>
					<h2>D&Eacute;FINITIONS</h2>
					<p>
						Application&nbsp;:&nbsp;l&rsquo;Application d&eacute;signe le service
						coachPME&reg; commercialis&eacute; par INBONIS. Utilisateur&nbsp;:&nbsp;l
						{"'"}Utilisateur est toute personne qui utilise l&rsquo;Application ou l
						{"'"}un des services propos&eacute;s par l&rsquo;Application.
					</p>
					<h2>MODALIT&Eacute;S DE L&rsquo;APPLICATION</h2>
					<p>
						CoachPME&reg; est une application en ligne d&eacute;velopp&eacute;e par
						INBONIS. Elle est mise &agrave; la disposition des Utilisateurs &agrave; 
						des fins de consultation d&rsquo;information, de documentation et 
						d&rsquo;utilisation des services propos&eacute;s. L&rsquo;application coachPME&reg; 
						est disponible pour les entreprises fran&ccedil;aises dont les comptes annuels 
						sont publi&eacute;s au Registre du Commerce ou fournis par l&rsquo;Utilisateur, et
						distribu&eacute;s par les fournisseurs de donn&eacute;es d&rsquo;INBONIS.
						L&rsquo;Application est disponible aux formats mobile, tablette et desktop.
					</p>
					<p>
						Le service propos&eacute; permet aux Utilisateurs
						d&rsquo;obtenir un acc&egrave;s &agrave; l&rsquo;Application, un diagnostic
						de leur situation financi&egrave;re, une comparaison avec leur secteur et
						des conseils personnalis&eacute;s pour am&eacute;liorer leur gestion de
						fa&ccedil;on automatique et imm&eacute;diate.
					</p>
					<p>
						L&rsquo;utilisation du service est gratuite pour les Utilisateurs.
					</p>
					<p>
						L&rsquo;utilisation et la mise &agrave; disposition de coachPME&reg; fait
						l&rsquo;objet d&rsquo;un contrat de licence entre le Distributeur
						interm&eacute;diaire et INBONIS.
					</p>
					<h2>PR&Eacute;SENTATION DES SERVICES</h2>
					<p>
						L&rsquo;Utilisateur acc&egrave;de &agrave; l&rsquo;Application via un site
						internet. Les donn&eacute;es
						recueillies par l&rsquo;Application sont les suivantes&nbsp;: le
						num&eacute;ro de Siren, la Raison Sociale, l&rsquo;ann&eacute;e de cr&eacute;ation
						et le code postal de l&rsquo;entreprise, les donn&eacute;es financières publiques disponibles 
						sur l&rsquo;entreprise ou les donn&eacute;es financi&egrave;res renseign&eacute;es de l&rsquo;entreprise par 
						l&rsquo;Utilisateur. L&rsquo;ensemble de
						ces donn&eacute;es ont objectif d&rsquo;initier le diagnostic
						propos&eacute; par l&rsquo;Application. Afin de poursuivre le diagnostic,
						l&rsquo;Utilisateur doit alors r&eacute;pondre &agrave; des questions
						&laquo;&nbsp;ferm&eacute;es&nbsp;&raquo; relatives &agrave; son entreprise pour
						obtenir un diagnostic complet. Les questions varient en fonction des
						informations que l&rsquo;Application analyse dans les diff&eacute;rentes
						sources de donn&eacute;es. En fin de diagnostic, l&rsquo;Utilisateur obtient
						une analyse personalis&eacute;e sur plusierus variables analys&eacute;es et accède &agrave; des conseils, 
						plans d’action et formation pour chacune d&rsquo;entre elles. L&rsquo;Utilisateur peut ensuite
						t&eacute;l&eacute;charger son diagnostic personnalis&eacute; en format .pdf.{" "}
					</p>
					<p>
						L&rsquo;Application propose un glossaire aux Utilisateurs &agrave; des fins
						purement informatives. Les conseils, plan d&rsquo;action et formations
						personnalis&eacute;s g&eacute;n&eacute;r&eacute;s automatiquement &agrave;
						partir des informations collect&eacute;es par l&rsquo;Application sont de
						nature strictement informative. En aucun cas les contenus du rapport de
						diagnostic ne pourront servir de justificatif aupr&egrave;s des tiers. Les
						parties qui utilisent le rapport de diagnostic sont tenues
						d&rsquo;&eacute;valuer de mani&egrave;re ind&eacute;pendante le
						caract&egrave;re appropri&eacute; de tout investissement ou de toute
						transaction, ou de toute autre d&eacute;cision commerciale ou
						non-commerciale concernant l{"'"}
						entit&eacute; &eacute;valu&eacute;e dans le rapport de diagnostic.
					</p>

					<h2>COMPARAISON SECTORIELLE</h2>
					<p>
						Afin de fournir une analyse de la situation de l&rsquo;Utilisateur
						comparativement &agrave; son secteur, l&rsquo;Application coachPME&reg;
						utilise quatre sources d&rsquo;information&nbsp;: la Banque de France,
						l&rsquo;INSEE, l&rsquo;INPI et les donn&eacute;es propres &agrave; INBONIS.
						Les donn&eacute;es utilis&eacute;es par l&rsquo;Application proviennent de
						sources publiques fiables et mises-&agrave;-jour
						r&eacute;guli&egrave;rement. La v&eacute;racit&eacute; et
						l&rsquo;actualit&eacute; de ces donn&eacute;es ne sont pas garanties et
						ne peut engager la responsabilit&eacute; de INBONIS.
					</p>
					<h2>MODIFICATION&nbsp;/ &Eacute;VOLUTION / MISE-A-JOUR DE COACHPME</h2>
					<p>
						L&rsquo;Application peut &ecirc;tre interrompue ou suspendue. INBONIS se
						r&eacute;serve la libert&eacute; de faire &eacute;voluer, modifier ou
						suspendre sans pr&eacute;avis l&rsquo;Application pour des raisons de
						maintenance ou tout autre motif jug&eacute; utile pour son bon
						fonctionnement. L&rsquo;indisponibilit&eacute; anticip&eacute;e pour des raison de maintenance
						de l&rsquo;Application ne peut donner lieu &agrave; une indemnit&eacute;.
					</p>
					<h2>PROPRI&Eacute;T&Eacute; INTELLECTUELLE</h2>
					<p>
						Le nom et le contenu de coachPME&reg; et d&rsquo;INBONIS (dont le texte, les
						logos, les images, les graphismes et tout autre signe distinctif et
						&eacute;l&eacute;ments composant l&rsquo;application) sont la
						propri&eacute;t&eacute; m&ecirc;me d&rsquo;INBONIS. L&rsquo;Utilisateur s&rsquo;engage 
						&agrave; respecter les
						r&egrave;gles de propri&eacute;t&eacute; intellectuelle fran&ccedil;aises et
						internationales et s&rsquo;engagent notamment &agrave; ne pas copier,
						reproduire, diffuser, modifier, alt&eacute;rer, communiquer ou transmettre
						les &eacute;l&eacute;ments de propri&eacute;t&eacute; de coachPME&reg; sans
						avoir au pr&eacute;alable obtenu l&rsquo;autorisation &eacute;crite
						d&rsquo;INBONIS par les personnes d&ucirc;ment habilit&eacute;es &agrave; sa
						repr&eacute;sentation. L&rsquo;Utilisateur s&rsquo;engage par ailleurs &agrave; ne pas supprimer
						les clauses et mentions obligatoires pr&eacute;vues par INBONIS.
					</p>
					<h2>RESPONSABILIT&Eacute;S</h2>
					<p>
						INBONIS effectue ses propres due diligences quant aux sources de
						donn&eacute;es utilis&eacute;es. Cependant, INBONIS n&rsquo;est pas
						responsable de la v&eacute;racit&eacute; des informations publiquement
						collect&eacute;es. De m&ecirc;me mani&egrave;re, l&rsquo;Utilisateur
						s&rsquo;engage &agrave; fournir des donn&eacute;es pr&eacute;cises, exactes
						et compl&egrave;tes. INBONIS n&rsquo;est pas responsable du caract&egrave;re
						erron&eacute; des donn&eacute;es fournies par un Utilisateur.
					</p>
					<p>
						En aucun cas les contenus de l&rsquo;Application ne pourront servir de
						justificatif aupr&egrave;s des tiers.
					</p>
					<p>
						INBONIS s{"'"}engage &agrave; mettre en &oelig;uvre tous les moyens dont
						elle dispose pour assurer l{"'"}acc&egrave;s des Utilisateurs &agrave; l&rsquo;application. 
						INBONIS utilise des moyens afin de garantir la s&eacute;curit&eacute; et la
						confidentialit&eacute; de l&rsquo;Application mais ne garantit pas que ses
						services soient exempts de bugs ou autres malfa&ccedil;ons. INBONIS ne
						pourra &ecirc;tre tenu pour responsable des &eacute;ventuelles
						d&eacute;gradations de mat&eacute;riel, logiciel ou donn&eacute;es subies
						par les Utilisateurs du fait de l&rsquo;utilisation de l&rsquo;Application.
					</p>
					<p>
						Tout partenaire s&rsquo;engage &agrave; tenir INBONIS exempt de toute
						contribution aux dommages et int&eacute;r&ecirc;ts, indemnit&eacute;s et
						frais de toute nature qui pourraient &ecirc;tre prononc&eacute;s &agrave;
						son encontre &agrave; la suite d&rsquo;un pr&eacute;judice indirect
						r&eacute;sultant d&rsquo;un manquement &agrave; ses obligations.
						L&rsquo;Utilisateur reconnait
						qu&rsquo;en cas de non-respect des pr&eacute;sentes CGU, la
						responsabilit&eacute; de INBONIS et de toute autre soci&eacute;t&eacute; de
						son groupe ne saurait &ecirc;tre engag&eacute;e. Les Utilisateurs sont les 
						seuls responsables de leur mat&eacute;riel
						informatique, de leurs donn&eacute;es et logiciels ainsi que de la connexion
						au r&eacute;seau leur permettant d&rsquo;avoir acc&egrave;s &agrave; l&rsquo;Application. 
						Par ailleurs, les Utilisateurs sont seuls &agrave; s&rsquo;assurer de la
						compatibilit&eacute; de leur mat&eacute;riel, logiciel et connexion aux
						services offert par coachPME&reg;.
					</p>
					<p>
						L&rsquo;Utilisateur reconnait
						&ecirc;tre parfaitement inform&eacute;s de la possibilit&eacute; pour
						INBONIS de suspendre et/ou de modifier temporairement ou
						d&eacute;finitivement l&rsquo;Application, &agrave; tout moment, en raison
						de contraintes techniques ou r&eacute;glementaires ou encore dans tous les
						cas de violation des pr&eacute;sentes CGU. INBONIS ne pourra &ecirc;tre tenu
						pour responsable de l&rsquo;impossibilit&eacute; temporaire ou
						d&eacute;finitive de l&rsquo;Utilisateur d&rsquo;acc&eacute;der &agrave; tout ou partie du
						service. INBONIS
						ne garantit en aucun cas la p&eacute;rennit&eacute; ou les performances de
						l&rsquo;Application, au-del&agrave; de ce qui a &eacute;t&eacute; convenue dans l&rsquo;Application. 
						L&rsquo;Utilisateur s&rsquo;engage &agrave; utiliser l&rsquo;Application
						de mani&egrave;re diligente, conform&eacute;ment &agrave; la Loi et aux
						pr&eacute;sentes CGU. Il r&eacute;pondra des pr&eacute;judices directs
						subits par INBONIS du fait du non-respect de ses obligations. INBONIS se
						d&eacute;gage de toute responsabilit&eacute; en cas d{"'"}utilisation non
						conforme aux pr&eacute;sentes CGU et se r&eacute;serve le droit de mettre un
						terme &agrave; sa relation avec l&rsquo;Utilisateur ou si celui-ci contrevient aux pr&eacute;sentes CGU.
					</p>
					<h2>ACCEPTATION ET MODIFICATION DES CGU</h2>
					<p>
						L&rsquo;acc&egrave;s ainsi que l&rsquo;utilisation de l&rsquo;Application
						sont soumis au respect des pr&eacute;sentes CGU par l&rsquo;Utilisateur. Il 
						est pr&eacute;sum&eacute;s en
						avoir connaissance avant toute utilisation de l&rsquo;Application. 
						En cochant la mention &ldquo;je
						reconnais avoir pris connaissance et accepter les pr&eacute;sentes
						CGU&raquo; puis en cliquant sur &laquo;&nbsp;Je d&eacute;marre mon diagnostic financi&egrave;re&nbsp;&raquo; 
						l&rsquo;Utilisateur reconnait
						avoir pris connaissance des CGU et les
						accepter&nbsp;express&eacute;ment&nbsp;; les respecter &agrave; tout moment
						et en toutes circonstances &agrave; compter de la premi&egrave;re
						utilisation&nbsp;; avoir pleinement conscience de leurs obligations et des
						responsabilit&eacute;s qui en d&eacute;coulent. INBONIS se r&eacute;serve le
						droit de modifier et de mettre &agrave; jour &agrave; tout moment
						l&rsquo;acc&egrave;s &agrave; l&rsquo;Application ainsi que les
						pr&eacute;sentes CGU, ce que les Utilisateurs acceptent express&eacute;ment.&nbsp;INBONIS
						s&rsquo;efforcera d&rsquo;informer les Utilisateurs d&rsquo;un quelconque changement.
					</p>
					<h2>FORCE MAJEURE</h2>
					<p>
						La responsabilit&eacute; de INBONIS ne pourra &ecirc;tre engag&eacute;e en
						cas de force majeure ou de faits ind&eacute;pendants de sa
						volont&eacute;.&nbsp;
					</p>
					<h2>NON-RENONCIATION</h2>
					<p>
						Le fait pour INBONIS de ne pas se pr&eacute;valoir d&rsquo;un manquement
						d&rsquo;un Utilisateur &agrave;
						l&rsquo;une des stipulations des pr&eacute;sentes CGU ne saurait
						s&rsquo;interpr&eacute;ter comme une tol&eacute;rance ou une renonciation de
						sa part &agrave; se pr&eacute;valoir dans l&rsquo;avenir d&rsquo;un tel
						manquement.
					</p>
					<h2>DROIT APPLICABLE ET JURIDICTION COMP&Eacute;TENTE</h2>
					<p>
						Le droit applicable aux pr&eacute;sentes CGU est le droit
						fran&ccedil;ais.&nbsp; Les litiges qui pourraient survenir seront de la
						comp&eacute;tence exclusive du Tribunal de Commerce de Paris.
					</p>
					<h2>DONN&Eacute;ES PERSONNELLES</h2>
					<p>
						Les donn&eacute;es &agrave; caract&egrave;re personnel recueillies dans le
						cadre de l&rsquo;utilisation de coachPME&reg; sont obligatoires pour le
						traitement et la gestion des op&eacute;rations en cause et en particulier
						pour son traitement informatique effectu&eacute; sous la
						responsabilit&eacute; de INBONIS. INBONIS conservera les donn&eacute;es
						&agrave; caract&egrave;re personnel conform&eacute;ment aux dur&eacute;es de
						prescription l&eacute;gales et r&eacute;glementaires fran&ccedil;aises et
						europ&eacute;ennes.
					</p>
					<p>
						Conform&eacute;ment &agrave; la r&eacute;glementation applicable, notamment
						le R&egrave;glement europ&eacute;en 2016/679, dit&nbsp;r&egrave;glement
						g&eacute;n&eacute;ral sur la protection des donn&eacute;es&nbsp;(RGPD) et
						les dispositions nationales relatives &agrave; l{"'"}informatique, aux
						fichiers et libert&eacute;s, les personnes dont les donn&eacute;es &agrave;
						caract&egrave;re personnel sont collect&eacute;es pour le traitement des
						demandes b&eacute;n&eacute;ficient d&rsquo;un droit d&rsquo;acc&egrave;s, de
						rectification, de suppression et d&rsquo;opposition pour motif
						l&eacute;gitime de ces donn&eacute;es.
					</p>
					<p>
						Ces droits peuvent &ecirc;tre exerc&eacute;s par l&rsquo;envoi d&rsquo;un
						courrier, &agrave; INBONIS D&eacute;l&eacute;gu&eacute;e &agrave; la
						protection des donn&eacute;es, H&ocirc;tel Bourrienne, 58 Rue d{"'"}
						Hauteville, 75010, Paris.&nbsp; Enfin, les personnes disposent du droit
						d&rsquo;introduire une r&eacute;clamation aupr&egrave;s de la Commission
						Nationale de l&rsquo;Informatique et des Libert&eacute;s (CNIL).
					</p>
				</LegalStyled>
			</Container>
		</Body>
	);
}

export default CGU;
