import axios from "axios";
import IMAGE_1 from "assets/img/conseils/IMAGE_1.png";
import IMAGE_2 from "assets/img/conseils/IMAGE_2.png";
import IMAGE_3 from "assets/img/conseils/IMAGE_3.png";
import IMAGE_4 from "assets/img/conseils/IMAGE_4.png";
import IMAGE_5 from "assets/img/conseils/IMAGE_5.png";
import IMAGE_6 from "assets/img/conseils/IMAGE_6.png";
import IMAGE_7 from "assets/img/conseils/IMAGE_7.png";
import IMAGE_8 from "assets/img/conseils/IMAGE_8.png";
import IMAGE_9 from "assets/img/conseils/IMAGE_9.png";
import IMAGE_10 from "assets/img/conseils/IMAGE_10.png";
import IMAGE_11 from "assets/img/conseils/IMAGE_11.png";
import IMAGE_12 from "assets/img/conseils/IMAGE_12.png";
import IMAGE_13 from "assets/img/conseils/IMAGE_13.png";
import IMAGE_14 from "assets/img/conseils/IMAGE_14.png";
import IMAGE_15 from "assets/img/conseils/IMAGE_15.png";
import IMAGE_16 from "assets/img/conseils/IMAGE_16.png";
import IMAGE_17 from "assets/img/conseils/IMAGE_17.png";
import Colors from "theme/Colors";

export const setAuthorizationToken = (token: string | null): void => {
	if (token) {
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	} else {
		delete axios.defaults.headers.common["Authorization"];
	}
};

// @ts-ignore
export const debounce = <T extends (...args: unknown[]) => unknown>(
	callback: T,
	waitFor: number
): unknown => {
	let timeout = 0;
	return (...args: Parameters<T>): ReturnType<T> => {
		// @ts-ignore
		let result;
		clearTimeout(timeout);
		// @ts-ignore
		timeout = setTimeout(() => {
			result = callback(...args);
		}, waitFor);
		// @ts-ignore
		return result;
	};
};

export const hexToRGBA = (hex: string, alpha: number | string): string => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);

	if (alpha) {
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	} else {
		return `rgb(${r}, ${g}, ${b})`;
	}
};

export const imageURItoBlob = (dataURI: string): Blob => {
	const byteString = atob(dataURI.split(",")[1]);

	const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

	const arrayBuffer = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(arrayBuffer);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}

	const dataView = new DataView(arrayBuffer);
	return new Blob([dataView], {type: mimeString});
};

export const colorShade = (col: string, amt: number): string => {
	col = col.replace(/^#/, "");
	if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
	//   @ts-ignore
	let [r, g, b] = col.match(/.{2}/g);
	[r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

	r = Math.max(Math.min(255, r), 0).toString(16);
	g = Math.max(Math.min(255, g), 0).toString(16);
	b = Math.max(Math.min(255, b), 0).toString(16);

	const rr = (r.length < 2 ? "0" : "") + r;
	const gg = (g.length < 2 ? "0" : "") + g;
	const bb = (b.length < 2 ? "0" : "") + b;

	return `#${rr}${gg}${bb}`;
};

export const toNumber = (n: number | undefined): string | number => {
	if (n === 0) return "0";
	if (!n) return "";
	return Number(Number(n).toFixed(2));
};

export const addWhiteSpace = (str: string | number): string => {
	const string = str.toString();
	const parts = string.match(/.{1,3}/g);
	return parts ? parts.join(" ") : "";
};

export const capitalize = (s: string): string => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getClassName = (row: number, q: number, success: boolean): string => {
	if (row === q && success) return "success";
	if (row === q && !success) return "error";
	return "gray";
};

export const getBlockColor = (row: number, quartile: number, name: string): string => {
	if (row === 4 && quartile === 4 ) return Colors.success;
	if (row === 3 && quartile === 3 ) return Colors.lightYellow;
	if (row === 2 && quartile === 2 ) {
		if (name === 'Croissance' || name === 'Chiffre d’affaires par salarié') return Colors.success;
		return Colors.orange;
	}
	if (row === 1 && quartile === 1 ) {
		if (name === 'Croissance'|| name === 'Chiffre d’affaires par salarié') return Colors.orange;
		return Colors.error;
	}
	return Colors.lightGrey;
};

export const CONSEIL_IMAGES = {
	"IMAGE 1": IMAGE_1,
	"IMAGE 2": IMAGE_2,
	"IMAGE 3": IMAGE_3,
	"IMAGE 4": IMAGE_4,
	"IMAGE 5": IMAGE_5,
	"IMAGE 6": IMAGE_6,
	"IMAGE 7": IMAGE_7,
	"IMAGE 8": IMAGE_8,
	"IMAGE 9": IMAGE_9,
	"IMAGE 10": IMAGE_10,
	"IMAGE 11": IMAGE_11,
	"IMAGE 12": IMAGE_12,
	"IMAGE 13": IMAGE_13,
	"IMAGE 14": IMAGE_14,
	"IMAGE 15": IMAGE_15,
	"IMAGE 16": IMAGE_16,
	"IMAGE 17": IMAGE_17,
};

export const allAdviceCategories = [
	"business_confidence", 
	"business_quality",
	"debt",
	"environmental_impact",
	"financial",
	"managing_team"
]

export const indicateursAnalyses = (name: string): string => {
	if (name === "Eléments Financiers") return "financial"
	if (name === "Endettement") return "debt"
	if (name === "Fondamentaux de l'entreprise") return "business_quality"
	if (name === "Impact et Réputation") return "environmental_impact"
	if (name === "Gouvernance") return "managing_team"
	if (name === "Stabilité de l'entreprise") return "business_confidence"
	return "financial"
}

export const indicateursAnalysesVariableToTerm = (name: string): string => {
	if (name === "financial") return "Eléments Financiers"
	if (name === "debt") return "Endettement"
	if (name === "business_quality") return "Fondamentaux de l'entreprise"
	if (name === "environmental_impact") return "Impact et Réputation"
	if (name === "managing_team") return "Gouvernance"
	if (name === "business_confidence") return "Stabilité de l'entreprise"
	return ""
}

export const getColor = (value: number | string): string => {
	if (value === "") return ""

	if (value >= 8) return "success"
	if (value >= 6) return "yellow"
	if (value >= 3.1) return "orange"
	return "error"
}

export const getHexColor = (value: number | string): string => {
	if (value === "") return ""

	if (value >= 8) return Colors.success
	if (value >= 6) return Colors.lightYellow
	if (value >= 3.1) return Colors.orange
	return Colors.error
}

export const getVariableColor = (evaluation: Record<string, any>): string => {
  if (evaluation.max_score === 2) {
    if (evaluation.score === 1) return "orange"
    return "success"
  } else if (evaluation.max_score === 3) {
    if (evaluation.score === 1) return "error"
    if (evaluation.score === 2) return "orange"
    return "success"
  } else if (evaluation.max_score === 4) {
    if (evaluation.score === 1) return "error"
    if (evaluation.score === 2) return "orange"
    if (evaluation.score === 3) return "yellow"
    return "success"
  } else {
    return "grey"
  }
}

export const getVariableHexColor = (evaluation: Record<string, any>): string => {
  if (evaluation.max_score === 2) {
    if (evaluation.score === 1) return Colors.orange
    return Colors.success
  } else if (evaluation.max_score === 3) {
    if (evaluation.score === 1) return Colors.error
    if (evaluation.score === 2) return Colors.orange
    return Colors.success
  } else if (evaluation.max_score === 4) {
    if (evaluation.score === 1) return Colors.error
    if (evaluation.score === 2) return Colors.orange
    if (evaluation.score === 3) return Colors.lightYellow
    return Colors.success
  } else {
    return Colors.secondaryColor
  }
}

export const getResultButtonText = (color: string): string => {
	if (color === "success") return "Excellent"
	if (color === "yellow") return "Très bon"
	if (color === "orange") return "Assez bon"
	if (color === "error") return "Améliorable"
	return ""
}
