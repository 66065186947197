import {createAsyncThunk} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {RootState} from "./store";

export const getActivities = createAsyncThunk(
	"enterprise/getActivities",
	async (params, {rejectWithValue}) => {
		try {
			const response: {data: any} = await axios({
				method: "get",
				url: "/activities",
			});
			return response.data;
		} catch (error) {
			const err = error as AxiosError;
			return rejectWithValue(err);
		}
	}
);

export const getDiagnosisScores = createAsyncThunk(
	"enterprise/getDiagnosisScores",
	async (_,thunkAPI) => {
		const state = thunkAPI.getState() as RootState;
		const id = state.enterprise.diagnosisResponse?.id;
		const token = state.user.token;
		try {
			const response: {data: any} = await axios({
				method: "get",
				url: `/diagnosis/${id}`,
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			return response.data.response;
		} catch (error) {
			const err = error as AxiosError;
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const getEnterpriseAnon = createAsyncThunk(
	"enterprise/getEnterpriseAnon",
	async ({siren, cnae}: {siren: string; cnae: string}, thunkAPI) => {
		const state = thunkAPI.getState() as RootState;
		const token = state.user.token;
		try {
			const response: {data: any} = await axios({
				method: "post",
				url: "/diagnosis/anon",
				headers: {
					Authorization: `Bearer ${token}`
				},
				data: {
					siren,
					cnae,
				},
			});
			return response.data;
		} catch (error) {
			const err = error as AxiosError;
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export interface DiagnosisResponseI {
	balance_date: number;
	cnae: string;
	created_at: string;
	id: number;
	siren: string;
	state: string;
	updated_at: string;
	question_flags: {
		capital_increment: boolean;
		low_cash_flow: boolean;
		sales_decreased: boolean;
	};
	sectorial: {
		cnae: string;
		activity: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		cost_effectiveness: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		debt: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		gross_margin: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		liquidity: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		margin: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		refund_capacity: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		rentability: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		rentability_after_tax: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		sales: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		solvency: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
		working_capital: {
			quartile: number;
			sector: {
				companies: number;
				inverted: boolean;
				quartile_q1: number;
				quartile_q2: number;
				quartile_q3: number;
			};
			value: number;
		};
	};
}

interface DiagnosisAnonResponseI {
	data: {
		response: DiagnosisResponseI;
	};
}

export const postDiagnosisAnon = createAsyncThunk(
	"enterprise/postDiagnosisAnon",
	// eslint-disable-next-line no-empty-pattern
	async (_, ThunkAPI) => {
		const state = ThunkAPI.getState() as RootState;
		const enterprise = state.enterprise;
		const token = state.user.token;

		try {
			const response: DiagnosisAnonResponseI = await axios({
				method: "post",
				url: "/diagnosis/anon",
				headers: {
					Authorization: `Bearer ${token}`
				},
				data: {
					siren: enterprise.selectedEnterprise?.siren,
					cnae: enterprise.selectedActivity,
					input_data: {
						year: new Date().getFullYear().toString(),
						company_name: enterprise.selectedEnterprise?.name,
						postcode: enterprise.selectedEnterprise?.postcode,
						employees: enterprise.salaries,
						constitution_date: enterprise.selectedEnterprise?.constitution_date,
						...enterprise.bilan,
						...enterprise.resultat,
					},
				},
			});
			return response.data.response;
		} catch (error) {
			const err = error as AxiosError;
			return ThunkAPI.rejectWithValue(err);
		}
	}
);
