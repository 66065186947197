import React, {useEffect} from "react";
import {Route} from "react-router-dom";
import LoadingPage from "../LoadingPage/LoadingPage";
// import Start from 'screens/Start/Start';

// Redux
import {RootState} from "store/store";
import {verifyToken} from "store/user.actions";
import { storeToken } from "store/user.slice";
import {useDispatch, useSelector} from "react-redux";

interface ProtectedRouteI {
	component: React.FC;
	path: string;
	exact?: boolean;
	location?: any;
	computedMatch?: any;
}

function ProtectedRoute(props: ProtectedRouteI): JSX.Element {
	const hasToken = useSelector((state: RootState) => state.user.hasToken);
	const dispatch = useDispatch();
	const token = useSelector((state: RootState) => state.user.token);
	const queryToken = new URLSearchParams(props.location.search).get("token");
	
	useEffect(() => {
		if (queryToken && !hasToken) {
			dispatch(storeToken(queryToken));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryToken, hasToken]);

	useEffect(() => {
		if (token !== "" && !hasToken) {
			dispatch(verifyToken());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, hasToken]);

	return hasToken ? (
		<Route path={props.path} exact={props.exact} component={props.component} />
	) : (
		// <Start />
		<LoadingPage />
	);
}
export default ProtectedRoute;
