import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const BienvenueWrapper = styled.div`
	.content {
		width: 100%;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		margin: 0 auto;
		padding: 1rem;

		h1 {
			margin-top: 0;
		}

		${device.laptop} {
			margin-bottom: 3rem;
			padding: 0rem;
		}

		.subtitle {
			display: none;
			font-size: 20px;
			font-weight: 400;
			margin-bottom: 0;

			${device.laptop} {
				display: block;
				color: ${Colors.mainColor};
			}
		}

		.top-content {
			${device.laptop} {
				padding: 5rem;
				padding-bottom: 3rem;
			}
		}

		.bottom-content {
			${device.laptop} {
				display: flex;
				flex-direction: row-reverse;
				align-items: stretch;
				justify-content: space-between;
			}
		}

		.enterprises {
			display: block;
			margin-top: 2rem;
			overflow: scroll;
			overflow-x: auto;
			overflow-y: none;
			width: auto;
			white-space: nowrap;

			${device.laptop} {
				display: flex;
				align-items: center;
				flex-direction: column;
				overflow: hidden;
				overflow-x: hidden;
				overflow-y: auto;
				width: 50%;
				margin-right: 5%;
				height: 400px;
			}
		}

		.enterprise {
			margin-bottom: 1rem;
			width: 184px;
			height: 184px;
			border-radius: 8px;
			transition: all 0.15s ease;
			background-color: ${Colors.yellow};
			display: inline-block;
			margin-right: 1.5rem;
			position: relative;
			overflow: hidden;

			${device.laptop} {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				width: 100%;
				height: 100%;
				max-height: 130px;
				margin-right: 0;
				padding-top: 2.5rem;
				padding-bottom: 2.5rem;
				flex-shrink: 0;
			}

			&:last-child {
				margin-right: 0;

				${device.laptop} {
					margin-bottom: 0;
				}
			}

			&:hover {
				cursor: pointer;
			}

			.circle {
				width: 50px;
				height: 50px;
				background-color: ${Colors.lightYellow};
				border-radius: 25px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 8px;
				margin-top: 48px;
				margin-left: 16px;

				${device.laptop} {
					margin-left: 24px;
					margin-top: 0;
					margin-bottom: 0;
					align-self: center;
					justify-self: center;
				}

				svg {
					font-size: 1.25rem;
				}
			}

			.decorative-circle {
				position: absolute;
				width: 78px;
				height: 78px;
				background-color: ${Colors.lightYellow};
				border-radius: 39px;
				bottom: -39px;
				right: -39px;

				svg {
					font-size: 1.25rem;
				}
			}

			.data {
				font-size: 18px;
				font-weight: 600;
				display: flex;
				flex-direction: column;
				margin-left: 16px;

				${device.laptop} {
					font-size: 26px;
					margin-left: 30px;
				}

				.siren {
					color: ${Colors.textColor};
					font-weight: 500;
					font-size: 14px;
					margin-bottom: 0;
					margin-top: 0.5rem;
				}
			}

			&.add-new {
				background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23999' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e");
				border-radius: 6px;
				border: none;
				font-weight: 500;
				box-shadow: none;

				&:hover {
					background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23666' stroke-width='4' stroke-dasharray='12' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e");
				}
				.data {
					opacity: 0.5;
				}
			}

			.last-diagnostic {
				position: absolute;
				top: 6px;
				left: 6px;
				font-size: 11px;
				padding: 6px 8px;
				border-radius: 20px;
				background-color: #f6f6f6;
			}
		}

		.scroll {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 1.5rem;

			${device.laptop} {
				display: none;
			}

			.circle {
				border: 1px solid;
				border-radius: 50%;
				width: 42px;
				height: 42px;
				display: flex;
				align-items: center;
				justify-content: center;

				&:last-child {
					margin-left: 2rem;
				}

				svg {
					font-size: 22px;
				}
			}
		}

		.checklist {
			margin-top: 2rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			${device.laptop} {
				padding: 3rem;
				width: 40%;
				background-color: #f5f7fb;
			}

			div {
				display: flex;
				align-items: center;
				margin-top: 1rem;

				${device.laptop} {
					align-items: flex-start;
					margin-top: 1.5rem;
				}

				span {
					font-size: 14px;
					color: ${Colors.textColor};
					font-weight: 400;
					margin-left: 0.5rem;

					${device.laptop} {
						font-size: 18px;
						margin-left: 0.75rem;
						color: ${Colors.mainColor};
					}
				}
			}

			svg {
				color: ${Colors.textColor};
				margin-right: 5px;

				${device.laptop} {
					margin-top: 5px;
				}
			}
		}
	}
`;
