import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const ConseilsStyled = styled.div`
	.semi-transparent-background {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: ${Colors.backgroundGrey};
		opacity: 0;
		z-index: 10;
	}

	.slide-in {		
		animation: fadeIn 200ms ease-in forwards;

		@keyframes fadeIn{
			100% {
				opacity: 0.8;
			}
		}
	}

	.slide-out {
		animation: fadeOut 200ms ease-out forwards;

		@keyframes fadeOut{
			0% {
				opacity: 0.8;
			}
			100% {
				opacity: 0;
			}
		}
	}

	.logo-container {
		position: relative;
		display: block;
		width: 95%;
		max-width: 1100px;
		margin: 0 auto 1.5rem;

		.logo {
			max-width: 130px;

			${device.laptop} {
				max-width: 100%;
			}
	
			${device.laptopL} {
				max-width: 100%;
			}
		}
	
	}

	.body {
		width: 95%;
		max-width: 1100px;
		margin: auto;
		padding: 2rem;
		padding-right: 0;
		align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
		overflow: hidden;
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);

		${device.laptop} {
			flex-direction: row;
		}

		.go-back {
			margin-bottom: 1.5rem;
			background-color: white;
			color: #89847e;
			font-size: 13px;

			${device.laptop} {
				font-size: 16px;
			}
		}

		h1.title {
			display: flex;
			align-items: center;
			font-size: 16px;

			${device.laptop} {
				margin: 1.5rem;
				margin-top: 2rem;
				font-size: 25px;
			}

			button {
				padding: 0.5rem 1.25rem;
				margin-top: 1rem;
				color: white;
				border-radius: 16px;
				border: none;
				font-size: 13px;
				margin: auto;
				
				&.success {
					background-color: ${Colors.success};
				}

				&.yellow {
					background-color: ${Colors.yellow};
				}

				&.orange {
					background-color: ${Colors.orange};
				}

				&.error {
					background-color: ${Colors.error};
				}

				${device.laptop} {
					display: flex;
					margin-left: 2rem;
				}

				&:hover {
					cursor: auto;
				}
			}
		}

		.conseils-container {
			padding-right: 1rem;
			margin-bottom: 1rem;
			max-height: 40vh !important;
			overflow: hidden;

			.conseils {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 20px;
				padding-right: 2rem;
				max-height: 40vh !important;
				overflow-y: auto;
				box-sizing: content-box;
	
				&::-webkit-scrollbar {
					width: 10px;
				}
	
				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
					border-radius: 10px;
				}
	
				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background-color: ${Colors.yellow};
					-webkit-box-shadow: inset 0 0 6px rgba(${Colors.yellow},0.5); 
				}
	
				h2.title {
					display: flex;
					align-items: center;
					font-size: 25px;
					margin-bottom: 70px;
	
					.number {
						width: 30px;
						height: 30px;
						background-color: ${Colors.yellow};
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						font-size: 14px;
						margin-left: 1rem;
					}
				}
	
				.conseil {
					display: flex;
					background-color: white;
					align-items: center;
					justify-content: space-between;
					padding: 1.5rem;
					margin-bottom: 2rem;
					position: relative;
					border-radius: 8px;
					box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
	
					&:hover {
						cursor: pointer;
					}
	
					.yellow-bar {
						position: absolute;
						width: 5px;
						height: 100%;
						background-color: ${Colors.yellow};
						left: -21px;
						top: 0;
	
						${device.laptop} {
							width: 4px;
							left: -24px;
						}
					}
					
					.content {
						display: flex;
						height: 100%;
						flex-direction: column;
						padding-right: 1.5rem;
						background: #fff;
	
						.title {
							color: #89847e;
							text-transform: uppercase;
							font-size: 12px;
							margin-bottom: 1.25rem;
	
							${device.laptop} {
								font-size: 17px;
							}
						}
	
						.desc {
							font-size: 13px;
							font-weight: 500;
							margin-bottom: 1.75rem;
							${device.laptop} {
								font-size: 14px;
							}
						}
	
						.footer {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: space-between;
	
							.plan,
							.formations {
								display: flex;
								align-items: center;
								justify-content: space-between;
								font-size: 12px;
								font-weight: 600;
								color: ${Colors.textColor};
								text-transform: uppercase;
	
								${device.laptop} {
									font-size: 14px;
								}
	
								.number {
									margin-left: 0.5rem;
									width: 28px;
									height: 28px;
									border-radius: 50%;
									background-color: ${Colors.buttonClear};
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: 14px;
									color: ${Colors.mainColor};
								}
							}
	
							.formations {
								padding-right: 1rem;
							}
						}
					}
	
					.arrow {
						svg {
							font-size: 2rem;
						}
					}
	
				}
	
			}
	

		}

		.conseil {
			position: relative;
			background: #fff;
			width: 100%;
			
			${device.laptop} {
				width: 46%;
			}

			.go-back {
				font-size: 25px;

				&:hover {
					cursor: pointer;
				}
			}

			> .desc {
				margin-top: 50px;
				margin-bottom: 60px;
				font-size: 17px;
				font-weight: 500;
				padding-left: 32px;
				line-height: 1.5em;
			}

			.plan-action {
				.title {
					text-transform: uppercase;
					color: #89847e;
					margin-bottom: 24px;
				}
				.desc {
					font-size: 16px;
					font-weight: normal;
					margin-left: 32px;
					line-height: 1.5rem;

					ul {
						list-style: none;
						padding-left: 0;
						margin: 0;
					}
				}
			}

			.plus {
				margin-top: 50px;

				.title {
					color: #89847e;
					text-transform: uppercase;
				}

				.recommendation {
					margin-bottom: 15px;
					background-color: white;

					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 30%;
						background-color: grey;
						height: 100%;
						object-fit: cover;
					}

					.content {
						width: 70%;
						padding: 2rem;
						h3 {
							margin-top: 0;
						}

						.desc {
							font-size: 14px;
						}

						.footer {
							margin-top: 2rem;
							justify-content: space-between;
							align-items: center;
							display: flex;

							.icons {
								height: 20px;
								width: auto;
								background: transparent;
							}
							.external {
								width: 20px;
								height: 20px;
								background-color: transparent;
								font-weight: bold;
								display: flex;
								align-items: center;
								justify-content: center;
								color: black;
								text-decoration: none;

								&:hover {
									cursor: pointer;
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}

		.conseil-detailed {
			position: relative;
			padding: 2rem;
			margin-left: -2rem;
			border-radius: 16px;
			background: #fff;
			z-index: 99;
			top: 110vh;

			.single-conseil-header {
				display: flex;
				justify-content: space-between;

				.conseil-nr {
					font-size: 25px;
					font-weight: 500;
					color: ${Colors.textColor};
				}
	
				.go-back {
					font-size: 25px;
					&:hover {
						cursor: pointer;
					}
				}
			}

			> .desc {
				margin-top: 1rem;
				margin-bottom: 60px;
				font-size: 17px;
				font-weight: 500;
				line-height: 1.5em;
			}

			.plan-action {
				.title {
					margin-top: 12px;
					margin-bottom: 24px;
					text-transform: uppercase;
					color: #89847e;
				}
				.desc {
					font-size: 16px;
					font-weight: normal;
					line-height: 1.5rem;

					ul {
						list-style: none;
						padding-left: 0;
						margin: 0;
					}
				}
			}

			.plus {
				margin-top: 50px;

				.title {
					color: #89847e;
					text-transform: uppercase;
				}

				.recommendation-card-container {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					margin-right: 1rem;
					height: 100%;

					.recommendation {
						display: flex;
						width: 100%;
						height: 100%;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 15px;
						background-color: white;
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	
						${device.laptop} {
							width: 48%;
							height: auto;
						}

						a {
							display: block;

							.recommendation-container {
								display: flex;
								flex-wrap: wrap;
								flex-direction: row;
								justify-content: center;
								align-items: center;
								width: 100%;

								.visible-on-large-screen {
									display: none;
	
									${device.laptop} {
										max-width: 150px;
										max-height: 150px;
										display: block;
										margin: 0rem;
										background-color: grey;
										object-fit: cover;
									}
								}

								.content {
									width: 100%;
									padding: 1.5rem;
									margin: 0 auto;

	
									${device.laptop} {
										width: 69%;
									}
	
									.visible-on-small-screen {
										img {
											max-width: 150px;
											max-height: 150px;
											display: block;
											margin: 0rem auto 1.5rem;
											background-color: grey;
											object-fit: cover;
										}
										
										${device.laptop} {
											display: none;
										}
									}
	
									h3 {
										margin-top: 0;
										color: ${Colors.textColor};
									}
			
									.desc {
										color: ${Colors.textColor};
										font-size: 14px;
									}
			
									.footer {
										margin-top: 1.5rem;
										justify-content: space-between;
										align-items: center;
										display: flex;
			
										.icons {
											height: 20px;
											width: auto;
											background: transparent;
										}
										.external {
											width: 20px;
											height: 20px;
											background-color: transparent;
											font-weight: bold;
											display: flex;
											align-items: center;
											justify-content: center;
											color: black;
											text-decoration: none;
			
											&:hover {
												cursor: pointer;
												text-decoration: none;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.slide-in {
			animation: slideIn 0.4s ease-in forwards;

			@keyframes slideIn {
				0% {
					top: 110vh;
				}
				100% {
					top: 0;
				}
			}
		}

		.slide-out {
			animation: slideOut 0.4s ease-out forwards;

			@keyframes slideOut {
				0% {
					top: 0;
				}
				100% {
					top: 110vh;
				}
			}
		}

		.card-row {
			width: 100%;
			padding: 1rem;
			overflow: hidden;
	
			&:hover {
				.card-container {
					.slider-arrow-left {
						visibility: visible;
					}

					.slider-arrow-right {
						visibility: visible;
					}
				}
			}

			.header {
				margin-bottom: 2rem;
	
				h2 {
					font-size: 16px;
	
					${device.laptop} {
						font-size: 20px;
					}
				}
			}
	
			.card-container {
				display: flex;
				flex-direction: row;
				width: 100%;
				height: 100%;
				overflow-x: scroll;
				box-sizing: content-box;
	
				&::-webkit-scrollbar {
					height: 10px;
				}
	
				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
					border-radius: 10px;
				}
	
				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background-color: ${Colors.yellow};
					-webkit-box-shadow: inset 0 0 6px rgba(${Colors.yellow},0.5); 
				}
	
				${device.tablet} {
					&::-webkit-scrollbar {
						height: 0;
					}
				}
	
				.slider-arrow-left {
					display: none;
					visibility: hidden;
					position: absolute;
					margin-top: 6rem;
					width: 30px;
					height: 30px;
					padding-top: 7px;
					padding-left: 9px;
					border-radius: 50%;
					background-color: rgba(0, 0, 0, 0.5);
					z-index: 9;
					
					&:hover {
						cursor: pointer;
					}
	
					${device.laptop} {
						display: block;
						margin-top: 3rem;
					}
	
					.arrow-left {
						width: 15px;
						height: 15px;
						border-left: 3px solid #fff;
						border-bottom: 3px solid #fff;
						border-radius: 0.1rem;
						transform: rotate(45deg);
					}
				}


				.slider-arrow-right {
					display: none;
					visibility: hidden;
					position: absolute;
					margin-top: 6rem;
					width: 30px;
					height: 30px;
					margin-left: 75vw;
					padding-top: 7px;
					padding-left: 6px;
					border-radius: 50%;
					background-color: rgba(0, 0, 0, 0.5);
	
					&:hover {
						cursor: pointer;
					}
	
					${device.laptop} {
						display: block;
						margin-top: 3rem;
						margin-left: 75vw;
					}
	
					${device.laptopL} {
						display: block;
						margin-left: 950px;
					}
	
					.arrow-right {
						width: 15px;
						height: 15px;
						border-right: 3px solid #fff;
						border-bottom: 3px solid #fff;
						border-radius: 0.1rem;
						transform: rotate(-45deg);
					}
				}
	
				.card-slider {
					display: block;
					
					${device.tablet} {
						display: flex;
						flex-direction: row;
					}
	
					.card {
						display: flex;
						flex-direction: row;
						margin-right: 2rem;
						margin-bottom: 1rem;
						padding: 1rem;
						border: 1px solid ${Colors.lightGrey};
						border-radius: 8px;
	
						.dot {
							width: 25px;
							height: 25px;
							border-radius: 50%;
							margin: 1rem;
							background-color: ${Colors.lightGrey};
	
							&.success {
								background-color: ${Colors.success};
							}
	
							&.yellow {
								background-color: ${Colors.lightYellow};
							}
	
							&.orange {
								background-color: ${Colors.orange};
							}
	
							&.error {
								background-color: ${Colors.error};
							}
						}
	
						h3 {
							min-width: 250px;
							font-size: 0.9rem;
	
							${device.laptop} {
								width: 500px;
								font-size: 1rem;
							}
						}

						p {
							font-size: 0.8rem;

							${device.laptop} {
								width: 500px;
								font-size: 0.9rem;
							}
						}
					}
				}
			}
	
		}
	}
`;
