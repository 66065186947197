const CONTENT = {
	1: {
		name: "Croissance",
		description:
			"La croissance interne (on parle aussi de croissance organique) désigne la croissance de chiffre d’affaires d'une entreprise qu'elle a généré par ses propres moyens en augmentant ses ventes en volume ou en prix.",
	},
	2: {
		name: "Taux de marge brute d’exploitation",
		description:
			"Résultat dégagé par l’entreprise de ses seules activités d’exploitation.",
	},
	3: {
		name: "Rendement de l’actif investi",
		description:
			"Capacité d’une entreprise à générer un revenu à partir de ses ressources.",
	},
	4: {
		name: "Liquidité",
		description:
			" Capacité de l’entreprise à faire face aux échéances de ses créanciers à court terme.",
	},
	5: {
		name: "Solvabilité",
		description:
			"Capacité d’une entreprise à rembourser ses créances à court, moyen ou long terme.",
	},
	6: {
		name: "Endettement",
		description:
			"Le taux d’endettement mesure le niveau des dettes de l’entreprise par rapport à ses fonds propres.",
	},
	7: {
		name: "Chiffre d’affaires par salarié",
		description:
			"Indicateur de performance commerciale.",
	},
};

export default CONTENT;
