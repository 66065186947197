import React, {useState, useCallback} from "react";
import {GlossaireStyled} from "./Glossaire.styles";
import content from "./content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";

// Redux
import {useDispatch} from "react-redux";
import {showGlossaire} from "store/user.slice";

interface GlossaireI {
	isActive: boolean;
}

function Glossaire({isActive}: GlossaireI): JSX.Element {
	const dispatch = useDispatch();
	const [closing, setClosing] = useState(false);

	const handleClose = () => {
		setClosing(true);
		setTimeout(() => {
			setClosing(false);
			dispatch(showGlossaire());
		}, 300);
	};

	const [searchVal, setSearchVal] = useState("");
	const handleSearch = (val: string) => {
		setSearchVal(val);
	};

	// Content Height
	const [contentHeight, setContentHeight] = useState<undefined | number>(undefined);

	const chRef = useCallback((node: any) => {
		setTimeout(() => {
			if (node !== null) {
				const wHeight = window.innerHeight;
				const ch = node.getBoundingClientRect().y;
				setContentHeight(wHeight - ch);
			}
		}, 600);
	}, []);

	return isActive ? (
		<GlossaireStyled contentHeight={contentHeight}>
			<div
				className={`overlay ${closing ? "overlay-closing" : "overlay-opening"}`}
				onClick={() => handleClose()}
				role="button"
				onKeyPress={(e) => e.keyCode === 13 && handleClose()}
				tabIndex={0}
			/>
			<div
				className={`modal-container ${closing ? "container-closing" : "container-opening"}`}
			>
				<div className="header">
					<h3>Glossaire</h3>
					<div
						className="close"
						onClick={handleClose}
						role="button"
						tabIndex={0}
						onKeyPress={(e) => e.keyCode === 13 && handleClose()}
					>
						<FontAwesomeIcon icon={faTimes} />
						<span>Retour</span>
					</div>
				</div>
				<div className="header-desktop">
					<div className="left">
						<h3>Glossaire</h3>
						<div className="search-wrapper">
							<input
								type="text"
								name="search-glossaire"
								id="search-glossaire"
								placeholder="Spécifiez votre recherche"
								onChange={(e) => handleSearch(e.target.value)}
							/>
							<FontAwesomeIcon icon={faSearch} />
						</div>
					</div>
					<div className="right">
						<div
							className="close-desktop"
							onClick={handleClose}
							role="button"
							tabIndex={0}
							onKeyPress={(e) => e.keyCode === 13 && handleClose()}
						>
							<FontAwesomeIcon icon={faTimes} />
							<span>Retour au diagnostic</span>
						</div>
					</div>
				</div>
				<div className="body">
					<div className="search-wrapper">
						<input
							type="text"
							name="search-glossaire"
							id="search-glossaire"
							placeholder="Spécifiez votre recherche"
							onChange={(e) => handleSearch(e.target.value)}
						/>
						<FontAwesomeIcon icon={faSearch} />
					</div>
					<div className="content" ref={chRef}>
						{content
							.filter((c) => c.title.toLowerCase().includes(searchVal.toLowerCase()))
							.map((c, idx) => (
								<div className="def" key={`gl-${idx}`}>
									<h3 dangerouslySetInnerHTML={{__html: c.title}}></h3>
									<p dangerouslySetInnerHTML={{__html: c.description}}></p>
								</div>
							))}
					</div>
				</div>
			</div>
		</GlossaireStyled>
	) : (
		<></>
	);
}

export default Glossaire;
