import {useState, useEffect, useRef} from "react";
import Body from "components/layout/Body/Body";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faCheckCircle, faCircle, faCaretSquareLeft} from "@fortawesome/free-regular-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {DashboardStyled} from "./Dashboard.styles";
import {ModalDownloadStyled} from "./ModalDownload.styles";
import {Container} from "theme/Theme";
import {useDispatch} from "react-redux";
import {faLongArrowAltDown} from "@fortawesome/free-solid-svg-icons";
import ResultGraph from "./ResultGraph/ResultGraph";
import {useAppSelector} from "store/storeHooks";
import ConseilListItem from "./ConseilListItem";
import {usePDF} from "@react-pdf/renderer";
import {postPDF} from "store/user.actions";
import {getDiagnosisScores} from "store/enterprise.actions";
import {indicateurAnalysesCategories} from "store/enterprise.slice";
import CreatePDF from "screens/Dashboard/CreatePDF/CreatePDF";
import Colors from "theme/Colors";
import {setPdfDownloaded} from "store/user.slice";
import {indicateursAnalysesVariableToTerm, allAdviceCategories, getColor} from "utils/utils";
import PaperLogo from "assets/img/documents.svg";

// const listItems = [
// 	{id: 1, name: "Complétez le questionnaire", done: false},
// 	{id: 2, name: "Téléchargez votre diagnostic en PDF", done: false},
// 	{id: 3, name: "Découvrez les conseils personnalisés par thématiques", done: false},
// 	{id: 4, name: "Participer à une de nos formations", done: false},
// 	{id: 5, name: "Dites-nous comment améliorer ce service", done: false},
// ];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Dashboard(props: any): JSX.Element {
	const dispatch = useDispatch();
	const pdfDownloaded = useAppSelector((state) => state.user.pdfDownloaded);
	// const hasSeenConseils = useAppSelector((state) => state.user.hasSeenConseils);
	const companies = useAppSelector(
		(state) => state.enterprise.diagnosisResponse?.sectorial.activity.sector.companies
	);
	const enterpriseName = useAppSelector((state) => state.enterprise.selectedEnterprise?.name);
	const advices = useAppSelector((state) => state.enterprise.advices);
	const diagnosisScores = useAppSelector((state) => state.enterprise.scores);
	const report = useAppSelector((state) => state.enterprise.report);
	const cnae = useAppSelector((state) => state.enterprise.diagnosisResponse?.sectorial.cnae);
	const [updatedContent, setUpdatedContent] = useState(false)
 
	const boxHeight = 460;
	const [childHeight, setChildHeight] = useState<undefined | number>(undefined);
	const parent = useRef<HTMLDivElement>(null);
	const child = useRef<HTMLDivElement>(null);

	const [instance, updateInstance] = usePDF({document: CreatePDF(advices, diagnosisScores, report)});
	const [blobsize, setBlobsize] = useState<undefined | number>(undefined)
	const [sent, setSent] = useState(false);

	const [open, setOpen] = useState(false);

	const handleClick = () => {
		if (instance.loading || instance.error) return;
		dispatch(setPdfDownloaded());
		setOpen(false);
	};

	const handleCloseModal = () => {
		setOpen(false)
	}

	// upon creation the PDF has only static content (so not null or undefined), 
	// but should only be uploaded once all dynamic content is available (size > than static PDF)
	useEffect(() => {
		if (instance.blob?.size) {
			if (blobsize && instance.blob.size > blobsize && !sent) {
				const blob = instance.blob;
				dispatch(postPDF(blob));
				setSent(true);
			} else {
				setBlobsize(instance.blob.size)
			}
		}
	}, [instance, blobsize, dispatch, sent])

	useEffect(() => {
		// check if advices, diagnosisScores and report have been updated with backend info
		if (Object.values(advices).filter(e => e.length > 0).length > 0 && 
		Object.values(diagnosisScores).filter(e => e !== "").length > 0 && 
		Object.values(report).filter(e => Object.values(e).filter(el => Object.values(el).filter(ele => ele !== 0) )).length > 0 && 
		!updatedContent) {

			updateInstance()
			setUpdatedContent(true)
			setOpen(true)
		}

	}, [dispatch, advices, report, diagnosisScores, instance, updateInstance, updatedContent]);	

	useEffect(() => {
		const checkValues = Object.values(diagnosisScores).filter(e => !!e)
		if (checkValues.length <= 0) {
			dispatch(getDiagnosisScores())
		}
	}, [dispatch, diagnosisScores])

	useEffect(() => {
		if (parent.current && child.current) {
			const parentY = parent.current.getBoundingClientRect().y;
			const childY = child.current.getBoundingClientRect().y;
			setChildHeight(boxHeight - (childY - parentY));
		}
	}, [parent, child]);

	// useEffect(() => {
	// 	if (pdfDownloaded) {
	// 		const nList = JSON.parse(JSON.stringify(list));
	// 		nList[1].done = true;
	// 		setList(nList);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [pdfDownloaded]);

	// useEffect(() => {
	// 	if (hasSeenConseils) {
	// 		const nList = JSON.parse(JSON.stringify(list));
	// 		nList[1].done = true;
	// 		setList(nList);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [hasSeenConseils]);

	const getConseilClassColor = (name: string) => {
		const getScore = Object.entries(diagnosisScores).filter(e => e[0] === name)
		const score = getScore.length > 0 ? getScore[0][1] : ""

		return getColor(score)
	}


	return (
		<Body
			url="/dashboard"
			title="Mon coaching financier"
			headerRight={
				instance.loading || instance.error ? (
					<span
						className="title"
						style={{
							color: Colors.mainColor,
							padding: "0.5rem 1rem",
							borderRadius: "1rem",
							textDecoration: "underline",
							backgroundColor: Colors.buttonClear
						}}
					>
						{window.innerWidth <= 470 ? '...' : 'Chargement du document...'}
					</span>
				) : (
					<a
						//@ts-ignore
						href={instance.url}
						className="title"
						style={{
							color: Colors.mainColor, 
							padding: "0.5rem 1rem",
							borderRadius: "1rem",
							textDecoration: "underline",
							textDecorationColor: window.innerWidth <= 470 ? Colors.buttonClear : "",
							backgroundColor: Colors.buttonClear
						}}
						target="_blank"
						rel="noreferrer"
						onClick={() => dispatch(setPdfDownloaded())}
					>
						↓ {window.innerWidth <= 470 ? '' : 'Télécharger mon diagnostic'}
					</a>
				)
			}
		>
			<DashboardStyled boxHeight={boxHeight} childHeight={childHeight}>
				<Container>
					<div className="top-row">
						{/* <div className="left">
							<h2>Bienvenue.</h2>
							<p>
								Voici quelques étapes vous guidant dans la découverte de ce service.
							</p>
							<div className="list">
								<ul>
									{list.map((el, idx) => (
										<li
											key={`list-${idx}`}
											style={{
												textDecoration: el.done ? "line-through" : "none",
											}}
										>
											<FontAwesomeIcon
												icon={el.done ? faCheckCircle : faCircle}
											/>
											<span>{el.name}</span>
										</li>
									))}
								</ul>
							</div>
						</div> 
						<div className="right" ref={parent}>*/}
						<div className="indicateurs-analyses-container" ref={parent}>
							<div className="indicateurs-analyses-titles">
								<h2>Indicateurs analysés</h2>
								<p>Découvrez les conseils suggérés pour {enterpriseName} classés par thématiques</p>
							</div>
							<span className="conseils" ref={child}>
								{Object.entries(advices).map(([key, value], idx) => {
									if (allAdviceCategories.includes(key)) {
										const categorie =  Object.values(indicateurAnalysesCategories)[Object.keys(indicateurAnalysesCategories).indexOf(key)]
										return (
											<ConseilListItem
												key={`conseil-item-${idx}`}
												name={indicateursAnalysesVariableToTerm(key)}
												className={`indicateurs-analyses-new ${getConseilClassColor(key)}`}
												amount={value.length.toString()}
												code={value}
												color={getConseilClassColor(key)}
												categorie={categorie}
											/>
										)
									}
									return null
								})}
							</span>
						</div>
					</div>
					<div className="bottom-row">
						<div className="header">
							<h2>Comparez votre entreprise</h2>
							<div>
								Nous comparons {enterpriseName} avec {companies} autres entreprises
								du même secteur ({cnae}).
							</div>
						</div>
						<div className="description-line">La vignette colorée représente la performance de votre entreprise sur chacun des ratios et permet de vous situer et de vous comparer avec le secteur.</div>
						<div className="repartition-entreprises-title">Répartition des entreprises du secteur</div>
						<div className="content">
							<ResultGraph />
						</div>
					</div>
					{updatedContent && !pdfDownloaded  ? (
						<>
						<ModalDownloadStyled 
							className={open ? "open" : "close"}
							role="button"
							onBlur={handleCloseModal}
							onKeyPress={(e) => e.keyCode === 13 && handleCloseModal}
							tabIndex={0}
						>
							<div className="closing-icon">
								<span
									role="button"
									onClick={handleCloseModal}
									onKeyPress={(e) => e.keyCode === 13 && handleCloseModal}
									tabIndex={0}
								>
									<FontAwesomeIcon 
										icon={faTimes}
									/>
								</span>
							</div>
							<div className="content">
								<h3>Conservez votre diagnostic !</h3>
								<div className="desc">
									<p>
										Attention, l’accès aux résultats de votre diagnostic n’est disponible
										qu’une fois.
									</p>
									<p>
										Ainsi, nous vous invitons à télécharger votre rapport au format pdf afin
										de pouvoir le consulter a posteriori et conserver les recommandations,
										plan d’action et formations Bpifrance proposés.
									</p>
								</div>
							</div>
							<div className="paper-logo">
									<img src={PaperLogo} alt="paper-logo" />
							</div>
			
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								// @ts-ignore
								href={instance.loading || instance.error ? "#" : instance.url}
								className="title download-button"
								style={{color: "#777", textDecoration: "none"}}
								target="_blank"
								rel="noreferrer"
								onClick={() => handleClick()}
							>
								<div className="icon">
									<FontAwesomeIcon icon={faLongArrowAltDown} />
								</div>
			
								<span className="title" style={{color: "#777", textDecoration: "none"}}>
									{instance.loading || instance.error
										? "Chargement..."
										: "Mon diagnostic"
									}
								</span>
			
								<span className="spec">PDF</span>
							</a>
						</ModalDownloadStyled>
						{/* {open ? <MobileOverlay /> : <></>} */}
					</>
					) : <></>
					}
				</Container>
			</DashboardStyled>
		</Body>
	);
}

export default Dashboard;
