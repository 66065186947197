import React, {useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import OnboardingMenu from "components/composed/OnboardingMenu/OnboardingMenu";
import SelectedEnterprise from "components/composed/SelectedEnterprise/SelectedEnterprise";

// Redux
import {useDispatch} from "react-redux";
import {ENTERPRISE_STATUS, setOnboardingStep} from "store/enterprise.slice";
import {getEnterpriseAnon} from "store/enterprise.actions";
import {RootState} from "store/store";
import {FakeChoice, FakeFound} from "../DonneesFinancieres.styles";
import {useAppSelector} from "store/storeHooks";

interface Step1I {
	selectedOption: string;
	setSelectedOption: (arg: string) => void;
}

function Step1({selectedOption, setSelectedOption}: Step1I): JSX.Element {
	const dispatch = useDispatch();
	const selectedSiren = useAppSelector(
		(state: RootState) => state.enterprise.selectedEnterprise?.siren
	);
	const selectedActivity = useAppSelector((state) => state.enterprise.selectedActivity);
	const enterpriseName = useAppSelector((state) => state.enterprise.selectedEnterprise?.name);
	const diagnosisResponse = useAppSelector((state) => state.enterprise.diagnosisResponse);
	const diagnosisAnonStatus = useAppSelector((state) => state.enterprise.diagnosisAnonStatus);

	useEffect(() => {
		dispatch(setOnboardingStep(3));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (diagnosisAnonStatus !== ENTERPRISE_STATUS.INITIAL) {
			return;
		}
		dispatch(
			getEnterpriseAnon({
				siren: selectedSiren ? selectedSiren : "",
				cnae: selectedActivity,
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="top-content">
				<h1>Données financières</h1>
				<p>
					Ce diagnostic se nourrit des données publiques agrégées auprès des sources
					telles que la Banque de France, l’INSEE et l’INPI.
				</p>
			</div>
			<div className="bottom-content">
				<div className="left">
					<SelectedEnterprise className="desktop" />
					<OnboardingMenu className="onboarding-desktop" />
				</div>
				<div className="right">
					{diagnosisAnonStatus === ENTERPRISE_STATUS.INITIAL ? (
						<>
							<FakeFound />
							<FakeChoice />
						</>
					) : (
						<>
							{diagnosisAnonStatus === ENTERPRISE_STATUS.SUCCESS ? (
								<div className="found">
									<span>
										Date des dernières données financières disponibles de{" "}
										{enterpriseName}
									</span>
									<button>{diagnosisResponse?.balance_date}</button>
								</div>
							) : null}

							<div className="choice">
								<p>
									Souhaitez vous actualiser les données financières avant de
									démarrer le diagnostic ?
								</p>
								<div className="questions">
									<button
										onClick={() => setSelectedOption("Oui")}
										className={selectedOption === "Oui" ? "selected" : ""}
									>
										Oui
										{selectedOption === "Oui" ? (
											<div className="circle">
												<FontAwesomeIcon icon={faCheck} />
											</div>
										) : null}
									</button>
									<button
										onClick={() => setSelectedOption("Non")}
										className={selectedOption === "Non" ? "selected" : ""}
									>
										Non
										{selectedOption === "Non" ? (
											<div className="circle">
												<FontAwesomeIcon icon={faCheck} />
											</div>
										) : null}
									</button>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default Step1;
