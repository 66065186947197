import React from "react";
import Body from "components/layout/Body/Body";

// Styles
import {StartDiagnosticWrapper} from "./StartDiagnostic.styles";

// Content
import BPIlogo from "assets/img/BpiFrance";
import Inbonislogo from "assets/img/LogoInbonis";
import HomeImage from "assets/img/home.png";

function StartDiagnostic(): JSX.Element {
	const handleClick = () => {
		return;
	};

	return (
		<Body url="start-diagnostic" title="Coaching financer – Bpifrance">
			<StartDiagnosticWrapper>
				<BPIlogo />
				<div className="content-wrapper">
					<div
						className="card"
						onClick={handleClick}
						onKeyPress={(e) => e.keyCode === 13 && handleClick()}
						role="link"
						tabIndex={0}
					>
						<div className="content">
							<div className="left">
								<h1>Le diagnostic va débuter</h1>
							</div>
							<div className="right">
								<img src={HomeImage} alt="Home" />
							</div>
						</div>
					</div>
				</div>
				<div className="footer-logo">
					<span>Propulsé par</span>
					<Inbonislogo />
				</div>
			</StartDiagnosticWrapper>
		</Body>
	);
}

export default StartDiagnostic;
