import {useState, ReactElement, useRef, useEffect} from "react";
import styled from "styled-components";
import {faPaperclip, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import Body from "components/layout/Body/Body";
import Button from "components/units/Button/Button";
import ResultGraph from "screens/Dashboard/ResultGraph/ResultGraph";
import {Container} from "theme/Theme";
import {QuickViewStyled} from "./QuickView.styles";
import {useAppSelector} from "store/storeHooks";
import {PDFDownloadLink} from "@react-pdf/renderer";
import CreatePDF from "./CreatePDF/CreatePDF";
import {useHistory} from "react-router-dom";
import Colors from "theme/Colors";
import useWindowSize from "hooks/useWindowSize";

const RepetitionEntreprisesTitle = styled.div`
	position: relative;
	margin-top: 2rem;
	font-size: 12px;
	color: ${Colors.textColor};
`

const StyledBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: ${Colors.backgroundGrey};
	opacity: 0.8;
	z-index: 1;
`

function QuickView(): ReactElement {
	const history = useHistory();
	const [showComment, setShowComment] = useState(false);
	const [height, setHeight] = useState(0);
	const [top, setTop] = useState(0);
	const enterpriseName = useAppSelector((state) => state.enterprise.selectedEnterprise?.name);
	const companies = useAppSelector((state) => state.enterprise.diagnosisResponse?.sectorial.activity.sector.companies);
	const cnae = useAppSelector((state) => state.enterprise.diagnosisResponse?.sectorial.cnae);
	const ref = useRef<HTMLDivElement>(null);
	const { width } = useWindowSize();
	
	useEffect(() => {
		if (ref.current !== null) {
			const {height, top} = ref.current.getBoundingClientRect();
			setHeight(height);
			setTop(top);
		}
	}, []);

	const handleCloseModal = () => {
		setShowComment(false)
	}

	return (
		<Body url="/quick-view" title="Mon entreprise – Bpifrance en ligne">
			{showComment && width < 768
				? <StyledBackground onClick={handleCloseModal}/>
				: null
			}
			<QuickViewStyled showComment={showComment} height={height} top={top}>
				<Container>
					<div className="card">
						<div className="top">
							<div className="top-title">
								<h1>Où se situe mon entreprise ?</h1>
								<Button
									className="primary-clear"
									text="Comment lire ce graphique ?"
									type="button"
									iconPosition="left"
									icon={faQuestionCircle}
									onClick={() => setShowComment(!showComment)}
								/>
							</div>
							<p>
								Nous avons comparé {enterpriseName} avec toutes les entreprises renseigées ({companies}) ayant le même code NAF (secteur d´activité {cnae}) dans le base de données de la Banque de France.
							</p>
							<p>
								La vignette colorée représente la performance de votre entreprise sur chacun des ratios et permet de vous situer et de vous comparer avec le secteur.
							</p>
							<RepetitionEntreprisesTitle>Répartition des entreprises du secteur</RepetitionEntreprisesTitle>
						</div>
						<ResultGraph className="result-graph" ref={ref} />
						{showComment ? (
							<div 
								className="comment-modal"
								style={{
									zIndex: 'inherit'
								}}
								onBlur={() => setShowComment(false)}
								role="button"
								onKeyPress={(e) => e.keyCode === 13 && setShowComment(false)}
								tabIndex={0}
							>
								<h2>Comment lire ce graphique ?</h2>
								<p>
									Plusieurs indicateurs financiers vous sont proposés afin d&apos;évaluer la performance de votre entreprise et vous comparer avec votre secteur d’activité.
								</p>
								<p>
									Les entreprises de votre secteur sont réparties, selon leur performance sur chacun des indicateurs, à effectif égal en 4 groupes (à l&apos;exception de la croissance où seule une moyenne est faite).
								</p>
								<p>
								La vignette colorée permet de vous situer par rapport aux autres entreprises du secteur.
								</p>
								<Button
									type="button"
									text="Consulter mes données"
									className="button primary"
									onClick={() => setTimeout(() => setShowComment(false), 300)}
									buttonStyles={{marginLeft: "auto", marginTop: "3rem"}}
								/>
							</div>
						) : (
							<div className="footer">
								<Button
									className="secondary"
									type="button"
									text="Je continue mon diagnostic financier"
									onClick={() => setTimeout(() => history.push("/questionnaire"), 300)}
								/>
								<PDFDownloadLink document={<CreatePDF />} fileName="Diagnostic-Bpifrance.pdf">
									{({blob, url, loading, error}) =>
										loading ? (
											<Button
												className="primary"
												type="button"
												text="Chargement du document..."
												iconPosition="left"
												icon={faPaperclip}
												disabled={true}
											/>
										) : (
											<Button
												className="primary"
												type="button"
												text="Je télécharge ce rapport simplifié"
												buttonStyles={{ textDecoration: 'underline', textDecorationColor: Colors.yellow}}
												iconPosition="left"
												icon={faPaperclip}
											/>
										)
									}
								</PDFDownloadLink>
							</div>
						)}
					</div>
				</Container>
			</QuickViewStyled>
		</Body>
	);
}

export default QuickView;
