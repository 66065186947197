import styled from "styled-components";
import Colors from "theme/Colors";

// Styles
import {device} from "theme/GlobalStyles";

interface ServicePremiumI {
	contentHeight: number | undefined;
}

export const ServicePremiumStyled = styled.div<ServicePremiumI>`
	display: flex;
	justify-content: center;
	align-items: flex-end;
	bottom: 0;
	left: 0;
	padding: 0rem;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 1;
	z-index: 400;

	.overlay {
		background: #f5f7fb;
		bottom: 0;
		cursor: default;
		display: block;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		animation: fadeIn 0.3s ease-in-out forwards;

		@keyframes fadeIn {
			0% {
				opacity: 0;
			}
			100% {
				opacity: 0.62;
			}
		}
	}

	.modal-container {
		display: flex;
		flex-direction: column;
		max-width: 95%;
		min-width: 95%;
		top: 10vh;
		padding: 1rem;
		padding-top: 1.5rem;
		z-index: 1;
		background: #fff 0% 0% no-repeat padding-box;
		background-color: #fff;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		opacity: 1;
		margin-bottom: -150vh;
		animation: slideIn 0.5s ease-in-out forwards;
		position: absolute;

		${device.laptop} {
			width: 90%;
			min-width: 90% !important;
			max-width: 1200px;
			top: -5vh;
			margin-bottom: -80vh;
			position: relative;
		}

		@keyframes slideIn {
			100% {
				margin-bottom: 0;
			}
		}

		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			h2 {
				img {
					max-width: 40px;
					height: auto;
					position: absolute;
					left: 0;
					top: 25px;
				}

				span {
					font-size: 33px;
					font-weight: 500;
					margin: 0;
					margin-left: 2rem;
				}
			}
		}

		.close {
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid ${Colors.mainColor};
			padding: 0.5rem 1rem;
			border-radius: 1rem;
			margin-right: 1.5rem;
			margin-top: 50px;

			${device.laptop} {
				margin-top: 0px;
			}

			&:hover {
				cursor: pointer;
			}

			span {
				font-size: 14px;
				font-weight: 500;
				text-decoration: none;
			}

			svg {
				margin-right: 0.75rem;
				color: ${Colors.mainColor};
			}
		}

		.visible-on-small-screen {
			display: inline-block;

			${device.tablet} {
				display: none;
			}
		}

		.visible-on-large-screen {
			display: none;

			${device.tablet} {
				display: inline-block;
			}
		}

		.body {
			padding: 3rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			h3 {
				font-weight: 500;
				font-size: 25px;
				text-align: center;
				line-height: 1.5rem;
			}

			.prices {
				display: block;
				align-items: center;
				justify-content: center;
				border-radius: 6px;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
				margin-top: 60px;
				border-radius: 10px;
				z-index: 1199;
				animation: scaleUp 0.75s ease-in-out forwards;
				animation-delay: 1.5s;
				transform: scale(0.8); /* Equal to scaleX(0.7) scaleY(0.7) */

				@keyframes scaleUp {
					0% {
						transform: scale(0.8); /* Equal to scaleX(0.7) scaleY(0.7) */
					}
					100% {
						transform: scale(1); /* Equal to scaleX(0.7) scaleY(0.7) */
					}
				}

				${device.tablet} {
					display: flex;
					margin-top: 80px;
				}

				.price {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					padding: 2rem 3rem;

					h5 {
						margin-top: 0;
						margin-bottom: 0.5rem;
						font-size: 14px;
						font-weight: bold;
					}

					span {
						font-size: 13px;
						opacity: 0.9;
					}

					h3 {
						margin-top: 0;
						margin-bottom: 0.5rem;
						font-size: 16px;

						span {
							font-size: 25px;
						}
					}

					button {
						margin-top: 20px;
						border: 1px solid ${Colors.mainColor};
						padding: 0.75rem 1.5rem;
						border-radius: 1.5rem;
						background-color: #fff;

						&:hover {
							cursor: pointer;
						}
					}

					&.special {
						background-color: ${Colors.yellow};
						border-top-left-radius: 10px;
						border-top-right-radius: 10px;
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
					}
				}
			}
		}

		.discover-subscription-btn {
			margin-top: 80px;
			padding: 1rem;

			${device.laptop} {
				margin-top: 100px;
				padding: 1rem 2rem;
			}
		}

		.croissance,
		.endettement,
		.rendement {
			position: absolute;
			max-width: 240px;
			height: auto;
			display: none;

			${device.laptop} {
				display: block;
			}
		}

		.endettement {
			top: 80%;
			left: -120px;
			transform: translateY(-50%);
			opacity: 0;
			animation: endettement 0.6s ease-in-out forwards;
			animation-delay: 0.3s;

			@keyframes endettement {
				0% {
					opacity: 0;
					top: 50%;
					left: -120px;
				}
				100% {
					opacity: 1;
					top: 80%;
					left: 100px;
				}
			}
		}

		.croissance {
			top: 38%;
			left: -250px;
			animation: croissance 0.6s ease-in-out forwards;
			animation-delay: 0.45s;

			@keyframes croissance {
				0% {
					left: -250px;
				}
				100% {
					left: -10px;
				}
			}
		}

		.rendement {
			bottom: -5%;
			right: -65px;
			transform: translate(-50%);
			animation: rendement 0.6s ease-in-out forwards;
			animation-delay: 0.6s;

			@keyframes rendement {
				0% {
					right: 40%;
					bottom: -5%;
				}
				100% {
					right: -65px;
					bottom: 15%;
				}
			}
		}
	}
`;
