import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	margin-top: 20px;
	// margin-bottom: 20px;

	svg.logo {
		max-width: 145px;

		&:hover {
			cursor: pointer;
		}
	}

	.logo-holder {
		min-width: 145px;

		&:hover {
			cursor: pointer;
		}
	}

	#logo {
		max-width: 145px;

		&:hover {
			cursor: pointer;
		}
	}

	.top-button {
		background-color: ${Colors.buttonClear};
		border: none;
		border-radius: 17px;
		color: ${Colors.mainColor};
		padding: 0.5rem 1.25rem;
		transition: all 0.15s ease-in-out;
		font-weight: 500;
		font-size: 16px;
		display: none;

		${device.tablet} {
			display: block;
		}

		span {
			position: relative;
			display: inline-block;
			z-index: 1;

			&:before {
				content: " ";
				height: 8px;
				width: 105%;
				background-color: ${Colors.yellow};
				position: absolute;
				top: 12px;
				z-index: -1;
				margin-left: -2px;
			}
		}
	}
`;
