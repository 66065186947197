import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const NotSubscribedWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;

	${device.laptop} {
		padding-top: 2rem;
	}

	.card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: white;
		border-radius: 4px;
		box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);

		.content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0.5rem;
			padding-top: 1rem;
			flex-direction: column;

			${device.mobileL} {
				padding: 2rem;
				padding-right: 1rem;
			}

			${device.laptop} {
				flex-direction: row;
			}

			.top-button {
				background-color: ${Colors.buttonClear};
				border: none;
				border-radius: 17px;
				color: ${Colors.mainColor};
				padding: 0.5rem 1.25rem;
				transition: all 0.15s ease-in-out;
				font-weight: 500;
				font-size: 14px;
				align-self: flex-start;
				margin-bottom: 1rem;

				${device.tablet} {
					display: none;
				}

				span {
					position: relative;
					display: inline-block;
					z-index: 1;

					&:before {
						content: " ";
						height: 8px;
						width: 105%;
						background-color: ${Colors.yellow};
						position: absolute;
						top: 10px;
						z-index: -1;
						margin-left: -2px;
					}
				}
			}

			.left {
				display: flex;
				flex-direction: column;
				padding-left: 1rem;

				h1 {
					font-size: 20px;
					font-weight: 500;

					${device.tablet} {
						font-size: 33px;
					}
				}

				img {
					width: 100%;
					max-width: 100%;
					height: auto;

					${device.tablet} {
						display: none;
					}
				}

				p {
					color: #071a39;
					opacity: 0.4;
					margin-bottom: 1rem;

					${device.laptop} {
						padding-right: 4rem;
					}
				}

				.group {
					display: flex;
					align-items: flex-start;
					margin-top: 2rem;
					margin-bottom: 2rem;

					input {
						margin: 0;
						margin-top: 2px;
						margin-right: 1rem;
					}

					label {
						font-size: 14px;
					}
				}

				button.bottom {
					margin-bottom: 2rem;
					align-self: center;

					${device.laptop} {
						align-self: flex-start;
					}
				}
			}

			.right {
				display: flex;
				min-width: 360px;
				img {
					width: 100%;
					max-width: 100%;
					height: auto;
					display: none;

					${device.laptop} {
						display: block;
						width: 450px;
						height: auto;
					}
				}
			}
		}
	}
`;
