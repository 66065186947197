import styled from "styled-components";

export const NotificationsWrapper = styled.div`
	position: fixed;
	width: 360px;
	top: 85px;
	right: 20px;
	z-index: 9999999;
`;

export const NotificationWrapper = styled.li`
	display: flex;
	flex-flow: row;
	align-items: flex-start;
	padding: 12px;
	margin-bottom: 10px;
	background-color: white;
	border: 1px solid #f2f2f2;
	border-radius: 6px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);

	&:last-child {
		margin: 0 0 0.5rem;
	}

	.icon {
		font-size: 48px;
		margin-right: 1rem;
	}

	.notification-dismiss {
		color: #999;
		transition: color 0.2s ease;

		&:hover {
			cursor: pointer;
			color: #444;
		}
	}
`;
