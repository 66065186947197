import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

export const ModalDownloadStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	max-width: 90%;
	position: fixed;
	bottom: 0;
	padding: 2rem;
	background-color: ${Colors.yellow};
	border-radius: 16px;
	opacity: 0;
	animation-delay: 0.75s;
	z-index: 9999;
	animation: slideIn 0.5s ease-in-out forwards;

	${device.laptop} {
		max-width: 1100px;
		flex-direction: row;
		animation: slideInDesktop 0.5s ease-in-out forwards;
	}

	@keyframes slideIn {
		100% {
			bottom: 18%;
			transform: translateY(50%);
			opacity: 1;
		}
	}

	@keyframes slideInDesktop {
		100% {
			bottom: 0;
			transform: translateY(0);
			opacity: 1;
		}
	}

	&.close {
		animation: slideOut 0.5s ease-in-out forwards;
		${device.laptop} {
			animation: slideOutDesktop 0.5s ease-in-out forwards;
		}
		@keyframes slideOut {
			0% {
				bottom: 18%;
				transform: translateY(50%);
				opacity: 1;
			}
			100% {
				bottom: -100%;
				opacity: 0;
			}
		}

		@keyframes slideOutDesktop {
			0% {
				bottom: 1rem;
				transform: translateY(0);
				opacity: 1;
			}
			100% {
				bottom: -100%;
				opacity: 0;
			}
		}
	}

	.closing-icon {
		position: absolute;
		display: flex;
		top: 15px;
		right: 15px;
		justify-content: flex-end;
		width: 100%;

		span {
			&:hover,
			&:focus,
			&:active {
				cursor: pointer;
			}
		}
	}

	.content {
		min-height: 150px;

		h3 {
			font-size: 16px;
			margin-top: 0;

			${device.laptop} {
				font-size: 22px;
			}
		}

		p {
			margin-top: 1.5rem;
			font-size: 12px;
			color: ${Colors.mainColor};
			${device.laptop} {
				font-size: 16px;
			}
		}
	}

	.download-button {
		flex-shrink: 0;
		width: 100%;
		border-radius: 4px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: space-between;

		margin-top: 1rem;

		${device.laptop} {
			margin-left: 2.5rem;
			width: auto;
		}

		&:hover {
			cursor: pointer;
		}

		.icon {
			padding: 0.5rem 0.75rem;
			background-color: #54514d;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;

			svg {
				color: white;
			}
		}

		span.title {
			margin-left: 1rem;
			margin-right: 2rem;
		}

		span.spec {
			color: #89847e;
			font-size: 14px;
			padding-right: 0.5rem;
		}
	}

	.paper-logo {
		position: absolute;
		right: -4rem;
		top: 25%;
		opacity: 0.7;
		width: 20%;

		${device.laptop} {
			right: -3rem;
			top: 70%;
		}
	}
`;

export const MobileOverlay = styled.div`
	background: #f5f7fb;
	bottom: 0;
	cursor: default;
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	opacity: 0.6;

	${device.laptop} {
		display: none;
	}
`;
