import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLandmark} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

// Styles
import {SelectedEnterpriseStyled} from "./SelectedEnterprise.styles";

// Redux
import {RootState} from "store/store";
import {useSelector} from "react-redux";

interface SelectedEnterpriseI {
	className?: string;
}

function SelectedEnterprise({className}: SelectedEnterpriseI): JSX.Element {
	const enterprise = useSelector((state: RootState) => state.enterprise.selectedEnterprise);
	const selectedActivityDesc = useSelector(
		(state: RootState) => state.enterprise.selectedActivityDesc
	);
	return (
		<>
			{enterprise ? (
				<SelectedEnterpriseStyled className={className}>
					<div className="top">
						<div className="left">
							<div className="circle">
								<FontAwesomeIcon className="icon" icon={faLandmark} />
							</div>
						</div>
						<div className="right">
							<div className="data">
								{enterprise.name}
								{selectedActivityDesc !== "" ? (
									<span className="selected-activity">{selectedActivityDesc}</span>
								) : null}
								{enterprise?.siren ? (
									<span className="siren">Siren: {enterprise.siren}</span>
								) : null}
							</div>
						</div>
						<Link to="/selection-entreprise" className="change-enterprise">
							Changer d’entreprise
						</Link>
						<div className="decorative-circle" />
					</div>
				</SelectedEnterpriseStyled>
			) : (
				<></>
			)}
		</>
	);
}

export default SelectedEnterprise;
