import React, {useRef} from "react";
import Body from "components/layout/Body/Body";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faCheck, faLandmark} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

// Styles
import {BienvenueWrapper} from "./Bienvenue.styles";

// Redux
import {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {setSelectedEnterprise} from "store/enterprise.slice";
import {Container} from "theme/Theme";

function Bienvenue(): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();
	const enterprises = useSelector((state: RootState) => state.enterprise.enterprises);

	// Scroll references
	const scrollContainer = useRef(null);
	const scrollElements = useRef<any>([]);
	const boxWidth = 184 + 24; // width + margin

	// Scroll actions
	const goLeft = () => {
		let distance = boxWidth;

		//@ts-ignore
		const curPosition = scrollContainer.current.scrollLeft;
		if (curPosition !== 0 && curPosition % boxWidth !== 0) {
			const diff = curPosition % boxWidth;
			distance = diff;
		}

		//@ts-ignore
		scrollContainer.current.scrollBy({
			left: -distance,
			behavior: "smooth",
		});
	};

	const goRight = () => {
		//@ts-ignore
		scrollContainer.current.scrollBy({
			left: boxWidth,
			behavior: "smooth",
		});
	};

	const setEnterprise = (siren: string) => {
		const enterprise = enterprises.find((e) => e.siren === siren);
		if (enterprise) {
			dispatch(setSelectedEnterprise(enterprise));
			history.push("/secteur-activite");
		}
	};

	return (
		<Body url="/selection-enterprise" title="Entreprise – Bpifrance">
			<BienvenueWrapper>
				<Container>
					<div className="content">
						<div className="top-content">
							<h1>Pour quelle entreprise souhaitez-vous réaliser ce diagnostic ?</h1>
							<p className="subtitle">
								Nous vous proposons un auto-diagnostic afin de prendre de la hauteur
								<br />
								et vous réconcilier avec vos états financiers.
							</p>
						</div>

						<div className="bottom-content">
							<div className="enterprises" ref={scrollContainer}>
								{enterprises.map((e, i: number) => {
									return (
										<div
											key={`q-${i}`}
											className="enterprise"
											ref={(el) => scrollElements.current.push(el)}
											onClick={() => setEnterprise(e.siren)}
											tabIndex={0}
											onKeyPress={(event) =>
												event.keyCode === 13 && setEnterprise(e.siren)
											}
											role="button"
										>
											<div className="circle">
												<FontAwesomeIcon
													className="icon"
													icon={faLandmark}
												/>
											</div>
											<div className="data">
												{e.name}
												{e?.siren ? (
													<span className="siren">Siren: {e.siren}</span>
												) : null}
											</div>
											<div className="decorative-circle" />
										</div>
									);
								})}
							</div>

							{enterprises.length ? (
								<div className="scroll">
									<div className="circle">
										<FontAwesomeIcon icon={faArrowLeft} onClick={goLeft} />
									</div>
									<div className="circle">
										<FontAwesomeIcon icon={faArrowRight} onClick={goRight} />
									</div>
								</div>
							) : null}

							<div className="checklist">
								<div>
									<FontAwesomeIcon className="icon" icon={faCheck} />
									<span>
										Le bilan de votre situation financière en moins de 10
										minutes basé sur vos ratios financiers et complété d’un
										questionnaire qualitatif
									</span>
								</div>
								<div>
									<FontAwesomeIcon className="icon" icon={faCheck} />
									<span>
										Des conseils adaptés et des plans d’actions pour vous aider
										à améliorer vos indicateurs
									</span>
								</div>
								<div>
									<FontAwesomeIcon className="icon" icon={faCheck} />
									<span>
										Une comparaison avec des entreprises de votre secteur
									</span>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</BienvenueWrapper>
		</Body>
	);
}

export default Bienvenue;
