import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
	DiagnosisResponseI,
	getActivities,
	getDiagnosisScores,
	getEnterpriseAnon,
	postDiagnosisAnon,
} from "./enterprise.actions";
import {verifyToken} from "./user.actions";

export const ENTERPRISE_STATUS = {
	INITIAL: "INITIAL",
	LOADING: "LOADING",
	LOADED: "LOADED",
	FAILURE: "FAILURE",
	SUCCESS: "SUCCESS",
};

export enum ConseilCodes {
	BC_01 = "BC_01",
	BC_02 = "BC_02",
	BC_03 = "BC_03",
	BC_04 = "BC_04",
	BC_05 = "BC_05",
	BC_06 = "BC_06",
	BC_07 = "BC_07",
	BC_11 = "BC_11",
	BC_12 = "BC_12",
	BC_13 = "BC_13",
	BQ_01 = "BQ_01",
	BQ_02 = "BQ_02",
	BQ_03 = "BQ_03",
	BQ_04 = "BQ_04",
	BQ_05 = "BQ_05",
	BQ_06 = "BQ_06",
	BQ_07 = "BQ_07",
	BQ_08 = "BQ_08",
	BQ_09 = "BQ_09",
	BQ_10 = "BQ_10",
	BQ_11 = "BQ_11",
	BQ_12 = "BQ_12",
	BQ_13 = "BQ_13",
	BQ_14 = "BQ_14",
	BQ_15 = "BQ_15",
	BQ_16 = "BQ_16",
	BQ_17 = "BQ_17",
	DE_01 = "DE_01",
	DE_02 = "DE_02",
	DE_03 = "DE_03",
	DE_04 = "DE_04",
	DE_05 = "DE_05",
	EI_01 = "EI_01",
	EI_02 = "EI_02",
	EI_03 = "EI_03",
	EI_05 = "EI_05",
	EI_06 = "EI_06",
	FI_01 = "FI_01",
	FI_02 = "FI_02",
	FI_03 = "FI_03",
	FI_04 = "FI_04",
	FI_05 = "FI_05",
	FI_06 = "FI_06",
	FI_07 = "FI_07",
	FI_08 = "FI_08",
	FI_09 = "FI_09",
	FI_10 = "FI_10",
	FI_11 = "FI_11",
	FI_12 = "FI_12",
	MT_01 = "MT_01",
	MT_02 = "MT_02",
	MT_03 = "MT_03",
	MT_04 = "MT_04",
	MT_05 = "MT_05",
	MT_06 = "MT_06",
	MT_07 = "MT_07",
}

export enum indicateurAnalysesCategories {
	financial = "financial",
	debt = "debt",
	business_quality = "business_quality",
	environmental_impact = "environmental_impact",
	managing_team = "managing_team",
	business_confidence = "business_confidence"
}

export interface ConseilI {
	n: string;
	code: string;
	type: string;
	num_conseil: string;
	conseil: string[];
	num_plan_action: string;
	plan_action: string[] | null;
	formations_bpifrance_universite: string[];
	pour_aller_plus_loin_link: string[];
	lien_lms: string[];
	pour_aller_plus_loin_text: string[];
	image: string[];
}

interface EnterpriseInterface {
	postcode: string;
	name: string;
	siren: string;
	constitution_date: string;
}

interface ActivitiesI {
	associated_cnaes: string;
	code: string;
	description: string;
}

interface ReportInterface {
	tempScore: number,
	scores: number[],
	score: number,
	max_score: number
}

interface enterpriseTyped {
	status: string;
	currentOnboardingStep: number;
	selectedEnterprise: EnterpriseInterface | undefined;
	enterprises: EnterpriseInterface[];
	salaries: number;
	activities: ActivitiesI[];
	activitiesCnaes: string[];
	selectedActivity: string;
	selectedActivityDesc: string;
	diagnosisAnonStatus: string;
	bilan: {
		total_assets: number | "";
		total_assets_old: number | "";
		own_fund: number | "";
		own_fund_old: number | "";
		cash_and_other_liquid: number | "";
		total_debt: number | "";
	};
	resultat: {
		revenues: number | "";
		revenues_old: number | "";
		fixed_assets_amortization: number | "";
		fixed_assets_amortization_old: number | "";
		tax_year_result: number | "";
		tax_year_result_old: number | "";
		personnel_expenses: number | "";
		financial_expenses: number | "";
		ebitda: number | "";
	};
	postDiagnosisAnonStatus: string;
	diagnosisResponse: DiagnosisResponseI | undefined;
	selectedConseils: (keyof typeof ConseilCodes)[];
	currentConseils: (keyof typeof ConseilCodes)[] | undefined;
	currentConseilColor: string;
	currentConseilCategorie: keyof typeof indicateurAnalysesCategories | undefined;
	scores: {
		activity_sector: number | "",
		business_confidence: number | "",
		business_quality: number | "",
		debt: number | "",
		environmental_impact: number | "",
		financial: number | "",
		managing_team: number | ""
	},
	advices: {
		activity_sector: (keyof typeof ConseilCodes)[],
		business_confidence: (keyof typeof ConseilCodes)[],
		business_quality: (keyof typeof ConseilCodes)[],
		debt: (keyof typeof ConseilCodes)[],
		environmental_impact: (keyof typeof ConseilCodes)[],
		financial: (keyof typeof ConseilCodes)[],
		managing_team: (keyof typeof ConseilCodes)[]
	},
	report: {
		activity_sector: {
			corona_impact_sector: ReportInterface,
			entry_barriers: ReportInterface,
			growth: ReportInterface
		},
		business_confidence: {
			age: ReportInterface,
			employees: ReportInterface,
			growth: ReportInterface,
			repayment: ReportInterface,
			size: ReportInterface
		},
		business_quality: {
			activity_benchmark: ReportInterface,
			business_model: ReportInterface,
			corona_sales_lost: ReportInterface,
			growth_benchmark: ReportInterface,
			rentability: ReportInterface,
			rentability_benchmark: ReportInterface
		},
		debt: {
			debt_quartile: ReportInterface,
			expenses_between_profit: ReportInterface,
			tendence: ReportInterface
		},
		environmental_impact: {
			avg_salary: ReportInterface,
			bankable: ReportInterface,
			sustainability: ReportInterface
		},
		financial: {
			balance_life: ReportInterface,
			cashflow_sales: ReportInterface,
			debt_level_growth: ReportInterface,
			liquidity: ReportInterface,
			margin_quartile: ReportInterface,
			solvency_quartile: ReportInterface
		},
		managing_team: {
			team_stability: ReportInterface,
			team_compromise: ReportInterface,
			team_quality: ReportInterface
    }
	}
}

const initialReportCategorie = {
	tempScore: 0,
	scores: [0],
	score: 0,
	max_score: 0
}

const initialState: enterpriseTyped = {
	status: ENTERPRISE_STATUS.INITIAL,
	currentOnboardingStep: 0,
	selectedEnterprise: undefined,
	enterprises: [],
	salaries: -1,
	activities: [],
	activitiesCnaes: [],
	selectedActivity: "",
	selectedActivityDesc: "",
	diagnosisAnonStatus: ENTERPRISE_STATUS.INITIAL,
	bilan: {
		total_assets: "",
		total_assets_old: "",
		own_fund: "",
		own_fund_old: "",
		cash_and_other_liquid: "",
		total_debt: "",
	},
	resultat: {
		revenues: "",
		revenues_old: "",
		fixed_assets_amortization: "",
		fixed_assets_amortization_old: "",
		tax_year_result: "",
		tax_year_result_old: "",
		personnel_expenses: "",
		financial_expenses: "",
		ebitda: "",
	},
	postDiagnosisAnonStatus: ENTERPRISE_STATUS.INITIAL,
	diagnosisResponse: undefined,
	selectedConseils: [],
	currentConseils: [],
	currentConseilColor: "",
	currentConseilCategorie: undefined,
	scores: {
		activity_sector: "",
		business_confidence: "",
		business_quality: "",
		debt: "",
		environmental_impact: "",
		financial: "",
		managing_team: ""
	},
	advices: {
		activity_sector: [],
		business_confidence: [],
		business_quality: [],
		debt: [],
		environmental_impact: [],
		financial: [],
		managing_team: []
	},
	report: {
		activity_sector: {
			corona_impact_sector: initialReportCategorie,
			entry_barriers: initialReportCategorie,
			growth: initialReportCategorie
		},
		business_confidence: {
			age: initialReportCategorie,
			employees: initialReportCategorie,
			growth: initialReportCategorie,
			repayment: initialReportCategorie,
			size: initialReportCategorie
		},
		business_quality: {
			activity_benchmark: initialReportCategorie,
			business_model: initialReportCategorie,
			corona_sales_lost: initialReportCategorie,
			growth_benchmark: initialReportCategorie,
			rentability: initialReportCategorie,
			rentability_benchmark: initialReportCategorie
		},
		debt: {
			debt_quartile: initialReportCategorie,
			expenses_between_profit: initialReportCategorie,
			tendence: initialReportCategorie
		},
		environmental_impact: {
			avg_salary: initialReportCategorie,
			bankable: initialReportCategorie,
			sustainability: initialReportCategorie
		},
		financial: {
			balance_life: initialReportCategorie,
			cashflow_sales: initialReportCategorie,
			debt_level_growth: initialReportCategorie,
			liquidity: initialReportCategorie,
			margin_quartile: initialReportCategorie,
			solvency_quartile: initialReportCategorie
		},
		managing_team: {
			team_stability: initialReportCategorie,
			team_compromise: initialReportCategorie,
			team_quality: initialReportCategorie
    }
	}
};

export const enterpriseSlice = createSlice({
	name: "enterprise",
	initialState,
	reducers: {
		setSelectedEnterprise: (state, action) => {
			state.selectedEnterprise = action.payload;
		},
		setNaf: (state, action) => {
			if (state?.selectedEnterprise) {
				state.selectedEnterprise.siren = action.payload;
			}
		},
		setOnboardingStep: (state, action) => {
			state.currentOnboardingStep = action.payload;
		},
		setSalaries: (state, action) => {
			state.salaries = action.payload;
		},
		setBilan: (state, action: PayloadAction<Record<string, string>>) => {
			const key = Object.keys(action.payload)[0];
			const value = Object.values(action.payload)[0];

			if (!value) {
				//@ts-ignore
				state.bilan[key] = "";
			} else {
				//@ts-ignore
				state.bilan[key] = parseFloat(value);
			}
		},
		setResultat: (state, action: PayloadAction<Record<string, string>>) => {
			const key = Object.keys(action.payload)[0];
			const value = Object.values(action.payload)[0];

			if (!value) {
				//@ts-ignore
				state.resultat[key] = "";
			} else {
				//@ts-ignore
				state.resultat[key] = parseFloat(value);
			}
		},
		setScores: (state, action) => {
			state.scores = action.payload;
		},
		setSelectedActivity: (state, action: PayloadAction<{code: string; desc: string}>) => {
			state.selectedActivity = action.payload.code;
			state.selectedActivityDesc = action.payload.desc;
		},
		setDiagnosisAnonStatus: (state, action: PayloadAction<string>) => {
			state.diagnosisAnonStatus = action.payload;
		},
		resetState: () => initialState,
		setCurrentConseils: (state, action: PayloadAction<(keyof typeof ConseilCodes)[]>) => {
			state.currentConseils = action.payload;
		},
		setCurrentConseilColor: (state, action) => {
			state.currentConseilColor = action.payload
		},
		setCurrentConseilCategorie: (state, action: PayloadAction<keyof typeof indicateurAnalysesCategories>) => {
			state.currentConseilCategorie = action.payload
		}
	},
	extraReducers: (builder) => {
		builder.addCase(verifyToken.fulfilled, (state, action) => {
			state.enterprises = action.payload.response.company;
		});
		builder.addCase(getEnterpriseAnon.fulfilled, (state, action) => {
			state.diagnosisAnonStatus = ENTERPRISE_STATUS.SUCCESS;
			state.diagnosisResponse = action.payload.response;
		});
		builder.addCase(getEnterpriseAnon.rejected, (state, action) => {
			state.diagnosisAnonStatus = ENTERPRISE_STATUS.FAILURE;
		});
		builder.addCase(getActivities.fulfilled, (state, action) => {
			state.activities = action.payload.response;
			let cnaesArr: string[] = [];
			for (let i = 0; i < action.payload.length; i++) {
				const el = action.payload[i];

				for (let x = 0; x < el.associated_cnaes.length; x++) {
					const associatedCnaes = el.associated_cnaes[x];
					cnaesArr.push(associatedCnaes);
				}
			}

			cnaesArr = cnaesArr.filter(function (item, pos) {
				return cnaesArr.indexOf(item) === pos;
			});
			state.activitiesCnaes = cnaesArr;
		});
		builder.addCase(getDiagnosisScores.fulfilled, (state, action) => {
			state.scores = action.payload.scores;
			state.report = action.payload.report;
			state.advices = action.payload.advices;
		})
		builder.addCase(postDiagnosisAnon.pending, (state) => {
			state.postDiagnosisAnonStatus = ENTERPRISE_STATUS.LOADING;
		});
		builder.addCase(postDiagnosisAnon.fulfilled, (state, action) => {
			state.postDiagnosisAnonStatus = ENTERPRISE_STATUS.SUCCESS;
			state.diagnosisResponse = action.payload;
		});
		builder.addCase(postDiagnosisAnon.rejected, (state, payload) => {
			state.postDiagnosisAnonStatus = ENTERPRISE_STATUS.FAILURE;
		});
	},
});

export const {
	setSelectedEnterprise,
	setNaf,
	setOnboardingStep,
	setSalaries,
	setBilan,
	setResultat,
	setSelectedActivity,
	setDiagnosisAnonStatus,
	resetState,
	setCurrentConseils,
	setCurrentConseilColor,
	setCurrentConseilCategorie
} = enterpriseSlice.actions;

export default enterpriseSlice.reducer;
