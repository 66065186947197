import {createAsyncThunk} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {RootState} from "./store";

export const postQuestionnaire = createAsyncThunk(
	"questionnaire/postQuestionnaire",
	// eslint-disable-next-line no-empty-pattern
	async (_, ThunkAPI) => {
		const {enterprise, questionnaire, user} = ThunkAPI.getState() as RootState;
		try {
			const data: any = {...questionnaire.answers};
			// if (enterprise.bilan.own_fund !== "") {
			// 	data["input_data"] = {
			// 		year: new Date().getFullYear().toString(),
			// 		company_name: enterprise.selectedEnterprise?.name,
			// 		postcode: enterprise.selectedEnterprise?.postcode,
			// 		employees: enterprise.salaries,
			// 		constitution_date: enterprise.selectedEnterprise?.constitution_date,
			// 		...enterprise.bilan,
			// 		...enterprise.resultat,
			// 	};
			// }
			const response = await axios({
				method: "post",
				url: `/diagnosis/${enterprise?.diagnosisResponse?.id}/answer`,
				headers: {
					Authorization: `Bearer ${user.token}`
				},
				data,
			});
			// const score = await axios({
			// 	method: "get",
			// 	url: `/diagnosis/${response.data.response.id}`,
			// });
			return response.data.response;
		} catch (error) {
			const err = error as AxiosError;
			return ThunkAPI.rejectWithValue(err);
		}
	}
);
