import styled from "styled-components";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

interface QuestionnaireWrapperI {
	height?: number;
}

export const QuestionnaireWrapper = styled.div<QuestionnaireWrapperI>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	.content {
		width: 100%;
		margin: 0 auto;
		position: relative;

		${device.laptop} {
			padding: 2rem;
			padding-bottom: 0;
			box-shadow: 0 7px 50px rgba(0, 0, 0, 0.05);
		}

		.header {
			h1 {
				${device.laptop} {
					margin-top: 0;
					font-size: 28px;
				}
			}

			p {
				font-size: 14px;
				color: ${Colors.mainColor};

				${device.laptop} {
					font-size: 20px;
				}
			}

			.small-screen-glossaire {
				${device.mobileL} {
					display: none;
				}
			}
		}

		.body {
			padding-left: 1rem;
			padding-right: 1rem;
			margin-top: 2rem;
			margin-bottom: 2rem;
			position: relative;
			transition: all 0.2s ease;
			border: 1px solid ${Colors.lightGrey};
			max-height: 1000px;
			// height: ${(props) => (props.height !== undefined ? `calc(${props.height}-10px)` : "auto")};
			border-radius: 4px;

			${device.laptop} {
				padding-left: 3rem;
				padding-right: 3rem;
			}

			.bar {
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				height: 4px;
				background-color: #ddd;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;

				.bar-left {
					border-top-left-radius: 4px;
					border-top-right-radius: 4px;
					background-color: ${Colors.yellow};
					height: 4px;
				}
			}

			.body-header {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				height: 60px;
				padding-top: 1.5rem;

				${device.laptop} {
					height: 80px;
					padding-top: 2.5rem;
				}

				h2 {
					font-size: 18px;
					margin: 0;

					${device.laptop} {
						margin-top: 0;
						font-size: 22px;
					}
				}

				.min-restantes {
					display: none;
					font-size: 14px;
					padding: 0.5rem 1rem;
					border-radius: 1rem;
					background-color: ${Colors.yellow};
					flex-shrink: 0;
					margin-left: 2rem;
					align-items: center;
					justify-content: center;

					svg {
						margin-right: 0.5rem;
						font-size: 18px;
					}

					${device.laptop} {
						display: flex;
					}
				}
			}

			.body-content {
				.questions-wrapper {
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					height: 250px;
					margin-top: 2.5rem;
					margin-bottom: 2rem;

					${device.tablet} {
						margin-bottom: 3rem;
					}

					.question {
						background-color: white;
						border: 1px solid ${Colors.mainColor};
						color: ${Colors.mainColor};
						padding: 0.75rem;
						width: 100%;
						margin-bottom: 1.5rem;
						border-radius: 6px;
						position: relative;

						${device.laptop} {
							width: 60%;
						}

						&.selected {
							background-color: #f7f6f6;
							border: 1px solid #3b3b3b;
						}

						&:hover {
							cursor: pointer;
							background-color: #f7f6f6;
							border: 1px solid #3b3b3b;
						}

						.subtitle {
							color: #9d9595;
							font-size: 12px;
							margin-bottom: 0;
							margin-top: 0.25rem;
						}

						.circle {
							position: absolute;
							top: 50%;
							transform: translate(0, -50%);
							left: -10px;
							width: 20px;
							height: 20px;
							border-radius: 10px;
							background-color: ${Colors.mainColor};
							display: flex;
							justify-content: center;
							align-items: center;

							svg {
								color: white;
								font-size: 10px;
							}
						}
					}
				}

				.buttons {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 2rem;
				}
			}
		}
	}
`;
