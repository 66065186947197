import {ReactElement} from "react";
import {Page, Text, View, StyleSheet, Image, Font} from "@react-pdf/renderer";
import store from "store/store";
import IBMregular from "assets/fonts/IBMPlexSans-Regular.ttf";
import IBMsemibold from "assets/fonts/IBMPlexSans-SemiBold.ttf";
import IBMbold from "assets/fonts/IBMPlexSans-Bold.ttf";
import LogoPDF from "assets/img/LogoPDF";
import MainIndicator from "assets/img/Indicator.jpg";
import GreenIndicator from "assets/img/GreenIndicator.jpg";
import OrangeIndicator from "assets/img/OrangeIndicator.jpg";
import Colors from "theme/Colors";

const capitalize = (s: string) => {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
};

Font.register({
	family: "IBM Plex Sans",
	fonts: [
		{src: IBMregular, fontStyle: "normal", fontWeight: 400},
		{src: IBMsemibold, fontStyle: "semibold", fontWeight: 500},
		{src: IBMbold, fontStyle: "bold", fontWeight: 700},
	],
});

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: "column",
		alignItems: "flex-start",
		fontFamily: "IBM Plex Sans",
		fontStyle: "normal",
		backgroundColor: Colors.lightGrey
	},
	logo: {
		margin: 60,
	},
	body: {
		width: "90%",
		padding: "2rem",
		margin: "auto",
		marginTop: 200,
		backgroundColor: "white",
		borderRadius: "16px",
	},
	header: {
		width: "100%",
		padding: 80,
	},
	title: {
		width: "100%",
		textAlign: "center",
		fontWeight: 500,
		fontSize: 90,
	},
	subTitle: {
		width: "100%",
		margin: 20,
		textAlign: "center",
		fontSize: 60,
		color: Colors.textColor,
	},
	images: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100%",
		marginTop: 60,
		marginBottom: 80,
	},
	greenIndicator: {
		width: "22%",
		objectFit: "contain",
	},
	imageMain: {
		width: "12%",
		objectFit: "contain",
	},
	orangeIndicator: {
		width: "25%",
		objectFit: "contain",
	},
	footer: {
		padding: 60,
		position: "absolute",
		bottom: 0,
		right: 0,
		fontSize: 72,
		color: "#666",
	},
});

// Create Document Component
const Front = (): ReactElement => {
	const name = store.getState().enterprise.selectedEnterprise?.name;
	const siren = store.getState().enterprise.selectedEnterprise?.siren;
	const today = new Date();

	return (
		<Page size={{width: 2400, height: 3200}} style={styles.page} orientation="landscape">
			<View style={styles.logo}>
				<LogoPDF />
			</View>
			<View style={styles.body}>
				<View style={styles.header}>
					<Text style={styles.title}>Diagnostic financier de {name}</Text>
				</View>
				<View style={styles.images}>
					<Image src={GreenIndicator} style={styles.greenIndicator} />
					<Image src={MainIndicator} style={styles.imageMain} />
					<Image src={OrangeIndicator} style={styles.orangeIndicator} />
				</View>
				<View>
					<Text style={styles.subTitle}>SIREN: {siren}</Text>
					<Text style={{...styles.subTitle, marginBottom: 60}}>
						{capitalize(
							today.toLocaleDateString("fr-FR", {year: "numeric", month: "long"})
						)}
					</Text>
				</View>
			</View>
			
			<View style={styles.footer}>
				<Text>1 / 4</Text>
			</View>
		</Page>
	);
};
export default Front;
