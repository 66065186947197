import React from "react";
import {useHistory} from "react-router";
import RetourModalImg from "assets/img/retour-modal.svg";

// Styles
import {RetourModalStyled} from "./RetourModal.styles";

// Redux
import {useDispatch} from "react-redux";
import {showRetourModal} from "store/user.slice";
import Button from "components/units/Button/Button";
import Colors from "theme/Colors";
import OnboardingMenu from "../OnboardingMenu/OnboardingMenu";
import {
	// ENTERPRISE_STATUS,
	// resetState,
	// setDiagnosisAnonStatus,
	// setSelectedEnterprise,
} from "store/enterprise.slice";

interface RetourModalI {
	isActive: boolean;
}

function RetourModal({isActive}: RetourModalI): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleClose = () => {
		dispatch(showRetourModal());
	};

	const handleRedirect = () => {
		// dispatch(setDiagnosisAnonStatus(ENTERPRISE_STATUS.INITIAL));
		// dispatch(setSelectedEnterprise({}));
		dispatch(showRetourModal());
		// dispatch(resetState());
		history.push("/");
	};

	return isActive ? (
		<RetourModalStyled>
			<div
				className="overlay"
				onClick={() => dispatch(showRetourModal())}
				role="button"
				onKeyPress={(e) => e.keyCode === 13 && handleClose()}
				tabIndex={0}
			/>
			<div className="modal-container">
				<div className="body">
					<img src={RetourModalImg} alt="Retour" />
					<h3>Souhaitez-vous vraiment suspendre le processus en cours ?</h3>
					<p>Votre progression ne pourra être sauvegardée.</p>
					<OnboardingMenu className="menu" />
					<div className="buttons">
						<Button
							text="Continuer mon diagnostic"
							type="button"
							buttonStyles={{backgroundColor: Colors.yellow}}
							onClick={() => dispatch(showRetourModal())}
						/>
						<Button
							text="Recommencer mon diagnostic"
							type="button"
							buttonStyles={{
								backgroundColor: "white",
								border: "1px solid",
								borderColor: Colors.mainColor,
							}}
							onClick={() => handleRedirect()}
						/>
					</div>
				</div>
			</div>
		</RetourModalStyled>
	) : (
		<></>
	);
}

export default RetourModal;
