import {DiagnosisResponseI} from "store/enterprise.actions";
import CONTENT from "./resultGraphContent";
import {toNumber} from "./utils";

export const getWorst = (
	sectorial: DiagnosisResponseI["sectorial"] | undefined
): undefined | string[] => {
	if (!sectorial) return undefined;
	const vals = [
		{id: "1", val: sectorial.activity.value},
		{id: "2", val: sectorial.margin.value},
		{id: "3", val: sectorial.rentability.value},
		{id: "4", val: sectorial.liquidity.value},
		{id: "5", val: sectorial.solvency.value},
		{id: "6", val: sectorial.debt.value},
	];

	vals.sort((a, b) => a.val - b.val);
	return [vals[0].id, vals[1].id];
};

export const getFormattedData = (sectorial: DiagnosisResponseI["sectorial"] | undefined):Record<string, any> | undefined => {
	if (!sectorial) return undefined;
	const {
		activity,
		margin,
		rentability,
		liquidity,
		solvency,
		debt,
		cost_effectiveness,
	} = sectorial;

	const worst = getWorst(sectorial);
	return {
		activity: {
			...CONTENT["1"],
			value: toNumber(activity.value),
			quartile: activity.quartile,
			q1: toNumber(activity.sector.quartile_q1),
			success: !worst?.includes("1") ? true : false,
			sign: "%",
		},
		margin: {
			...CONTENT["2"],
			value: toNumber(margin.value),
			quartile: margin.quartile,
			q1: margin.sector.inverted
				? toNumber(margin.sector.quartile_q3)
				: toNumber(margin.sector.quartile_q1),
			q2: toNumber(margin.sector.quartile_q2),
			q3: margin.sector.inverted
				? toNumber(margin.sector.quartile_q1)
				: toNumber(margin.sector.quartile_q3),
			success: !worst?.includes("2") ? true : false,
			sign: "%",
		},
		rentability: {
			...CONTENT["3"],
			value: toNumber(rentability.value),
			quartile: rentability.quartile,
			q1: rentability.sector.inverted
				? toNumber(rentability.sector.quartile_q3)
				: toNumber(rentability.sector.quartile_q1),
			q2: toNumber(rentability.sector.quartile_q2),
			q3: rentability.sector.inverted
				? toNumber(rentability.sector.quartile_q1)
				: toNumber(rentability.sector.quartile_q3),
			success: !worst?.includes("3") ? true : false,
			sign: "%",
		},
		liquidity: {
			...CONTENT["4"],
			value: toNumber(liquidity.value),
			quartile: liquidity.quartile,
			q1: liquidity.sector.inverted
				? toNumber(liquidity.sector.quartile_q3)
				: toNumber(liquidity.sector.quartile_q1),
			q2: toNumber(liquidity.sector.quartile_q2),
			q3: liquidity.sector.inverted
				? toNumber(liquidity.sector.quartile_q1)
				: toNumber(liquidity.sector.quartile_q3),
			success: !worst?.includes("4") ? true : false,
			sign: "%",
		},
		solvency: {
			...CONTENT["5"],
			value: toNumber(solvency.value),
			quartile: solvency.quartile,
			q1: solvency.sector.inverted
				? toNumber(solvency.sector.quartile_q3)
				: toNumber(solvency.sector.quartile_q1),
			q2: toNumber(solvency.sector.quartile_q2),
			q3: solvency.sector.inverted
				? toNumber(solvency.sector.quartile_q1)
				: toNumber(solvency.sector.quartile_q3),
			success: !worst?.includes("5") ? true : false,
			sign: "%",
		},
		debt: {
			...CONTENT["6"],
			value: toNumber(debt.value),
			quartile: debt.quartile,
			q1: debt.sector.inverted
				? toNumber(debt.sector.quartile_q3)
				: toNumber(debt.sector.quartile_q1),
			q2: toNumber(debt.sector.quartile_q2),
			q3: debt.sector.inverted
				? toNumber(debt.sector.quartile_q1)
				: toNumber(debt.sector.quartile_q3),
			success: !worst?.includes("6") ? true : false,
			sign: "%",
		},
		cost_effectiveness: {
			...CONTENT["7"],
			value: toNumber(cost_effectiveness.value / 1000),
			quartile: cost_effectiveness.quartile,
			q1: toNumber(
				cost_effectiveness.sector.inverted
					? cost_effectiveness.sector.quartile_q3 / 1000
					: cost_effectiveness.sector.quartile_q1 / 1000
			),
			// q2: toNumber(cost_effectiveness.sector.quartile_q2 / 1000),
			// q3: toNumber(
			// 	cost_effectiveness.sector.inverted
			// 		? cost_effectiveness.sector.quartile_q1 / 1000
			// 		: cost_effectiveness.sector.quartile_q3 / 1000
			// ),
			success: !worst?.includes("7") ? true : false,
			sign: "k€",
		},
	};
};
