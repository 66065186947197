import React, {useState, useEffect, useRef} from "react";
import Body from "components/layout/Body/Body";
import Button from "components/units/Button/Button";
import content from "./content";
import {useHistory} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Styles
import {QuestionnaireWrapper} from "./Questionnaire.styles";
import {Container} from "theme/Theme";

// Redux
import {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {prevQuestion, nextQuestion, setAnswer} from "store/questionnaire.slice";
import {faCheck, faLongArrowAltLeft, faLongArrowAltRight} from "@fortawesome/free-solid-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {postQuestionnaire} from "store/questionnaire.actions";
import ConsultezNotreGlossaire from "screens/DonneesFinancieres/Steps/ConsultezNotreGlossaire";

const questionnaireLength = Object.keys(content).length;

function Questionnaire(): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();
	const questionnaireNames = useSelector((state: RootState) =>
		Object.keys(state.questionnaire.answers)
	);
	const currentQuestion = useSelector((state: RootState) => state.questionnaire.currentQuestion);
	const answers = useSelector((state: RootState) => state.questionnaire.answers);
	const diagnosisResponse = useSelector((state: RootState) => state.enterprise.diagnosisResponse);
	const [questionNr, setQuestionNr] = useState(1);

	// Handle selected question
	const [selectedQuestion, setSelectedQuestion] = useState<undefined | number | boolean>(undefined);

	const handleSelectQuestion = (idx: number | boolean) => {
		setSelectedQuestion(idx);
	};

	// Get question index
	const getQuestionIndex = (currentQuestion: string) => {
		return questionnaireNames.findIndex((el) => el === currentQuestion);
	};

	const [bodyH, setBodyH] = useState<undefined | number>(undefined);
	const bodyHeaderHeight = useRef<HTMLDivElement>(null);
	const bodyContentHeight = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (bodyHeaderHeight.current !== null && bodyContentHeight.current !== null) {
			setBodyH(
				bodyHeaderHeight.current.getBoundingClientRect().height +
					bodyContentHeight.current.getBoundingClientRect().height +
					16 * 8
			);
		}
	}, [currentQuestion]);

	// Handle go to next question
	const handleGoNext = () => {
		const idx = getQuestionIndex(currentQuestion);
		setQuestionNr(questionNr + 1)
		// Mostrar o no pregunta 2: who_are_your_clients
		if (idx === 0 && selectedQuestion !== 102) {
			dispatch(setAnswer({[currentQuestion]: selectedQuestion}));
			setSelectedQuestion(undefined);
			dispatch(nextQuestion(questionnaireNames[idx + 2]));
			return;
		}

		// Mostrar o no pregunta 7: why_did_sales_decreased
		if (idx === 5 && diagnosisResponse?.question_flags?.sales_decreased === false) {
			dispatch(setAnswer({[currentQuestion]: selectedQuestion}));
			setSelectedQuestion(undefined);

			// Si low_cash_flow === flase, saltar la pregunta 6 también
			if (diagnosisResponse?.question_flags?.low_cash_flow === false) {
				dispatch(nextQuestion(questionnaireNames[idx + 3]));
			} else {
				dispatch(nextQuestion(questionnaireNames[idx + 2]));
			}
			return;
		}

		// Mostrar o no pregunta 8: why_is_gross_margin_low
		if (idx === 6 && diagnosisResponse?.question_flags?.low_cash_flow === false) {
			dispatch(setAnswer({[currentQuestion]: selectedQuestion}));
			setSelectedQuestion(undefined);
			dispatch(nextQuestion(questionnaireNames[8]));
			return;
		}

		// Mostrar o no pregunta 13: kind_of_finance
		if (idx === 11 && selectedQuestion !== 1002) {
			dispatch(setAnswer({[currentQuestion]: selectedQuestion}));
			setSelectedQuestion(undefined);
			dispatch(nextQuestion(questionnaireNames[idx + 2]));
			return;
		}

		// Mostrar o no pregunta 17: why_is_gross_margin_low
		if (idx === 15 && diagnosisResponse?.question_flags?.capital_increment === false) {
			dispatch(setAnswer({[currentQuestion]: selectedQuestion}));
			setSelectedQuestion(undefined);
			dispatch(nextQuestion(questionnaireNames[idx + 2]));
			return;
		}

		// Handle todas las respuestas
		if (idx !== questionnaireLength - 1) {
			dispatch(setAnswer({[currentQuestion]: selectedQuestion}));
			setSelectedQuestion(undefined);
			dispatch(nextQuestion(questionnaireNames[idx + 1]));
			return;
		} else {
			dispatch(setAnswer({[currentQuestion]: selectedQuestion}));
			dispatch(postQuestionnaire());
			history.push("/questionnaire-end");
		}
	};

	// Handle go back
	const handleGoBack = () => {
		setQuestionNr(questionNr -1)
		const idx = getQuestionIndex(currentQuestion);
		if (idx === 0) {
			return history.push("/quick-view");
		}

		// Mostrar o no pregunta 2: three_represents_50_percent
		if (idx === 2 && answers["who_are_your_clients"] !== 102) {
			dispatch(prevQuestion(questionnaireNames[idx - 2]));
			return;
		}

		// Mostrar o no pregunta 7: why_did_sales_decreased
		if (idx === 7 && diagnosisResponse?.question_flags?.sales_decreased === false) {
			dispatch(nextQuestion(questionnaireNames[idx - 2]));
			return;
		}

		// Mostrar o no pregunta 8: why_is_gross_margin_low
		if (idx === 8 && diagnosisResponse?.question_flags?.low_cash_flow === false) {
			if (diagnosisResponse?.question_flags?.sales_decreased === false) {
				setQuestionNr(questionNr -1)
				dispatch(nextQuestion(questionnaireNames[5]));
				return;
			} else {
				dispatch(nextQuestion(questionnaireNames[7]));
				return;
			}
		}

		// Mostrar o no pregunta 13: why_is_gross_margin_low
		if (idx === 13 && answers["will_you_use_financiation"] !== 1002) {
			dispatch(nextQuestion(questionnaireNames[idx - 2]));
			return;
		}

		// Mostrar o no pregunta 17: why_is_gross_margin_low
		if (idx === 17 && diagnosisResponse?.question_flags?.capital_increment === false) {
			dispatch(nextQuestion(questionnaireNames[idx - 2]));
			return;
		}

		dispatch(prevQuestion(questionnaireNames[idx - 1]));
	};

	useEffect(() => {
		if (Object.prototype.hasOwnProperty.call(answers, currentQuestion)) {
			// @ts-ignore
			setSelectedQuestion(answers[currentQuestion]);
		}
	}, [answers, currentQuestion]);

	return (
		<Body
			title="Questionnaire - Bpifrance en ligne"
			url="questionnare"
			headerRight={window.innerWidth <= 425 ? '' : <ConsultezNotreGlossaire />}
			// headerRight={<ConsultezNotreGlossaire />}
		>
			<QuestionnaireWrapper height={bodyH}>
				<Container>
					<div className="content">
						<div className="header">
							<h1>
								{getQuestionIndex(currentQuestion) === 0
									? "Le diagnostic va débuter"
									: `Question  №${questionNr}`}
									{/* : `Question №${getQuestionIndex(currentQuestion) + 1}`} */}
							</h1>
							<p>
								Nous vous proposons une série de questions afin de mieux comprendre le fonctionnement de votre entreprise.{" "}
							</p>
							<span className="small-screen-glossaire">
								<ConsultezNotreGlossaire />
							</span>
						</div>
						<div className="body">
							<div className="bar">
								<div
									className="bar-left"
									style={{
										width: `${(
											((getQuestionIndex(currentQuestion) + 1) * 100) /
											questionnaireLength
										).toFixed(2)}%`,
									}}
								/>
							</div>
							<div className="body-header" ref={bodyHeaderHeight}>
								<h2 className="question-title">{content[currentQuestion]?.title}</h2>
								<span className="min-restantes">
									<FontAwesomeIcon icon={faClock} />
									{content[currentQuestion]?.minutesLeft} min restantes
								</span>
							</div>

							<div className="body-content" ref={bodyContentHeight}>
								<div className="questions-wrapper">
									{content[currentQuestion]?.options.map((q, i: number) => {
										return (
											<button
												key={`q-${i}`}
												className={`question${
													q.value === selectedQuestion ? " selected" : ""
												}`}
												onClick={() => handleSelectQuestion(q.value)}
											>
												{q.title}
												{q?.subtitle ? (
													<p className="subtitle">{q.subtitle}</p>
												) : null}
												{q.value === selectedQuestion ? (
													<div className="circle">
														<FontAwesomeIcon icon={faCheck} />
													</div>
												) : null}
											</button>
										);
									})}
								</div>
								<div className="buttons">
									<Button
										type="button"
										onClick={handleGoBack}
										className="secondary"
										text="Précédent"
										icon={faLongArrowAltLeft}
										iconPosition="left"
										buttonStyles={{
											border: "none",
										}}
										textStyles={{paddingLeft: "0.75rem"}}
									/>
									<Button
										type="button"
										text="Suivant"
										icon={faLongArrowAltRight}
										iconPosition="right"
										className={`secondary ${selectedQuestion === undefined ? 'disabled' : ''}`}
										title={
											selectedQuestion === undefined
												? "Vous devez sélectionner une option"
												: "Suivant"
										}
										disabled={selectedQuestion === undefined ? true : false}
										onClick={handleGoNext}
									/>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</QuestionnaireWrapper>
		</Body>
	);
}

export default Questionnaire;
