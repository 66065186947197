import {useState, useEffect} from "react";
import OnboardingMenu from "components/composed/OnboardingMenu/OnboardingMenu";
import SelectedEnterprise from "components/composed/SelectedEnterprise/SelectedEnterprise";
import {setResultat, setOnboardingStep} from "store/enterprise.slice";
import {useAppDispatch, useAppSelector} from "store/storeHooks";
import ConsultezNotreGlossaire from "./ConsultezNotreGlossaire";

function Step4(): JSX.Element {
	const dispatch = useAppDispatch();
	const [curTab, setCurTab] = useState("anne");
	const resultat = useAppSelector((state) => state.enterprise.resultat);

	useEffect(() => {
		dispatch(setOnboardingStep(3.3));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (cat: string, val: string) => {
		if (/[a-zA-Z]/.test(val)) return

		dispatch(setResultat({[cat]: val}))
	}

	return (
		<>
			<div className="top-content">
				<h1>Données financières</h1>
				<p className="no-sentence">
					Ce diagnostic se nourrit des données publiques agrégées auprès des sources
					telles que la Banque de France, l’INSEE et l’INPI.
				</p>
				<p className="only-mobile">
					Nous avons besoin de plus d’informations pour continuer. Veuillez renseigner les
					informations du bilan de l’entreprise.
				</p>
				<ConsultezNotreGlossaire />
			</div>
			<div className="bottom-content">
				<div className="left">
					<SelectedEnterprise className="desktop" />
					<OnboardingMenu className="onboarding-desktop" />
				</div>
				<div className="right">
					<div className="desktop-form">
						<p>
							Nous avons besoin de plus d’informations pour continuer.
							<br />
							Veuillez renseigner les informations du bilan de l’entreprise.
						</p>
					</div>
					<div className="tabs">
						<div
							onClick={() => setCurTab("anne")}
							role="button"
							tabIndex={0}
							onKeyPress={(event) => event.keyCode === 13 && setCurTab("anne")}
							className={`${curTab === "anne" ? "selected" : ""}`}
						>
							Année N
						</div>
						<div
							onClick={() => setCurTab("anne1")}
							role="button"
							tabIndex={0}
							onKeyPress={(event) => event.keyCode === 13 && setCurTab("anne1")}
							className={`${curTab === "anne1" ? "selected" : ""}`}
						>
							Année N+1
						</div>
					</div>
					<div className="tabs-content">
						{curTab === "anne" ? (
							<>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Total des ventes réalisées sur l’ensemble de la période.</div>
										<label htmlFor="revenues">Chiffre d{"'"}affaires</label>
									</div>
									<input
										type="number"
										name="revenues"
										id="revenues"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('revenues', e.target.value)}
										value={resultat.revenues}
									/>
									<span className={`euro ${resultat.revenues !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content"> Il s{"'"}agit de l{"'"}allocation totale au cours de la période pour amortir les actifs dont la durée de vie est supérieure à un an à la date du bilan.</div>
										<label htmlFor="fixed_assets_amortization">
											Dotation aux amortissements
										</label>
									</div>
									<input
										type="number"
										name="fixed_assets_amortization"
										id="fixed_assets_amortization"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('fixed_assets_amortization', e.target.value)}
										value={resultat.fixed_assets_amortization}
									/>
									<span className={`euro ${resultat.fixed_assets_amortization !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Bénéfice net attribuable après impôts sur les sociétés.</div>
										<label htmlFor="tax_year_result">Résultat fiscal</label>
									</div>
									<input
										type="number"
										name="tax_year_result"
										id="tax_year_result"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('tax_year_result', e.target.value)}
										value={resultat.tax_year_result}
									/>
									<span className={`euro ${resultat.tax_year_result !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Dépenses de personnel, charges sociales comprises.</div>
										<label htmlFor="personnel_expenses">
											Dépenses de personnel
										</label>
									</div>
									<input
										type="number"
										name="personnel_expenses"
										id="personnel_expenses"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('personnel_expenses', e.target.value)}
										value={resultat.personnel_expenses}
									/>
									<span className={`euro ${resultat.personnel_expenses !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Coût des ressources d{"'"}emprunt obtenus auprès des différents créanciers bancaires et financiers.</div>
										<label htmlFor="financial_expenses">Charges financières</label>
									</div>
									<input
										type="number"
										name="financial_expenses"
										id="financial_expenses"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('financial_expenses', e.target.value)}
										value={resultat.financial_expenses}
									/>
									<span className={`euro ${resultat.financial_expenses !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Il s{"'"}agit du résultat brut d{"'"}exploitation avant la prise en compte de la charge d{"'"}amortissement des actifs dont la durée de vie est supérieure à un an à la date du bilan.</div>
										<label htmlFor="ebitda">Excédent brut d{"'"}exploitation</label>
									</div>
									<input
										type="number"
										name="ebitda"
										id="ebitda"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('ebitda', e.target.value)}
										value={resultat.ebitda}
									/>
									<span className={`euro ${resultat.ebitda !== "" ? 'active' : null}`}>€</span>
								</div>
							</>
						) : (
							<>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Total des ventes réalisées sur l’ensemble de la période.</div>
										<label htmlFor="revenues_old">Chiffre d{"'"}affaires</label>
									</div>
									<input
										type="number"
										name="revenues_old"
										id="revenues_old"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('revenues_old', e.target.value)}
										value={resultat.revenues_old}
									/>
									<span className={`euro ${resultat.revenues_old !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Il s{"'"}agit de l{"'"}allocation totale au cours de la période pour amortir les actifs dont la durée de vie est supérieure à un an à la date du bilan.</div>
										<label htmlFor="fixed_assets_amortization_old">
											Dotation aux amortissements
										</label>
									</div>
									<input
										type="number"
										name="fixed_assets_amortization_old"
										id="fixed_assets_amortization_old"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('fixed_assets_amortization_old', e.target.value)}
										value={resultat.fixed_assets_amortization_old}
									/>
									<span className={`euro ${resultat.fixed_assets_amortization_old !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Bénéfice net attribuable après impôts sur les sociétés.</div>
										<label htmlFor="tax_year_result_old">Résultat fiscal</label>
									</div>
									<input
										type="number"
										name="tax_year_result_old"
										id="tax_year_result_old"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('tax_year_result_old', e.target.value)}
										value={resultat.tax_year_result_old}
									/>
									<span className={`euro ${resultat.tax_year_result_old !== "" ? 'active' : null}`}>€</span>
								</div>
							</>
						)}
					</div>
					<div className="form-desktop">
						<div className="titles">
							<h3>Année N</h3>
							<h3>Année N-1</h3>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content">Total des ventes réalisées sur l{"'"}ensemble de la période.</div>
								<label htmlFor="revenues">Chiffre d{"'"}affaires</label>
							</div>
							<div className="inline">
								<input
									type="number"
									name="revenues"
									id="revenues"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('revenues', e.target.value)}
									value={resultat.revenues}
								/>
								<span className={`euro ${resultat.revenues !== "" ? 'active' : null}`}>€</span>
							</div>
							<div className="inline">
								<input
									type="number"
									name="revenues_old"
									id="revenues_old"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('revenues_old', e.target.value)}
									value={resultat.revenues_old}
								/>
								<span className={`euro ${resultat.revenues_old !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content"> Il s{"'"}agit de l{"'"}allocation totale au cours de la période pour amortir les actifs dont la.</div>
								<label htmlFor="fixed_assets_amortization">Dotation aux amortissements</label>
							</div>
							<div className="inline">
								<input
									type="number"
									name="fixed_assets_amortization"
									id="fixed_assets_amortization"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('fixed_assets_amortization', e.target.value)}
									value={resultat.fixed_assets_amortization}
								/>
								<span className={`euro ${resultat.fixed_assets_amortization !== "" ? 'active' : null}`}>€</span>
							</div>
							<div className="inline">
								<label
									htmlFor="fixed_assets_amortization_old"
									style={{display: "none"}}
								>
									Dotation aux amortissements
								</label>
								<input
									type="number"
									name="fixed_assets_amortization_old"
									id="fixed_assets_amortization_old"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('fixed_assets_amortization_old', e.target.value)}
									value={resultat.fixed_assets_amortization_old}
								/>
								<span className={`euro ${resultat.fixed_assets_amortization_old !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content">  Bénéfice net attribuable après impôts sur les sociétés.</div>
								<label htmlFor="tax_year_result">Résultat fiscal</label>
							</div>
							<div className="inline">
								<input
									type="number"
									name="tax_year_result"
									id="tax_year_result"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('tax_year_result', e.target.value)}
									value={resultat.tax_year_result}
								/>
								<span className={`euro ${resultat.tax_year_result !== "" ? 'active' : null}`}>€</span>
							</div>
							<div className="inline">
								<label htmlFor="tax_year_result_old" style={{display: "none"}}>
									Résultat fiscal
								</label>
								<input
									type="number"
									name="tax_year_result_old"
									id="tax_year_result_old"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('tax_year_result_old', e.target.value)}
									value={resultat.tax_year_result_old}
								/>
								<span className={`euro ${resultat.tax_year_result_old !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content">Dépenses de personnel, charges sociales comprises.</div>
								<label htmlFor="personnel_expenses">Dépenses de personnel</label>
							</div>
							<div className="inline" style={{marginRight: "35%"}}>
								<input
									type="number"
									name="personnel_expenses"
									id="personnel_expenses"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('personnel_expenses', e.target.value)}
									value={resultat.personnel_expenses}
								/>
								<span className={`euro ${resultat.personnel_expenses !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content">Coût des ressources d{"'"}emprunt obtenus auprès des différents créanciers bancaires et financiers.</div>
								<label htmlFor="financial_expenses">Charges financières</label>
							</div>
							<div className="inline" style={{marginRight: "35%"}}>
								<input
									type="number"
									name="financial_expenses"
									id="financial_expenses"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('financial_expenses', e.target.value)}
									value={resultat.financial_expenses}
								/>
								<span className={`euro ${resultat.financial_expenses !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content">Il s{"'"}agit du résultat brut d{"'"}exploitation avant la prise en compte de la charge d{"'"}amortissement des actifs dont la durée de vie est supérieure à un an à la date du bilan.</div>
								<label htmlFor="ebitda">Excédent brut d{"'"}exploitation</label>
							</div>
							<div className="inline" style={{marginRight: "35%"}}>
								<input
									type="number"
									name="ebitda"
									id="ebitda"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('ebitda', e.target.value)}
									value={resultat.ebitda}
								/>
								<span className={`euro ${resultat.ebitda !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Step4;
