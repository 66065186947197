import {ReactElement, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Colors from "theme/Colors";
import {device} from "theme/GlobalStyles";

const VerticalBarStyled = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	border-radius: 1.5rem;
	background-color: transparent;

	-webkit-transition: background-color 200ms ease-in-out;
	-moz-transition: background-color 200ms ease-in-out;
	-o-transition: background-color 200ms ease-in-out;
	transition: background-color 200ms ease-in-out;

	&:hover {
		background-color: ${Colors.background};
	}

	.dark-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: ${Colors.backgroundGrey};
		opacity: 0.8;
		z-index: 9;
	}

	.show-company-result-upon-hover {
		position: relative;
		width: 100%;

		.company-result {
			opacity: 0;
			height: 20px;
			margin-top: 1rem;
			width: 80%;
				margin: 0.5rem auto;
				text-align: center;
				border-radius: 8px;
				color: white;
		
				&.success {
					background-color: ${Colors.success};
				}
			
				&.yellow {
					background-color: ${Colors.yellow};
				}
			
				&.orange {
					background-color: ${Colors.orange};
				}
			
				&.error {
					background-color: ${Colors.error};
				}

			-webkit-transition: opacity 200ms ease-in-out;
			-moz-transition: opacity 200ms ease-in-out;
			-o-transition: opacity 200ms ease-in-out;
			transition: opacity 200ms ease-in-out;
		}

		&:hover,
		&:focus,
		&:active {
			cursor: pointer;

			.company-result {
				opacity: 1;
			}
		}
	}

	.name {
		text-align: center;
		position: relative;
		font-size: 13px;
		height: 70px;
		padding: 1rem;
	}

	.popover {
		display: none;

		&:hover {
			cursor: default;
		}

		&.visible {
			display: block;
		}

		.popover-window {
			position: fixed;
			top: 45%;
			left: 50%;
			width: 240px;
			text-align: left;
			transform: translate(-50%);
			background-color: #fff;
			padding: 1rem;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
			border-radius: 16px;
			z-index: 999;

			${device.laptop} {
				width: 500px;
			}

			.popover-title-line {
				display: flex;
				justify-content: space-between;

				h5 {
					margin-top: 0;
					margin-bottom: 0.5rem;
					font-size: 14px;
				}
			}

			.desc {
				padding-right: 0.5rem;
			}
		}

	}
`;

const VertivalBarUnitStyled = styled.div<{sign: string; croissance?: boolean; chiffreDAffaires?: boolean}>`
	position: relative;
	left: 40%;
	height: ${(props) => (props.croissance ? "180px" : "82px")};
	width: 24px;
	border-radius: 10px;
	background-color: #eaeae9;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	margin-bottom: 1rem;
	color: white;
	text-shadow: 1px 1px black;
	text-align: center;

	&.success {
		background-color: ${Colors.success};
	}

	&.yellow {
		background-color: ${Colors.yellow};
	}

	&.orange {
		background-color: ${Colors.orange};
	}

	&.error {
		background-color: ${Colors.error};
	}

	&:after {
		display: block;
	}

	.between {
		position: absolute;
		bottom: -1rem;
		right: -2.5rem;
		color: ${Colors.textColor};
		text-shadow: none;

		&:after {
			content: "${(props) => props.sign}";
		}
	}

	.horizontal-grey-line {
		position: absolute;
		top: -0.5rem;
		width: 29px;
		right: 0;
		border-bottom: 1px solid ${Colors.lightGrey};
	}
`;

interface VerticalBarI {
	data: {
		name: string;
		description: string;
		value: number | string;
		quartile: number;
		q1: number | string;
		q2?: number | string;
		q3?: number | string;
		success: boolean;
		sign: string;
	};
	croissance?: boolean;
	className?: string;
}

const showOnlyOneDigit = (num: number | string | undefined) => {
	if (typeof num === 'undefined') return
	if (typeof num === 'number') {
		return Number.isInteger(num) ? num : num.toFixed(1)
	}
	const number = parseFloat(num)
	return Number.isInteger(number) ? number : number.toFixed(1)
}

const showNoDecimals = (num: number | string) => {
	if (typeof num === 'number') return Math.round(num)
	const number = parseFloat(num)
	return Math.round(number)
}

function VerticalBar({data, croissance, className}: VerticalBarI): ReactElement {
	const [showPopUp, setShowPopUp] = useState<boolean>(false);

	const endettement = data?.name === 'Endettement' ? true : false
	const chiffreDAffaires = data?.name === "Chiffre d’affaires par salarié"? true : false

	const quartileColor = data?.quartile === 4
		? "success"
		: data?.quartile === 3 
			? "yellow"
			: data?.quartile === 2
				? croissance
					? "success"
					: "orange"
				: data?.quartile === 1
					? croissance
						? "orange"
						: "error"
		: null

	const handleShowPopUp = (bool: boolean) => setShowPopUp(bool)

	return (
		<VerticalBarStyled className={className}>
			<div 
				className={showPopUp ? "dark-background" : ""} 
				role="button"
				onClick={() => handleShowPopUp(false)}
				onKeyPress={(e) => e.keyCode === 13 && handleShowPopUp(false)}
				tabIndex={0}
			/>
			<div 
				role="button"
				className="show-company-result-upon-hover"
				onClick={() => handleShowPopUp(true)}
				onKeyPress={(e) => e.keyCode === 13 && handleShowPopUp(true)}
				tabIndex={0}
			>
				<div className={`company-result ${quartileColor}`}>
					{endettement || chiffreDAffaires
						? showNoDecimals(data.value)
						: showOnlyOneDigit(data.value)
					}{' '}{data.sign}
				</div>
				{!croissance ? (
					<>
						<VertivalBarUnitStyled
							sign={data.sign}
							className={data.quartile === 4 ? "success" : ""}
							croissance={croissance}
						>
							<div className="between">
								{endettement
									? showOnlyOneDigit(data.q1) 
									: showOnlyOneDigit(data.q3)
								}{' '}
							</div>
						</VertivalBarUnitStyled>

						<VertivalBarUnitStyled
							sign={data.sign}
							className={data.quartile === 3 ? "yellow" : ""}
							croissance={croissance}
						>
							<div className="between">
								{showOnlyOneDigit(data.q2)}{' '}
							</div>
							<div className="horizontal-grey-line" />
						</VertivalBarUnitStyled>
					</>
				) : (
					<></>
				)}

				<VertivalBarUnitStyled
					sign={data.sign}
					className={data.quartile === 2
						? croissance
							? "success"
							: "orange"
						: ""
					}
					croissance={croissance}
				>
					<div className="between">
						{endettement
							? showOnlyOneDigit(data.q3) 
							: showOnlyOneDigit(data.q1)
						}{' '}
					</div>
					{croissance
						? null
						: <div className="horizontal-grey-line" />
					}
				</VertivalBarUnitStyled>

				<VertivalBarUnitStyled
					sign={data.sign}
					className={data.quartile === 1 
						? croissance 
							? "orange"
							: "error" 
						: ""}
					croissance={croissance}
				>
					<div className="horizontal-grey-line" />
				</VertivalBarUnitStyled>
			
			
				<div className="name">
					{data.name}
				</div>
			</div>
			<div 
				className={showPopUp ? "popover visible" : "popover"}
				onBlur={() => setShowPopUp(false)}
				role="button"
				onKeyPress={(e) => e.keyCode === 13 && setShowPopUp(false)}
				tabIndex={0}
			>
						<div className="popover-window">
							<div className="popover-title-line">
								<h5 className="title">{data.name}</h5>
								<span
									role="button"
									onClick={() => handleShowPopUp(false)}
									onKeyPress={(e) => e.keyCode === 13 && handleShowPopUp(false)}
									tabIndex={0}
									>
									<FontAwesomeIcon icon={faTimes} style={{marginRight: 8}} />
								</span>
							</div>
							<p className="desc">{data.description}</p>
						</div>
					</div>
		</VerticalBarStyled>
	);
}

export default styled(VerticalBar)``;
