import styled from "styled-components";
import {device} from "./GlobalStyles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 90%;
	margin: 0 auto;

	${device.laptop} {
		max-width: 1100px;
	}
`;
