import {createAsyncThunk} from "@reduxjs/toolkit";
import axios, {AxiosError} from "axios";
import {RootState} from "./store";

export const getWsToken = createAsyncThunk("user/getWsToken", async (params: Record<string,any> | undefined, thunkAPI) => {
	try {
		const response: {data: any} = await axios({
			method: "post",
			url: "/ws/login",
			data: params
		});
		return response.data.response.token;
	} catch (error) {
		const err = error as AxiosError;
		return thunkAPI.rejectWithValue(err);
	}
});

export const getToken = createAsyncThunk("user/getToken", async (params: Record<string,any> | undefined, thunkAPI) => {
	try {
		const response: {data: any} = await axios({
			method: "post",
			url: "/referer",
			data: {
				company: params?.company
			},
			headers: {
				"Authorization": `Bearer ${params?.loginToken}`
			}
		});
		return response.data.response.token;
	} catch (error) {
		const err = error as AxiosError;
		return thunkAPI.rejectWithValue(err);
	}
});

export const verifyToken = createAsyncThunk(
	"user/verifyToken",
	// eslint-disable-next-line no-empty-pattern
	async (params, thunkAPI) => {
		const token = thunkAPI.getState() as RootState;
		try {
			const response: {data: any} = await axios({
				method: "get",
				url: "/referer",
				params: {
					token: token.user.token,
				},
			});
			return response.data;
		} catch (error) {
			const err = error as AxiosError;
			return thunkAPI.rejectWithValue(err);
		}
	}
);

export const postPDF = createAsyncThunk(
	"user/uploadPDF",
	// eslint-disable-next-line no-empty-pattern
	async (params: any, thunkAPI) => {
		const state = thunkAPI.getState() as RootState;
		const id = state.enterprise.diagnosisResponse?.id;
		const token = state.user.token;
		const formData = new FormData(); // Currently empty
		formData.append("pdf", params, `pdf-${id}.pdf`);
		try {
			const response: {data: any} = await axios({
				method: "post",
				url: `/upload/${id}`,
				headers: {
					Authorization: `Bearer ${token}`
				},
				data: formData,
			});
			return response.data;
		} catch (error) {
			const err = error as AxiosError;
			return thunkAPI.rejectWithValue(err);
		}
	}
);
