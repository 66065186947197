import {createSlice} from "@reduxjs/toolkit";
import {getToken, getWsToken, verifyToken} from "./user.actions";

export const USER_STATUS = {
	LOADING: "LOADING",
	LOADED: "LOADED",
};

const initialState = {
	status: USER_STATUS.LOADING,
	showGlossaire: false,
	loginToken: "",
	token: "",
	hasToken: false,
	showRetourModal: false,
	showServicePremiumModal: false,
	pdfDownloaded: false,
	hasSeenConseils: false,
};

export const userSlice = createSlice({
	name: "user",
	initialState: initialState,
	reducers: {
		showGlossaire: (state) => {
			state.showGlossaire = !state.showGlossaire;
		},
		showRetourModal: (state) => {
			state.showRetourModal = !state.showRetourModal;
		},
		showServicePremiumModal: (state) => {
			state.showServicePremiumModal = !state.showServicePremiumModal;
		},
		setToken: (state, action) => {
			state.hasToken = action.payload;
		},
		storeToken: (state, action) => {
			state.token = action.payload;
		},
		setPdfDownloaded: (state) => {
			state.pdfDownloaded = true;
		},
		setHasSeenConseils: (state) => {
			state.hasSeenConseils = true;
		},
	},
	extraReducers: (builder) => {
		builder
			// .addCase(verifyToken.pending, (state) => {
			// 	state.status = ENTERPRISE_STATUS.LOADING;
			// })
			.addCase(verifyToken.fulfilled, (state) => {
				state.hasToken = true;
			})
			.addCase(getToken.fulfilled, (state, action) => {
				//@ts-ignore
				state.token = action.payload;
			})
			.addCase(getWsToken.fulfilled, (state, action) => {
				//@ts-ignore
				state.loginToken = action.payload;
			})
			.addCase("enterprise/resetState", () => initialState);
		// .addCase(verifyToken.rejected, (state) => {
		// 	state.status = ENTERPRISE_STATUS.FAILURE;
		// });
	},
});

export {verifyToken, getToken};
export const {
	showGlossaire,
	showRetourModal,
	setToken,
	showServicePremiumModal,
	setPdfDownloaded,
	setHasSeenConseils,
	storeToken
} = userSlice.actions;
export default userSlice.reducer;
