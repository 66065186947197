import React from "react";
import Header from "components/layout/Header/Header";
import Footer from "components/layout/Footer/Footer";
import Notifications from "components/composed/Notifications/Notifications";
import Glossaire from "components/composed/Glossaire/Glossaire";
import ServicePremium from "components/composed/ServicePremium/ServicePremium";

// Redux
import {RootState} from "store/store";
import {useSelector} from "react-redux";
import RetourModal from "components/composed/RetourModal/RetourModal";

type BodyTypes = {
	title?: string;
	showTitle?: boolean;
	children?: any;
	headerRight?: any;
	url: string;
};

function Body({title, url, children, headerRight}: BodyTypes): JSX.Element {
	const showGlossaire = useSelector((state: RootState) => state.user.showGlossaire);
	const showRetourModal = useSelector((state: RootState) => state.user.showRetourModal);
	const showServicePremiumModal = useSelector(
		(state: RootState) => state.user.showServicePremiumModal
	);

	return (
		<>
			<Header title={title} url={url} headerRight={headerRight} />
			{children}
			<Footer />
			<Notifications />
			<Glossaire isActive={showGlossaire} />
			<RetourModal isActive={showRetourModal} />
			<ServicePremium isActive={showServicePremiumModal} />
		</>
	);
}

export default Body;
