import {useState, useEffect} from "react";
import OnboardingMenu from "components/composed/OnboardingMenu/OnboardingMenu";
import SelectedEnterprise from "components/composed/SelectedEnterprise/SelectedEnterprise";

// Redux
import {RootState} from "store/store";
import {useSelector, useDispatch} from "react-redux";
import {setBilan, setOnboardingStep} from "store/enterprise.slice";
import ConsultezNotreGlossaire from "./ConsultezNotreGlossaire";

function Step3(): JSX.Element {
	const dispatch = useDispatch();
	const [curTab, setCurTab] = useState("anne");
	const bilan = useSelector((state: RootState) => state.enterprise.bilan);

	useEffect(() => {
		dispatch(setOnboardingStep(3.2));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (cat: string, val: string) => {
		if (/[a-zA-Z]/.test(val)) return

		dispatch(setBilan({[cat]: val}))
	}

	return (
		<>
			<div className="top-content">
				<h1>Données financières</h1>
				<p className="no-sentence">
					Ce diagnostic se nourrit des données publiques agrégées auprès des sources
					telles que la Banque de France, l’INSEE et l’INPI.
				</p>
				<p className="only-mobile">
					Nous avons besoin de plus d’informations pour continuer. Veuillez renseigner les
					informations du bilan de l’entreprise.
				</p>
				<ConsultezNotreGlossaire />
			</div>
			<div className="bottom-content">
				<div className="left">
					<SelectedEnterprise className="desktop" />
					<OnboardingMenu className="onboarding-desktop" />
				</div>
				<div className="right">
					<div className="desktop-form">
						<p>
							Nous avons besoin de plus d’informations pour continuer.
							<br />
							Veuillez renseigner les informations du bilan de l’entreprise.
						</p>
					</div>
					<div className="tabs">
						<div
							onClick={() => setCurTab("anne")}
							role="button"
							tabIndex={0}
							onKeyPress={(event) => event.keyCode === 13 && setCurTab("anne")}
							className={`${curTab === "anne" ? "selected" : ""}`}
						>
							Année N
						</div>
						<div
							onClick={() => setCurTab("anne1")}
							role="button"
							tabIndex={0}
							onKeyPress={(event) => event.keyCode === 13 && setCurTab("anne1")}
							className={`${curTab === "anne1" ? "selected" : ""}`}
						>
							Année N+1
						</div>
					</div>
					<div className="tabs-content">
						{curTab === "anne" ? (
							<>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Total des actifs figurant au bilan consolidé de la société à la date de clôture de l’exercice</div>
										<label htmlFor="total_assets">Total actif</label>
									</div>
									<input
										type="number"
										name="total_assets"
										id="total_assets"
										value={bilan.total_assets}
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('total_assets', e.target.value)}
									/>
									<span className={`euro ${bilan.total_assets !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Total des actifs - total des dettes</div>
										<label htmlFor="own_fund">Fonds propres</label>
									</div>
									<input
										type="number"
										name="own_fund"
										id="own_fund"
										value={bilan.own_fund}
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('own_fund', e.target.value)}
									/>
									<span className={`euro ${bilan.own_fund !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">L{"'"}ensemble des sommes d’argent disponibles en caisse ou placées sur des comptes bancaires.</div>
										<label htmlFor="cash_and_other_liquid">Trésorerie</label>
									</div>
									<input
										type="number"
										name="cash_and_other_liquid"
										value={bilan.cash_and_other_liquid}
										id="cash_and_other_liquid"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('cash_and_other_liquid', e.target.value)}
									/>
									<span className={`euro ${bilan.cash_and_other_liquid !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Montant total des dettes financières ( qui ont un coût explicite) figurant au bilan consolidé de l’entreprise.</div>
										<label htmlFor="total_debt">Dettes financières</label>
									</div>
									<input
										type="number"
										name="total_debt"
										value={bilan.total_debt}
										id="total_debt"
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('total_debt', e.target.value)}
									/>
									<span className={`euro ${bilan.total_debt !== "" ? 'active' : null}`}>€</span>
								</div>
							</>
						) : (
							<>
								<div className="inline">
									<div className="label">
										<div className="popup-content">Total des actifs figurant au bilan consolidé de la société à la date de clôture de l’exercice</div>
										<label htmlFor="total_assets_old">Total actif</label>
									</div>
									<input
										type="number"
										name="total_assets_old"
										id="total_assets_old"
										value={bilan.total_assets_old}
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('total_assets_old', e.target.value)}
									/>
									<span className={`euro ${bilan.total_assets_old !== "" ? 'active' : null}`}>€</span>
								</div>
								<div className="inline">
								<div className="label">
										<div className="popup-content">Total des actifs - total des dettes</div>
										<label htmlFor="own_fund_old">Fonds propres</label>
									</div>
									<input
										type="number"
										name="own_fund_old"
										id="own_fund_old"
										value={bilan.own_fund_old}
										placeholder="Montant"
										step={0.01}
										onChange={(e) => handleChange('own_fund_old', e.target.value)}
									/>
									<span className={`euro ${bilan.own_fund_old !== "" ? 'active' : null}`}>€</span>
								</div>
							</>
						)}
					</div>
					<div className="form-desktop">
						<div className="titles">
							<h3>Année N</h3>
							<h3>Année N-1</h3>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content">Total des actifs figurant au bilan consolidé de la société à la date de clôture de l’exercice</div>
								<label htmlFor="total_assets">Total actif</label>
							</div>
							<div className="inline">
								<input
									type="number"
									name="total_assets"
									id="total_assets"
									value={bilan.total_assets}
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('total_assets', e.target.value)}
								/>
								<span className={`euro ${bilan.total_assets !== "" ? 'active' : null}`}>€</span>
							</div>
							<div className="inline">
								<input
									type="number"
									name="total_assets_old"
									id="total_assets_old"
									value={bilan.total_assets_old}
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('total_assets_old', e.target.value)}
								/>
								<span className={`euro ${bilan.total_assets_old !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
						<div className="group">
							<div className="label">
								<div className="popup-content">Total des actifs - total des dettes</div>
								<label htmlFor="own_fund">Fonds propres</label>
							</div>
							<div className="inline">
								<input
									type="number"
									name="own_fund"
									value={bilan.own_fund}
									id="own_fund"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('own_fund', e.target.value)}
								/>
								<span className={`euro ${bilan.own_fund !== "" ? 'active' : null}`}>€</span>
							</div>
							<div className="inline">
								<input
									type="number"
									name="own_fund_old"
									value={bilan.own_fund_old}
									id="own_fund_old"
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('own_fund_old', e.target.value)}
								/>
								<span className={`euro ${bilan.own_fund_old !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>

						<div className="group">
							<div className="label">
								<div className="popup-content">L’ensemble des sommes d’argent disponibles en caisse ou placées sur des comptes bancaires.</div>
								<label htmlFor="cash_and_other_liquid">Trésorerie</label>
							</div>
							<div className="inline" style={{marginRight: "35%"}}>
								<input
									type="number"
									name="cash_and_other_liquid"
									id="cash_and_other_liquid"
									value={bilan.cash_and_other_liquid}
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('cash_and_other_liquid', e.target.value)}
								/>
								<span className={`euro ${bilan.cash_and_other_liquid !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>

						<div className="group">
							<div className="label">
								<div className="popup-content">Montant total des dettes financières ( qui ont un coût explicite) figurant au bilan consolidé de l’entreprise.</div>
								<label htmlFor="total_debt">Dettes financières</label>
							</div>
							<div className="inline" style={{marginRight: "35%"}}>
								<input
									type="number"
									name="total_debt"
									id="total_debt"
									value={bilan.total_debt}
									placeholder="Montant"
									step={0.01}
									onChange={(e) => handleChange('total_debt', e.target.value)}
								/>
								<span className={`euro ${bilan.total_debt !== "" ? 'active' : null}`}>€</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Step3;
